import { FC, useMemo, useState } from "react"
import { useFindNcUpdatesQuery } from "../store"
import { DataGrid } from "@/core/components"
import { TG } from "../interfaces"
import {
  GridCallbackDetails,
  GridColDef,
  GridFilterModel,
  GridPaginationModel,
  getGridNumericOperators,
  getGridSingleSelectOperators,
  getGridStringOperators,
} from "@mui/x-data-grid-pro"
import { GRID_PAGE_SIZE_OPTIONS } from "@core/constants"
import { formatDateAndTime } from "@/core/utils"
import { AutoRefreshSwitch } from "./autoRefreshSwitch"
import { Box } from "@mui/material"

const getUpdateTypeText = (status: TG.NcUpdateType): string => {
  switch (status) {
    case "text":
      return "Текст"
    case "media":
      return "Медиа"
    case "left":
      return "Покинул"
    case "joined":
      return "Присоединился"
    default:
      return "-"
  }
}

const stringFilterOperators = getGridStringOperators().filter(
  (o) => o?.value === "contains",
)
const singleSelectFilterOperators = getGridSingleSelectOperators().filter(
  (o) => o?.value === "is",
)

const AUTOREFRESH_INTERVAL = 60000

interface IProps {}
export const NcUpdates: FC<IProps> = (props) => {
  const [autorefresh, setAutorefresh] = useState(AUTOREFRESH_INTERVAL)
  const [page, setPage] = useState(0)
  const [pageSize, setPageSize] = useState(GRID_PAGE_SIZE_OPTIONS[2])
  const [find, setFind] = useState<
    Partial<TG.FindNcMessagesReq["queryParams"]["find"]>
  >({})

  const { isFetching, data } = useFindNcUpdatesQuery(
    {
      queryParams: {
        find,
        take: pageSize,
        skip: page * pageSize,
      },
    },
    { pollingInterval: autorefresh },
  )
  const [updates, total] = data ?? []

  const columns = useMemo<GridColDef<TG.INcUpdate>[]>(
    () => [
      {
        headerName: "Дата",
        field: "date",
        valueFormatter: ({ value }) => formatDateAndTime(value),
        flex: 1.2,
        filterable: false,
        sortable: false,
      },
      {
        headerName: "Тип события",
        field: "type",
        type: "singleSelect",
        valueOptions: Object.values(TG.NcUpdateType),
        getOptionLabel: getUpdateTypeText,
        valueFormatter: ({ value }) => getUpdateTypeText(value),
        filterOperators: singleSelectFilterOperators,
        flex: 0.8,
        sortable: false,
      },
      {
        headerName: "Чат",
        field: "chat_title",
        flex: 1.5,
        filterOperators: stringFilterOperators,
        sortable: false,
      },
      {
        headerName: "Юзернейм чата",
        field: "chat_username",
        flex: 1,
        filterOperators: stringFilterOperators,
        sortable: false,
      },
      {
        headerName: "ID участника",
        field: "from_id",
        flex: 1,
        filterOperators: stringFilterOperators,
        sortable: false,
      },
      {
        headerName: "Юзернейм участника",
        field: "from_username",
        flex: 1,
        filterOperators: stringFilterOperators,
        sortable: false,
      },
      {
        headerName: "Имя участника",
        field: "from_first_name",
        flex: 2,
        filterOperators: stringFilterOperators,
        sortable: false,
      },
      {
        headerName: "Текст",
        field: "text",
        flex: 4,
        filterOperators: stringFilterOperators,
        sortable: false,
      },
    ],
    [],
  )

  const updatePagination = ({ page, pageSize }: GridPaginationModel) => {
    setPage(page)
    setPageSize(pageSize)
  }

  const handleFilterModeChange = (
    model: GridFilterModel,
    details: GridCallbackDetails<"filter">,
  ) => {
    setPage(0)

    const find = model.items.reduce<
      Partial<TG.FindNcMembersReq["queryParams"]["find"]>
    >((prev, cur) => ({ ...prev, [cur.field]: cur.value }), {})
    setFind(find)
  }

  return (
    <>
      <DataGrid<TG.INcUpdate>
        getRowId={(row) => row.id}
        columns={columns}
        rows={updates ?? []}
        loading={isFetching}
        rowCount={total ?? 0}
        unstable_headerFilters
        pagination
        paginationMode="server"
        pageSizeOptions={GRID_PAGE_SIZE_OPTIONS}
        paginationModel={{
          page,
          pageSize,
        }}
        onPaginationModelChange={updatePagination}
        filterMode="server"
        sortingMode="server"
        filterDebounceMs={700}
        onFilterModelChange={handleFilterModeChange}
        disableColumnFilter
        initialState={{
          filter: {
            filterModel: {
              items: [],
            },
          },
          sorting: {
            sortModel: [
              {
                field: "date",
                sort: "desc",
              },
            ],
          },
        }}
        slotProps={{
          toolbar: {
            onChange: (v) => setAutorefresh(v ? AUTOREFRESH_INTERVAL : 0),
            value: !!autorefresh,
          },
        }}
        slots={{
          headerFilterMenu: null,
          toolbar: (props) => (
            <Box display="flex" flexDirection="row-reverse" px={2}>
              <AutoRefreshSwitch {...props} />
            </Box>
          ),
        }}
      />
    </>
  )
}
