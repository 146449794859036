import { FC } from "react"
import { DataGrid, PreviousLocation } from "@/core/components"
import { ADV } from "../../interfaces"
import {
  GridActionsCellItem,
  GridColDef,
  getGridStringOperators,
} from "@mui/x-data-grid-pro"
import {
  getAdvState,
  setAgentsPaginationModel,
  useAgentsFullListQuery,
} from "../../store"
import { GRID_PAGE_SIZE_OPTIONS } from "@/core/constants"
import { useAppDispatch, useAppSelector } from "@/modules/app/hooks"
import { wrapFilterOperator } from "@/core/utils"
import { Link } from "@mui/material"
import { formatTGLink } from "../utils/string"
import { Preview } from "@mui/icons-material"
import { useLocation, useNavigate } from "@/core/hooks"

interface IProps {}
export const AgentsTable: FC<IProps> = (props) => {
  const navigate = useNavigate<PreviousLocation>()
  const { state } = useLocation<PreviousLocation>()
  const dispatch = useAppDispatch()
  const {
    agents: { page, pageSize },
    agentsFilters,
  } = useAppSelector(getAdvState)
  const { isFetching, data } = useAgentsFullListQuery({
    queryParams: {
      page: page + 1,
      limit: pageSize,
      ...agentsFilters,
    },
  })

  const columns: GridColDef<ADV.AgentsFullListRes["data"][number]>[] = [
    {
      headerName: "ID",
      field: "id",
      flex: 1,
    },
    {
      headerName: "Имя",
      field: "name",
      flex: 3,
    },
    {
      headerName: "Контакты",
      field: "contacts",
      valueGetter: ({ value }) => value ?? [],
      renderCell: ({ value }) =>
        Array.isArray(value) &&
        value.map((v: ADV.Contact) => (
          <Link
            color="secondary"
            href={formatTGLink(v.name)}
            target="_blank"
            referrerPolicy="no-referrer"
            key={v.id}
          >
            {v.name}
          </Link>
        )),
      filterOperators: getGridStringOperators().map((f) =>
        wrapFilterOperator<NonNullable<ADV.Agent["contacts"]>>(
          f,
          (applyFilterFn, params) =>
            applyFilterFn({
              ...params,
              value: params.value?.map((v: ADV.Contact) => v.name).join(),
            }),
          (applyFilterFnV7, value, row, col, apiRef) =>
            applyFilterFnV7(value?.join(), row, col, apiRef),
        ),
      ),
      getApplyQuickFilterFn: (value, col) => {
        return (params) =>
          params.value?.some(
            (v: any) =>
              v?.toString().toLowerCase().includes(value.toLowerCase()),
          )
      },
      flex: 3,
    },
    {
      headerName: "Геолокация",
      field: "geos",
      valueGetter: ({ value }) => value ?? [],
      renderCell: ({ value }) =>
        Array.isArray(value) && value.map((v) => v.name).join(", "),
      filterOperators: getGridStringOperators().map((f) =>
        wrapFilterOperator<NonNullable<ADV.Agent["geos"]>>(
          f,
          (applyFilterFn, params) =>
            applyFilterFn({ ...params, value: params.value?.name.join() }),
          (applyFilterFnV7, value, row, col, apiRef) =>
            applyFilterFnV7(value?.name.join(), row, col, apiRef),
        ),
      ),
      getApplyQuickFilterFn: (value, col) => {
        return (params) =>
          params.value?.name.some(
            (v: any) =>
              v?.toString().toLowerCase().includes(value.toLowerCase()),
          )
      },
      flex: 3,
    },
    {
      headerName: "Тип",
      field: "type",
      flex: 3,
    },
    {
      headerName: "Ссылка",
      field: "url",
      renderCell: ({ value }) => (
        <Link
          color="secondary"
          href={formatTGLink(value)}
          target="_blank"
          referrerPolicy="no-referrer"
        >
          {value}
        </Link>
      ),
      flex: 3,
    },
  ]

  return (
    <DataGrid<ADV.AgentsFullListRes["data"][number]>
      columns={columns}
      rows={data?.data ?? []}
      getRowId={(row) => row.id}
      rowCount={data?.count ?? 0}
      loading={isFetching}
      // unstable_headerFilters
      filterMode="client"
      sortingMode="client"
      pagination
      paginationMode="server"
      pageSizeOptions={GRID_PAGE_SIZE_OPTIONS}
      onPaginationModelChange={(model) =>
        dispatch(setAgentsPaginationModel(model))
      }
      initialState={{
        sorting: {
          sortModel: [{ field: "id", sort: "desc" }],
        },
        pagination: { paginationModel: { page, pageSize } },
      }}
      rowActions={[
        (r) => (
          <GridActionsCellItem
            label="Открыть"
            icon={<Preview />}
            onClick={() =>
              navigate(r.id.toString(), {
                state: {
                  from: window.location.pathname,
                  title: "К списку контрагентов",
                },
              })
            }
          />
        ),
      ]}
    />
  )
}
