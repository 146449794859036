import { Box, CircularProgress, Grid } from "@mui/material"
import {
  getAdvState,
  setPaymentsFilters,
  usePaymentFiltersListQuery,
} from "../../store"
import { FormContainer, useForm } from "react-hook-form-mui"
import { ADV } from "../../interfaces"
import { useEffect } from "react"
import { useAppDispatch, useAppSelector } from "@/modules/app/hooks"
import equal from "fast-deep-equal"
import { Filter } from "./filter"

const FILTERS_LABELS: { [key: string]: string } = {
  agents: "Контрагент",
  agent_types: "Тип",
  geos: "Геолокация",
  campaigns: "Кампания",
  order_types: "Тип заказа",
}

interface IProps {}
interface IFormData extends ADV.Filters {}
export const PaymentFilters = (props: IProps) => {
  const dispatch = useAppDispatch()
  const { paymentsFilters } = useAppSelector(getAdvState)
  const { data, isLoading } = usePaymentFiltersListQuery({})
  const formContext = useForm<IFormData>({ defaultValues: paymentsFilters })

  const watch = formContext.watch()
  useEffect(() => {
    if (!formContext.formState.isDirty || equal(watch, paymentsFilters)) {
      return
    }
    dispatch(setPaymentsFilters(watch))
  }, [formContext.formState])

  if (isLoading) {
    return (
      <Box display="flex" justifyContent="center" my={1}>
        <CircularProgress />
      </Box>
    )
  }

  return (
    <FormContainer formContext={formContext}>
      <Grid container spacing={1} alignItems="stretch" my={1}>
        {Object.keys(data ?? {}).map((key) => (
          <Grid item xs={12} sm={6} md={4} xl={2} key={`filter_${key}`}>
            <Filter
              name={key}
              label={FILTERS_LABELS[key]}
              isLoading={isLoading}
              multiple={true}
              options={data?.[key]}
            />
          </Grid>
        ))}
      </Grid>
    </FormContainer>
  )
}
