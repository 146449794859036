import { ADV } from "../../interfaces"
import { AutocompleteElement, FieldValues, Path } from "react-hook-form-mui"
import { useTypesListQuery } from "../../store"

interface IProps<T extends FieldValues> extends ADV.TypeBelong {
  name: Path<T>
  label?: string
  required?: boolean
  disabled?: boolean
}
type IField = {
  [x: string]: ADV.Type["id"] | undefined
}

export const Type = <T extends FieldValues = IField>(props: IProps<T>) => {
  const { required, disabled, name, label, for: forType } = props
  const { data, isFetching: isLoading } = useTypesListQuery({
    queryParams: { table: forType },
  })

  return (
    <AutocompleteElement<T>
      name={name}
      label={label ?? "Тип"}
      loading={isLoading}
      matchId
      required={required}
      autocompleteProps={{
        disabled,
        fullWidth: true,
        getOptionLabel: (option: ADV.Type) => option?.name,
      }}
      options={data ?? []}
    />
  )
}
