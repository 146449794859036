import { FC, useEffect, useMemo, useState } from "react"
import {
  SelectElement,
  useFormContext,
  useFormState,
} from "react-hook-form-mui"
import { IMailingFormData } from "./csvForm"
import { BodyFieldsPropperties } from "../../../constants"
import { Stack, Step, StepLabel, Stepper } from "@mui/material"
import { useMailingFields } from "@/modules/telegram/hooks/useMailingFields"

interface IProps {}
export interface IFormData {}

export const CsvStepper: FC<IProps> = (props) => {
  const [activeStep, setActiveStep] = useState(0)
  const formContext = useFormContext<IMailingFormData>()
  const formState = useFormState<IMailingFormData>()
  const csvRows = formContext.watch("rows")
  const method = formContext.watch("method")
  const fields = useMailingFields(method).filter(
    (key) => !BodyFieldsPropperties[method][key].hidden,
  )
  const collationFields = fields.map(
    (f) => `collation.${f}`,
  ) as `collation.${string &
    keyof (typeof BodyFieldsPropperties)[typeof method]}`[]

  useEffect(() => {
    if (!csvRows) {
      setActiveStep(0)
    } else if (!!csvRows && !formContext.formState.isValid) {
      setActiveStep(1)
    } else if (!!csvRows && formContext.formState.isValid) {
      setActiveStep(2)
    } else if (
      !csvRows &&
      formContext.formState.isValid &&
      formState.isSubmitting
    ) {
      setActiveStep(3)
    }
  }, [csvRows, method, collationFields, formState])

  return (
    <Stepper activeStep={activeStep} alternativeLabel>
      <Step completed={activeStep > 0}>
        <StepLabel>
          Выберите .csv файл, содержащий данные для рассылки
        </StepLabel>
      </Step>
      <Step completed={activeStep > 1}>
        <StepLabel>
          Выберите тип рассылки и сопоставьте столбцы таблицы с полями рассылки
        </StepLabel>
      </Step>
      <Step completed={activeStep > 2}>
        <StepLabel>
          Проверьте правильность сопоставления в таблице предпросмотра и
          запустите рассылку
        </StepLabel>
      </Step>
    </Stepper>
  )
}
