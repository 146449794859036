import { Form } from "@core/components";
import { FC } from "react";
import { IFormProps } from "@core/interfaces";
import { Button, Link, Typography } from "@mui/material";
import { getTGStatState, setLink1, setLink2, setRefCode } from "../store";
import { TextFieldElement } from "react-hook-form-mui";
import { TGStatStatLinkRegExp } from "../constants";
import { useAppDispatch, useAppSelector } from "@/modules/app/hooks";
import { RefCodes } from "@/modules/adv/components/formFields/refCodesSelect";
import { Adv } from "@/modules/adv/interfaces";

interface IProps 
extends IFormProps<{}, {}> {

}
interface IFormData {
  link1: string;
  link2: string;
  referral_code: string;
}

export const LinksForm: FC<IProps> = ({afterCreate, afterUpdate, ...props}) => {
  const token = useAppSelector(getTGStatState).token;
  if (!token) {
    return;
  }
  const dispatch = useAppDispatch();
  const state = useAppSelector(getTGStatState);

  return <Form<IFormData>
    actions={[
      <Button
        variant='outlined'
        fullWidth
        type='submit'
      >
        Искать пересечения
      </Button>,
    ]}
    onSubmit={({link1, link2, referral_code}) => {      
      dispatch(setLink1(link1));
      dispatch(setLink2(link2));
      dispatch(setRefCode(referral_code));
    }}
    formContextParams={{
      defaultValues: {
        link1: state.link1,
        link2: state.link2,
        referral_code: state.refCode,
      }
    }}
    reValidateMode='onChange'
    // formStackProps={{
    //   direction: 'row',
    //   alignItems: 'baseline',
    //   justifyContent: 'stretch',
    // }}
  >
    <Typography>
      Скопируйте ссылки на статистику из&nbsp;
      <Link
        color='secondary'
        href="https://tgstat.com/ru/my/channels"
        target='_blank'
        rel='noreferrer'
      >
        TGStat
      </Link>
    </Typography>
    <Typography variant='body2' color={theme => theme.palette.grey[600]}>
      Пример ссылки: https://tgstat.ru/chat/@channel/stat/invite-links/123456789/members
    </Typography>
    <TextFieldElement
      label='Ссылка 1'
      name='link1'
      required
      validation={{
        pattern: TGStatStatLinkRegExp,
      }}
    />
    <TextFieldElement
      label='Ссылка 2'
      name='link2'
      required
      validation={{
        pattern: TGStatStatLinkRegExp,
      }}
    />
    <TextFieldElement
      label='Реф. код'
      name='referral_code'
      required
    />
    
  </Form>
}
