import {
  BaseQueryFn,
  createApi,
  fetchBaseQuery,
} from "@reduxjs/toolkit/query/react"
import { EndpointBuilder } from "@reduxjs/toolkit/dist/query/endpointDefinitions"
import {
  RootState,
  dynamicMiddlewaresInstance,
  registerReducer,
} from "@core/store"
import { baseQueryWithReauth } from "@/modules/app/store"
import { TG, TgMailing } from "../interfaces"

const tagTypes = [
  "Updates",
  "NcMembers",
  "ActiveMailings",
  "FinishedMailings",
  "MailingTypes",
] as const

const reducerPath: string = "telegramApi"
export const baseTGStatApiURL = new URL(
  import.meta.env.REACT_APP_TGSTAT_API,
  window.location.origin,
).toString()
export const baseUrl = `${
  baseTGStatApiURL.endsWith("/") ? baseTGStatApiURL : baseTGStatApiURL + "/"
}telegram`
const baseQuery: BaseQueryFn = fetchBaseQuery({
  baseUrl,
  prepareHeaders: (headers, { getState }) => {
    const token = (getState() as RootState).auth.token
    if (token) {
      headers.set("authorization", `Bearer ${token}`)
    }
    return headers
  },
})

type ApiEndpointBuider = EndpointBuilder<
  typeof baseQuery,
  (typeof tagTypes)[number],
  typeof reducerPath
>

const endpoints = (build: ApiEndpointBuider) => ({
  findUpdates: build.query<TG.FindUpdatesRes, TG.FindUpdatesReq>({
    query: ({ queryParams }) => ({
      url: `find-text`,
      method: "GET",
      params: {
        take: 100,
        skip: 0,
        ...queryParams,
      },
    }),
    providesTags: ["Updates"],
  }),
  findNcMembers: build.query<TG.FindNcMembersRes, TG.FindNcMembersReq>({
    query: ({ queryParams: { take, skip, find, groupByDate } }) => ({
      url: `find-nc-member`,
      method: "GET",
      params: {
        take: take ?? 100,
        skip: skip ?? 0,
        find: JSON.stringify(find),
        groupByDate,
      },
    }),
    providesTags: ["NcMembers"],
  }),
  findNcUpdates: build.query<TG.FindNcMessagesRes, TG.FindNcMessagesReq>({
    query: ({ queryParams: { take, skip, find } }) => ({
      url: `find-updates`,
      method: "GET",
      params: {
        take: take ?? 100,
        skip: skip ?? 0,
        find: JSON.stringify(find),
      },
    }),
    providesTags: ["NcMembers"],
  }),
  createMailing: build.mutation<
    TgMailing.CreateMailingRes,
    TgMailing.CreateMailingReq
  >({
    query: ({ body }) => {
      return {
        url: `mailing`,
        method: "POST",
        body,
      }
    },
    invalidatesTags: ["ActiveMailings"],
  }),
  activeMailings: build.query<
    TgMailing.ActiveMailingsRes,
    TgMailing.ActiveMailingsReq
  >({
    query: () => ({
      url: `active-mailings`,
      method: "GET",
    }),
    providesTags: ["ActiveMailings"],
  }),
  finishedMailings: build.query<
    TgMailing.FinishedMailingsRes,
    TgMailing.FinishedMailingsReq
  >({
    query: () => ({
      url: `finished-mailings`,
      method: "GET",
    }),
    providesTags: ["FinishedMailings"],
  }),
  mailingTypes: build.query<
    TgMailing.MailingTypesRes,
    TgMailing.MailingTypesReq
  >({
    query: () => ({
      url: `mailing-types`,
      method: "GET",
    }),
    providesTags: ["MailingTypes"],
  }),
})

export const telegramApi = createApi({
  reducerPath,
  tagTypes,
  baseQuery: (...args) => baseQueryWithReauth(baseQuery, args),
  endpoints: (build) => ({
    ...endpoints(build),
  }),
})
dynamicMiddlewaresInstance.addMiddleware(telegramApi.middleware)
registerReducer(telegramApi.reducerPath, telegramApi.reducer)

export const {
  useFindUpdatesQuery,
  useFindNcMembersQuery,
  useFindNcUpdatesQuery,
  useCreateMailingMutation,
  useActiveMailingsQuery,
  useFinishedMailingsQuery,
  useMailingTypesQuery,
} = telegramApi
