import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react"
import {
  RootState,
  StateFromRoot,
  dynamicMiddlewaresInstance,
  registerReducer,
} from "@/core/store"
import { AuthState } from "."

export const baseAppApiURL = new URL(
  import.meta.env.REACT_APP_TGSTAT_API,
  window.location.origin,
).toString()
export const appApi = createApi({
  reducerPath: "appApi",
  baseQuery: fetchBaseQuery({
    baseUrl: `${
      baseAppApiURL.endsWith("/") ? baseAppApiURL : baseAppApiURL + "/"
    }`,
    prepareHeaders: (headers, { getState }) => {
      // By default, if we have a token in the store, let's use that for authenticated requests
      const token = (getState() as RootState<StateFromRoot<AuthState>>).auth
        .token
      if (token) {
        headers.set("authorization", `Bearer ${token}`)
      }
      return headers
    },
  }),
  tagTypes: [],
  endpoints: (build) => ({}),
})
dynamicMiddlewaresInstance.addMiddleware(appApi.middleware)
registerReducer(appApi.reducerPath, appApi.reducer)

export const {} = appApi
