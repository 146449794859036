import { useEffect, useRef, useState } from "react"
import { FieldValues, useFormContext } from "react-hook-form-mui"
import { CSVRow, IMailingFormData } from "./csvForm"
import { usePapaParse } from "react-papaparse"
import { FormControlLabel, Stack, Switch } from "@mui/material"
import { DropzoneArea, FileObject } from "mui-file-dropzone"

interface IProps {}
interface IField extends Pick<IMailingFormData, "method" | "rows"> {}

export const CSVField = <T extends FieldValues = IField>(props: IProps) => {
  const formContext = useFormContext<IMailingFormData>()
  // const dropzoneContext = useDropzone()
  const [files, setFiles] = useState<File[]>([])
  const [hasHeader, setHasHeader] = useState<boolean>(true)
  const fileObjects = useRef<FileObject[]>([])
  const { readString } = usePapaParse()
  const rows = formContext.watch("rows")

  useEffect(() => {
    formContext.resetField("rows")
    if (files.length === 0) {
      return
    }
    files[0].text().then((t) =>
      readString<typeof hasHeader extends true ? CSVRow : string[]>(t, {
        worker: true,
        header: hasHeader,
        complete: ({ data }) => {
          if (hasHeader) {
            if (Object.values(data?.at(-1) ?? {}).every((v) => v === "")) {
              data.pop()
            }
            formContext.setValue("rows", data as unknown as CSVRow[])
            return
          }
          formContext.setValue(
            "rows",
            data.map((row) =>
              row.reduce(
                (prev, cur, i) => ({ ...prev, [`col_${i}`]: cur }),
                {},
              ),
            ),
          )
        },
      }),
    )
  }, [files, hasHeader])

  useEffect(() => {
    if (!rows) {
      setFiles([])
      fileObjects.current = []
    }
  }, [rows])

  return (
    <Stack spacing={0}>
      <DropzoneArea
        onChange={(files) => {
          setFiles(files)
        }}
        acceptedFiles={["text/csv"]}
        clearOnUnmount
        showFileNames
        filesLimit={1}
        fileObjects={fileObjects.current}
        maxFileSize={50000000}
        // inputProps={dropzoneContext.getInputProps()}
      />
      <FormControlLabel
        label="Первая строка - заголовок"
        control={<Switch checked={hasHeader} />}
        onChange={(ev, value) => setHasHeader(value)}
      />
    </Stack>
  )
}
