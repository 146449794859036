import { useMemo } from "react"
import { BodyFieldsPropperties } from "../constants"
import { TgMailing } from "../interfaces"

export function useMailingFields(method: TgMailing.AvailableMethods) {
  return useMemo<(keyof (typeof BodyFieldsPropperties)[typeof method])[]>(
    () =>
      (
        Object.keys(
          BodyFieldsPropperties[method],
        ) as unknown as (keyof (typeof BodyFieldsPropperties)[typeof method])[]
      ).filter((f) => !BodyFieldsPropperties[method][f].hidden),
    [method],
  )
}
