import {
  FieldValues,
  TextFieldElement,
  useFormContext,
} from "react-hook-form-mui"
import { Stack, Typography } from "@mui/material"
import { IMailingFormData } from "./mailingForm"
import { InlineCode } from "@/core/components"

interface IProps {}
interface IFormData extends Pick<IMailingFormData, "credentials"> {}

export const MailingCredentials = <T extends FieldValues = IFormData>(
  props: IProps,
) => {
  // const formContext = useFormContext<IFormData>()
  // const values = formContext.watch(`credentials`)

  return (
    <Stack spacing={1}>
      <TextFieldElement
        label="Токен бота и ID пользователя"
        name={`credentials`}
        // value={values}
        required
        multiline
        rows={20}
        fullWidth
        sx={{ textarea: { resize: "both" } }}
        helperText={
          <Typography variant="caption">
            Формат: bot_token/user_id
            <br />
            Каждая запись с новой строки
            <br />
            <InlineCode>1213456:BotToken/9087654</InlineCode>
            <br />
            <InlineCode>1213456:BotToken/4576356</InlineCode>
            <br />
            <InlineCode>45645345:AnotherBotToken/234667</InlineCode>
          </Typography>
        }
      />
    </Stack>
  )
}
