import { FC } from "react"
import {
  DataGrid,
  FieldView,
  InlineCode,
  QuickSearchToolbar,
} from "@core/components"
import { GridColDef, gridClasses } from "@mui/x-data-grid-pro"
import { useGetCandidatesQuery, useUpdateCandidateMutation } from "../store"
import { Hr } from "../interfaces"
import { Stack } from "@mui/material"
import { formatDateAndTime } from "@/core/utils"
import equal from "fast-deep-equal"
import { Check, Close, QuestionMark } from "@mui/icons-material"

interface IProps {}

export const HrCandidatesTable: FC<IProps> = (props) => {
  const { data, isLoading } = useGetCandidatesQuery({})
  const [update, { isLoading: isUpdating }] = useUpdateCandidateMutation()

  const columns: GridColDef<Hr.Candidate>[] = [
    {
      headerName: "Данные",
      field: "user",
      renderCell: ({ value }) => (
        <Stack>
          <FieldView label="Город" value={value.city} />
          <FieldView label="Возраст" value={value.age} />
          <FieldView label="Пол" value={value.gender} />
          <FieldView
            label="Есть авто"
            value={
              typeof value.has_car === "boolean" ? (
                value.has_car ? (
                  <Check fontSize="small" />
                ) : (
                  <Close fontSize="small" />
                )
              ) : (
                <QuestionMark fontSize="small" />
              )
            }
            sx={{
              display: "flex",
              alignContent: "center",
            }}
          />
          <FieldView label="Депозит" value={value.deposit} />
          <FieldView label="Откуда узнал" value={value.source} />
          <FieldView
            label="Matrix id"
            value={
              value.matrix_id && <InlineCode>{value.matrix_id}</InlineCode>
            }
          />
          <FieldView
            label="Дата добавления"
            value={formatDateAndTime(value.date_create)}
          />
          <FieldView label="Реферал" value={value.referral} />
          <FieldView
            label="Ссылка на чат"
            value={value.chat_url && <InlineCode>{value.chat_url}</InlineCode>}
          />
        </Stack>
      ),
      valueGetter: (params) => {
        const { description, id, ...user } = params.row
        return user
      },
      getApplyQuickFilterFn: (value, col) => {
        return (params) =>
          Object.values(params.value).some(
            (v: any) =>
              v?.toString().toLowerCase().includes(value.toLowerCase()),
          )
      },
      sortable: false,
      flex: 4,
    },
    {
      headerName: "Описание",
      field: "description",
      sortable: false,
      flex: 8,
    },
    {
      headerName: "Результат",
      field: "result",
      sortable: false,
      editable: true,
      flex: 3,
    },
  ]

  const processRowUpdate = (upd: Hr.Candidate, old: Hr.Candidate) => {
    if (equal(upd, old)) {
      return old
    }
    if (typeof upd.id === "number") {
      updateCandidate(upd)
    }

    return upd
  }
  const updateCandidate = (upd: Hr.Candidate) => {
    const { id, result } = upd
    update({
      pathParams: {
        id,
      },
      body: { result },
    })
  }

  return (
    <DataGrid<Hr.Candidate>
      columns={columns}
      rows={data ?? []}
      loading={isLoading || isUpdating}
      paginationMode="client"
      getRowHeight={() => "auto"}
      sx={{
        [`&.MuiDataGrid-root .${gridClasses.cell}`]: {
          paddingTop: 2,
          paddingBottom: 2,
          paddingLeft: 1,
          paddingRight: 1,
        },
      }}
      slots={{ toolbar: QuickSearchToolbar }}
      canEdit
      processRowUpdate={processRowUpdate}
      onProcessRowUpdateError={(e) => console.error(e)}
      actionsColumnFlex={1}
    />
  )
}
