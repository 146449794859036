import { MenuListItem } from "@/modules/app/components"
import { List } from "@mui/material"

export const MarketplaceMenu = () => {
  return (
    <List disablePadding>
      <MenuListItem item={{ url: "products", title: "Товары" }} depth={0} />
      <MenuListItem
        item={{ url: "product-categories", title: "Категории товаров" }}
        depth={0}
      />
      <MenuListItem
        item={{ url: "product-payments", title: "Фасовки" }}
        depth={0}
      />
      <MenuListItem item={{ url: "cities", title: "Города" }} depth={0} />
      <MenuListItem item={{ url: "districts", title: "Районы" }} depth={0} />
      <MenuListItem item={{ url: "couriers", title: "Сотрудники" }} depth={0} />
      <MenuListItem
        item={{ url: "questionnaire", title: "Анкеты" }}
        depth={0}
      />
      <MenuListItem item={{ url: "procurements", title: "Закупы" }} depth={0} />
    </List>
  )
}
