import { FC } from "react"
import { AutocompleteElement, FieldValues } from "react-hook-form-mui"
import { ListboxComponent } from "@/core/components"
import { IMailingFormData } from "./manual/mailingForm"
import { TgMailing } from "../../interfaces"

interface IProps {}
interface IField extends Pick<IMailingFormData, "method"> {}

const ACTION_TYPE: Record<TgMailing.AvailableMethods, string> = {
  sendMessage: "Текст",
  sendChatAction: "Проверка на блокировку",
  sendAnimation: "Анимация (GIF)",
  sendPhoto: "Картинка",
  sendVideo: "Видео",
}
export const MethodSelectField = <T extends FieldValues = IField>(
  props: IProps,
) => {
  return (
    <AutocompleteElement
      name="method"
      label="Тип рассылки"
      required
      autocompleteProps={{
        getOptionLabel: (option: TgMailing.AvailableMethods) =>
          option ? `${ACTION_TYPE[option]}` : "Загрузка...",
        // getOptionKey: (option: AvailableMethods) =>
        //   option ? `${option[0]}` : "",
        isOptionEqualToValue: (
          option: TgMailing.AvailableMethods,
          value: TgMailing.AvailableMethods,
        ) => option === value,
        ListboxComponent: ListboxComponent,
        renderOption: (props, option: TgMailing.AvailableMethods, state) =>
          [props, `${ACTION_TYPE[option]}`, state] as React.ReactNode,
        fullWidth: true,
        disableClearable: true,
      }}
      options={Object.keys(ACTION_TYPE)}
    />
  )
}
