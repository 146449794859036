import { ApiMethods } from "typegram"
import { TgMailing } from "../interfaces"
import { ParseModeHelperText } from "../components/mailing/csv/helperText/parseModeHelperText"

export const AvailableMethods: TgMailing.AvailableMethods[] = [
  "sendMessage",
  "sendChatAction",
  "sendPhoto",
  "sendAnimation",
  "sendVideo",
]
const ANY_TEXT_REGEXP = /.+/
const URL_REGEXP = /^https?:\/\/.+$/
const INTEGER_REGEXP = /^-?\d+$/
const TOKEN_REGEXP = /^\d+:.+$/
const BOOL_REGEXP = /true|false/i
const PARSE_MODE_REGEXP = /Markdown|MarkdownV2|HTML/i
const CHAT_ACTION_REGEXP =
  /typing|upload_photo|record_video|upload_video|record_voice|upload_voice|upload_document|choose_sticker|find_location|record_video_note|upload_video_note/i

const CHAT_ID_COLUMN_HEADER_REGEXP = /.*id|user|юзер|ид.*/i
const TOKEN_COLUMN_HEADER_REGEXP = /.*token|bot|бот|токен.*/i
const TEXT_COLUMN_HEADER_REGEXP =
  /.*text|message|caption|текст|сообщение|подпись.*/i
const PARSE_MODE_COLUMN_HEADER_REGEXP = /.*format|parse|формат.*/i
const PHOTO_COLUMN_HEADER_REGEXP = /.*photo|img|image|url|фото|картинка.*/i
const GIF_COLUMN_HEADER_REGEXP =
  /.*gif|animation|image|url|гиф|картинка|анимация.*/i
const VIDEO_COLUMN_HEADER_REGEXP = /.*video|mp4|avi|url|видео.*/i
const NOTIFICATION_COLUMN_HEADER_REGEXP = /.*notif|alert|уведомл.*/i
const SPOILER_COLUMN_HEADER_REGEXP = /.*spoiler|hidd?e|спойлер|скрыть.*/i
const URL_PREVIEW_COLUMN_HEADER_REGEXP = /.*preview|web|предпросмотр.*/i

const BOOLEAN_PARSER: TgMailing.BodyFieldProperties<
  TgMailing.AvailableMethods,
  any
>["parseValue"] = (v) => typeof v === "string" && v.toLowerCase() === "true"

export const BaseParameters: Record<
  "chat_id" | "token",
  TgMailing.BodyFieldProperties<
    TgMailing.AvailableMethods,
    keyof Parameters<ApiMethods<any>[TgMailing.AvailableMethods]>[0]
  >
> = {
  chat_id: {
    required: true,
    validation: INTEGER_REGEXP,
    columnHeaderMatch: CHAT_ID_COLUMN_HEADER_REGEXP,
    name: "ID чата / пользователя",
  },
  token: {
    required: true,
    validation: TOKEN_REGEXP,
    columnHeaderMatch: TOKEN_COLUMN_HEADER_REGEXP,
    name: "Токен бота",
  },
}

export const SendMessageParameters: TgMailing.BodyFieldsDefinitions<
  "sendMessage",
  keyof Omit<Parameters<ApiMethods<any>["sendMessage"]>[0], "chat_id">
>["sendMessage"] = {
  text: {
    required: true,
    validation: ANY_TEXT_REGEXP,
    columnHeaderMatch: TEXT_COLUMN_HEADER_REGEXP,
    name: "Текст рассылки",
  },
  message_thread_id: {
    required: false,
    validation: INTEGER_REGEXP,
    hidden: true,
  },
  parse_mode: {
    required: false,
    validation: PARSE_MODE_REGEXP,
    columnHeaderMatch: PARSE_MODE_COLUMN_HEADER_REGEXP,
    name: "Форматирование",
    helperText: ParseModeHelperText,
    hidden: false,
  },
  entities: {
    required: false,
    validation: undefined,
    hidden: true,
  },
  disable_web_page_preview: {
    name: "Отключить предпросмотр веб-страниц",
    required: false,
    validation: BOOL_REGEXP,
    parseValue: BOOLEAN_PARSER,
    columnHeaderMatch: URL_PREVIEW_COLUMN_HEADER_REGEXP,
    hidden: false,
  },
  disable_notification: {
    name: "Отключить уведомление",
    required: false,
    validation: BOOL_REGEXP,
    parseValue: BOOLEAN_PARSER,
    columnHeaderMatch: NOTIFICATION_COLUMN_HEADER_REGEXP,
    hidden: false,
  },
  protect_content: {
    name: "Защитить контент",
    required: false,
    validation: BOOL_REGEXP,
    parseValue: BOOLEAN_PARSER,
    helperText: "Запретить пересылать сообщение и делать скриншоты",
    hidden: true,
  },
  reply_to_message_id: {
    required: false,
    validation: INTEGER_REGEXP,
    hidden: true,
  },
  allow_sending_without_reply: {
    required: false,
    validation: BOOL_REGEXP,
    parseValue: BOOLEAN_PARSER,
    hidden: true,
  },
  reply_markup: {
    required: false,
    validation: undefined,
    hidden: true,
  },
}
export const SendPhotoParameters: TgMailing.BodyFieldsDefinitions<
  "sendPhoto",
  keyof Omit<Parameters<ApiMethods<any>["sendPhoto"]>[0], "chat_id">
>["sendPhoto"] = {
  photo: {
    required: true,
    validation: URL_REGEXP,
    columnHeaderMatch: PHOTO_COLUMN_HEADER_REGEXP,
    name: "Картинка",
    helperText: "Ссылка на картинку",
  },
  caption: {
    required: false,
    validation: ANY_TEXT_REGEXP,
    columnHeaderMatch: TEXT_COLUMN_HEADER_REGEXP,
    name: "Текст рассылки",
  },
  parse_mode: {
    required: false,
    validation: PARSE_MODE_REGEXP,
    columnHeaderMatch: PARSE_MODE_COLUMN_HEADER_REGEXP,
    name: "Форматирование",
    helperText: ParseModeHelperText,
  },
  protect_content: {
    name: "Защитить контент",
    validation: BOOL_REGEXP,
    parseValue: BOOLEAN_PARSER,
    helperText: "Запретить пересылать сообщение и делать скриншоты",
    required: false,
    hidden: true,
  },
  disable_notification: {
    name: "Отключить уведомление",
    validation: BOOL_REGEXP,
    parseValue: BOOLEAN_PARSER,
    columnHeaderMatch: NOTIFICATION_COLUMN_HEADER_REGEXP,
    required: false,
    hidden: false,
  },
  reply_to_message_id: {
    validation: INTEGER_REGEXP,
    required: false,
    hidden: true,
  },
  allow_sending_without_reply: {
    validation: BOOL_REGEXP,
    parseValue: BOOLEAN_PARSER,
    required: false,
    hidden: true,
  },
  reply_markup: {
    validation: undefined,
    required: false,
    hidden: true,
  },
  caption_entities: {
    validation: undefined,
    required: false,
    hidden: true,
  },
  has_spoiler: {
    name: "Спрятать под спойлер",
    validation: BOOL_REGEXP,
    parseValue: BOOLEAN_PARSER,
    columnHeaderMatch: SPOILER_COLUMN_HEADER_REGEXP,
    helperText: "Картинка будет размыта, пока на неё не нажмут",
    required: false,
    hidden: false,
  },
  message_thread_id: {
    required: false,
    validation: undefined,
    hidden: true,
  },
}
export const SendAnimationParameters: TgMailing.BodyFieldsDefinitions<
  "sendAnimation",
  keyof Omit<Parameters<ApiMethods<any>["sendAnimation"]>[0], "chat_id">
>["sendAnimation"] = {
  animation: {
    required: true,
    validation: URL_REGEXP,
    columnHeaderMatch: GIF_COLUMN_HEADER_REGEXP,
    name: "Анимация (GIF)",
    helperText: "Ссылка на анимацию",
  },
  caption: {
    required: false,
    validation: ANY_TEXT_REGEXP,
    columnHeaderMatch: TEXT_COLUMN_HEADER_REGEXP,
    name: "Текст рассылки",
  },
  parse_mode: {
    required: false,
    validation: PARSE_MODE_REGEXP,
    columnHeaderMatch: PARSE_MODE_COLUMN_HEADER_REGEXP,
    name: "Форматирование",
    helperText: ParseModeHelperText,
  },
  protect_content: {
    name: "Защитить контент",
    validation: BOOL_REGEXP,
    parseValue: BOOLEAN_PARSER,
    helperText: "Запретить пересылать сообщение и делать скриншоты",
    required: false,
    hidden: true,
  },
  disable_notification: {
    name: "Отключить уведомление",
    validation: BOOL_REGEXP,
    parseValue: BOOLEAN_PARSER,
    columnHeaderMatch: NOTIFICATION_COLUMN_HEADER_REGEXP,
    required: false,
    hidden: false,
  },
  reply_to_message_id: {
    validation: INTEGER_REGEXP,
    required: false,
    hidden: true,
  },
  allow_sending_without_reply: {
    validation: BOOL_REGEXP,
    parseValue: BOOLEAN_PARSER,
    required: false,
    hidden: true,
  },
  reply_markup: {
    validation: undefined,
    required: false,
    hidden: true,
  },
  caption_entities: {
    validation: undefined,
    required: false,
    hidden: true,
  },
  has_spoiler: {
    name: "Спрятать под спойлер",
    validation: BOOL_REGEXP,
    parseValue: BOOLEAN_PARSER,
    columnHeaderMatch: SPOILER_COLUMN_HEADER_REGEXP,
    helperText: "Обложка анимации будет размыта, пока на неё не нажмут",
    required: false,
    hidden: false,
  },
  message_thread_id: {
    required: false,
    validation: undefined,
    hidden: true,
  },
  duration: {
    required: false,
    validation: undefined,
    hidden: true,
  },
  width: {
    required: false,
    validation: undefined,
    hidden: true,
  },
  height: {
    required: false,
    validation: undefined,
    hidden: true,
  },
  thumbnail: {
    required: false,
    validation: undefined,
    hidden: true,
  },
}
export const SendVideoParameters: TgMailing.BodyFieldsDefinitions<
  "sendVideo",
  keyof Omit<Parameters<ApiMethods<any>["sendVideo"]>[0], "chat_id">
>["sendVideo"] = {
  video: {
    required: true,
    validation: URL_REGEXP,
    columnHeaderMatch: VIDEO_COLUMN_HEADER_REGEXP,
    name: "Видео",
    helperText: "Ссылка на видео",
  },
  caption: {
    required: false,
    validation: ANY_TEXT_REGEXP,
    columnHeaderMatch: TEXT_COLUMN_HEADER_REGEXP,
    name: "Текст рассылки",
  },
  parse_mode: {
    required: false,
    validation: PARSE_MODE_REGEXP,
    columnHeaderMatch: PARSE_MODE_COLUMN_HEADER_REGEXP,
    name: "Форматирование",
    helperText: ParseModeHelperText,
  },
  protect_content: {
    name: "Защитить контент",
    validation: BOOL_REGEXP,
    parseValue: BOOLEAN_PARSER,
    helperText: "Запретить пересылать сообщение и делать скриншоты",
    required: false,
    hidden: true,
  },
  disable_notification: {
    name: "Отключить уведомление",
    validation: BOOL_REGEXP,
    parseValue: BOOLEAN_PARSER,
    columnHeaderMatch: NOTIFICATION_COLUMN_HEADER_REGEXP,
    required: false,
    hidden: false,
  },
  reply_to_message_id: {
    validation: INTEGER_REGEXP,
    required: false,
    hidden: true,
  },
  allow_sending_without_reply: {
    validation: BOOL_REGEXP,
    parseValue: BOOLEAN_PARSER,
    required: false,
    hidden: true,
  },
  reply_markup: {
    validation: undefined,
    required: false,
    hidden: true,
  },
  caption_entities: {
    validation: undefined,
    required: false,
    hidden: true,
  },
  has_spoiler: {
    name: "Спрятать под спойлер",
    validation: BOOL_REGEXP,
    parseValue: BOOLEAN_PARSER,
    columnHeaderMatch: SPOILER_COLUMN_HEADER_REGEXP,
    helperText: "Обложка видео будет размыта, пока на него не нажмут",
    required: false,
    hidden: false,
  },
  message_thread_id: {
    required: false,
    validation: undefined,
    hidden: true,
  },
  duration: {
    required: false,
    validation: undefined,
    hidden: true,
  },
  width: {
    required: false,
    validation: undefined,
    hidden: true,
  },
  height: {
    required: false,
    validation: undefined,
    hidden: true,
  },
  thumbnail: {
    required: false,
    validation: undefined,
    hidden: true,
  },
  supports_streaming: {
    required: false,
    validation: undefined,
    parseValue: BOOLEAN_PARSER,
    hidden: true,
    default: true,
  },
}
export const SendChatActionParameters: TgMailing.BodyFieldsDefinitions<
  "sendChatAction",
  keyof Omit<Parameters<ApiMethods<any>["sendChatAction"]>[0], "chat_id">
>["sendChatAction"] = {
  action: {
    required: true,
    validation: CHAT_ACTION_REGEXP,
    hidden: true,
    default: "typing",
  },
  message_thread_id: {
    required: false,
    validation: undefined,
    hidden: true,
  },
}

export const BodyFieldsPropperties = {
  sendMessage: { ...BaseParameters, ...SendMessageParameters },
  sendChatAction: { ...BaseParameters, ...SendChatActionParameters },
  sendPhoto: { ...BaseParameters, ...SendPhotoParameters },
  sendVideo: { ...BaseParameters, ...SendVideoParameters },
  sendAnimation: { ...BaseParameters, ...SendAnimationParameters },
} as const
