import { createSlice } from "@reduxjs/toolkit"
import type { PayloadAction } from "@reduxjs/toolkit"
import { RootState, StateFromRoot, registerReducer, store } from "@core/store"

const STATE_KEY = "tgstat"
export const CONFIG_KEY = `${STATE_KEY}_state`
interface IState {
  token?: string
  link1?: string
  link2?: string
  refCode?: string
}

const persistantState = localStorage.getItem(CONFIG_KEY)
const emptyState: IState = {}

const initialState: IState = persistantState
  ? JSON.parse(persistantState)
  : emptyState

export const tgstatSlice = createSlice({
  name: "tgstat",
  initialState: initialState,
  reducers: {
    setToken: (state, { payload: token }: PayloadAction<string>) => {
      state.token = token
    },
    setLink1: (state, { payload }: PayloadAction<string>) => {
      state.link1 = payload
    },
    setLink2: (state, { payload }: PayloadAction<string>) => {
      state.link2 = payload
    },
    setRefCode: (state, { payload }: PayloadAction<string>) => {
      state.refCode = payload
    },
    logout: (state) => {
      localStorage.removeItem(CONFIG_KEY)
      Object.keys(state).forEach((k) => delete state[k as keyof IState])
    },
  },
})
registerReducer(tgstatSlice.name, tgstatSlice.reducer)
store.subscribe(() => {
  localStorage.setItem(CONFIG_KEY, JSON.stringify(store.getState().tgstat))
})

export const { setToken, setLink1, setLink2, setRefCode, logout } =
  tgstatSlice.actions

export const getTGStatState = (state: RootState<StateFromRoot<IState>>) =>
  state[STATE_KEY]
