import { Typography } from "@mui/material"
import { format } from "date-fns"
import { FC } from "react"
import { DateTimePickerElement } from "react-hook-form-mui"

interface IProps {
  required?: boolean
}
interface IField {
  scheduleDate?: Date | undefined
}

export const ScheduleDate = <T extends IField>({ required }: IProps) => {
  return (
    <DateTimePickerElement<IField>
      name="scheduleDate"
      label="Отложенный запуск"
      required={required}
      disablePast
      helperText={
        <Typography variant="caption">
          Часовой пояс: {format(Date.now(), "OOOO")}
        </Typography>
      }
    />
  )
}
