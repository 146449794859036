import {
  Alert,
  AlertTitle,
  Box,
  BoxProps,
  CircularProgress,
} from "@mui/material"
import { FC, useEffect, useRef, useState } from "react"
import { getAppThemeName } from "../../../app/store"
import { useAppSelector } from "../../../app/hooks"
import { Metabase } from "../../interfaces"
import { useMetabaseEmbedQuery } from "../../store"

interface IProps extends Omit<BoxProps, "id"> {
  type: Metabase.EmbedType
  id: number
  params?: Metabase.EmbedQueryParams
}

export const MetabaseEmbed: FC<IProps> = ({
  type,
  id,
  params,
  ...boxProps
}) => {
  const [isLoading, setIsLoading] = useState(true)
  const iframeRef = useRef<HTMLIFrameElement | null>(null)
  const theme = useAppSelector(getAppThemeName)
  const isDark = theme === "dark"
  params = { ...params, bordered: false, titled: false }
  if (params?.theme === undefined && isDark) {
    params = { ...params, theme: "night" }
  }
  const {
    data,
    isFetching: isUrlLoading,
    isError,
  } = useMetabaseEmbedQuery(
    {
      pathParams: { type, id },
      queryParams: params,
    },
    {
      refetchOnMountOrArgChange: import.meta.env
        .REACT_APP_METABASE_IFRAME_URL_EXPIRATION,
    },
  )
  useEffect(() => {
    setIsLoading(true)
  }, [isUrlLoading, data])

  return (
    <Box height="100%" width="100%" {...boxProps}>
      {isLoading && !isError && (
        <Box display="flex" justifyContent="center" alignItems="center">
          <CircularProgress />
        </Box>
      )}
      {isError && (
        <Alert>
          <AlertTitle>Произошла ошибка</AlertTitle>
        </Alert>
      )}
      {data?.url && (
        <iframe
          ref={iframeRef}
          onLoad={(e) => {
            setIsLoading(false)
          }}
          src={data?.url}
          height={isLoading ? 0 : "100%"}
          width={isLoading ? 0 : "100%"}
          frameBorder={0}
          allowTransparency
          hidden={isLoading}
        />
      )}
    </Box>
  )
}
