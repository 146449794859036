import { ListboxComponent } from "@/core/components"
import { AutocompleteElement } from "react-hook-form-mui"
import { ADV } from "../../interfaces"

interface IFilterProps {
  name: string
  label: string
  isLoading: boolean
  multiple: boolean
  options: ADV.Filter[] | undefined
}
export const Filter = ({
  name,
  label,
  isLoading,
  multiple,
  options,
}: IFilterProps) => {
  return (
    <AutocompleteElement
      name={name}
      label={label}
      loading={isLoading}
      multiple={multiple}
      showCheckbox={multiple}
      matchId
      autocompleteProps={{
        fullWidth: true,
        getOptionLabel: (option: ADV.Filter) =>
          option ? `${option.name}` : "Загрузка...",
        ListboxComponent: ListboxComponent,
        renderOption: (props, option, state) =>
          [props, `${option.name}`, state] as React.ReactNode,
      }}
      textFieldProps={{
        helperText: "",
      }}
      options={options ?? []}
    />
  )
}
