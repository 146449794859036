import { FC } from "react"
import { IFormProps } from "@/core/interfaces"
import { RtkHookForm } from "@/core/components"
import { ADV } from "../../interfaces"
import { useUpdateOrderMutation } from "../../store"
import { Grid, Typography } from "@mui/material"
import { DefaultValues } from "react-hook-form-mui"
import { Links } from "../formFields/links"
import { EMPTY_LINK } from "../../constants/adv"

interface IProps extends IFormProps<ADV.CreateOrderRes, ADV.UpdateOrderRes> {
  id: ADV.Order["id"]
}
interface IFormData extends ADV.UpdateOrder {}

export const UpdateOrderBotsForm: FC<IProps> = (props) => {
  const DEFAULT_FORM_VALUES: DefaultValues<IFormData> = {
    links: [{ ...EMPTY_LINK }],
  }

  return (
    <RtkHookForm<IFormData, ADV.UpdateOrderRes, typeof useUpdateOrderMutation>
      mutationHook={useUpdateOrderMutation}
      getSubmitHandlerParams={(body) => ({
        body,
        pathParams: { id: props.id },
      })}
      formContextParams={{
        defaultValues: DEFAULT_FORM_VALUES,
      }}
      actions={[
        {
          text: "Сохранить",
          type: "submit",
          variant: "contained",
          fullWidth: true,
        },
      ]}
    >
      <Grid container spacing={2}>
        <Grid item xs={12} sm={12} lg={4}>
          <Typography variant="body1" pb={2}>
            Ссылки:
          </Typography>
          <Links<IFormData> minLength={1} />
        </Grid>
      </Grid>
    </RtkHookForm>
  )
}
