import { FC, useMemo } from "react"
import { DataGrid } from "@/core/components"
import { GridColDef } from "@mui/x-data-grid-pro"
import { GRID_PAGE_SIZE_OPTIONS } from "@core/constants"
import { useFinishedMailingsQuery, useMailingTypesQuery } from "../../store"
import { TgMailing } from "../../interfaces"

interface IProps {}
export const FinishedMailings: FC<IProps> = (props) => {
  const { isFetching, data } = useFinishedMailingsQuery(
    {},
    { pollingInterval: 30000 },
  )
  const { data: types, isLoading: isTypesLoading } = useMailingTypesQuery({})

  const columns = useMemo<
    GridColDef<Omit<TgMailing.CompletedMailing, "details">>[]
  >(
    () => [
      {
        headerName: "ID",
        field: "id",
        flex: 1,
        filterable: false,
      },
      {
        headerName: "Тип",
        field: "type",
        valueGetter: ({ value }) => value?.id,
        type: "singleSelect",
        valueOptions: types?.map((t) => ({ label: t.name, value: t.id })),
        flex: 2,
      },
      {
        headerName: "Название",
        field: "name",
        flex: 2,
        filterable: false,
      },
      {
        headerName: "Успешно",
        field: "success",
        flex: 2,
        filterable: false,
      },
      {
        headerName: "Ошибка",
        field: "failed",
        flex: 2,
        filterable: false,
      },
      {
        headerName: "Дата создания",
        field: "createdAt",
        flex: 2,
        filterable: false,
      },
      {
        headerName: "Запланирована на",
        field: "scheduledDate",
        flex: 2,
        filterable: false,
      },
      {
        headerName: "Дата заввершения",
        field: "finishedAt",
        flex: 2,
        filterable: false,
      },
    ],
    [types],
  )

  return (
    <DataGrid<Omit<TgMailing.CompletedMailing, "details">>
      columns={columns}
      rows={data ?? []}
      loading={isFetching}
      rowCount={data?.length ?? 0}
      unstable_headerFilters
      pagination
      paginationMode="client"
      pageSizeOptions={GRID_PAGE_SIZE_OPTIONS}
      filterMode="client"
      sortingMode="client"
    />
  )
}
