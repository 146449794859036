import React from "react"
import "@/index.css"
import { createRoot } from "react-dom/client"
import { Provider } from "react-redux"
import App from "@/App"
import { SnackbarProvider } from "notistack"
import { store } from "@core/store"

const container = document.getElementById("root")!
const root = createRoot(container)

root.render(
  <React.StrictMode>
    <Provider store={store}>
      <SnackbarProvider maxSnack={3}>
        <App />
      </SnackbarProvider>
    </Provider>
  </React.StrictMode>,
)
