import { PayloadAction, createSlice } from "@reduxjs/toolkit"
import { RootState, StateFromRoot, registerReducer, store } from "@core/store"
import { CSVRow } from "../components/mailing/csv/csvForm"
import { TgMailing } from "../interfaces"

const STATE_KEY = "telegram"
export const CONFIG_KEY = `${STATE_KEY}_state`

interface IState {
  mailingFilters: TgMailing.MailingFilters
}
// type StateFromRoot = { [STATE_KEY]: IState }

// const persistantState = localStorage.getItem(CONFIG_KEY)
const emptyState: IState = {
  mailingFilters: {},
}

const initialState: IState = emptyState

export const telegramSlice = createSlice({
  name: "telegram",
  initialState: initialState,
  reducers: {
    setMailingFilters: (
      state,
      { payload: filters }: PayloadAction<TgMailing.MailingFilters>,
    ) => {
      state.mailingFilters = filters
    },
  },
})
registerReducer(telegramSlice.name, telegramSlice.reducer)
store.subscribe(() => {
  localStorage.setItem(CONFIG_KEY, JSON.stringify(store.getState().telegram))
})

export const { setMailingFilters } = telegramSlice.actions

export const getTelegramState = (state: RootState<StateFromRoot<IState>>) =>
  state.telegram
