import { FC, useEffect, useMemo } from "react"
import { DataGrid } from "@core/components"
import {
  GridColDef,
  GridColumnsPanel,
  GridLogicOperator,
  gridClasses,
  useGridApiRef,
} from "@mui/x-data-grid-pro"
import { Liga } from "../../interfaces"
import {
  useCreateProductPaymentMutation,
  useDeleteProductPaymentMutation,
  useGetProductPaymentsQuery,
  useUpdateProductPaymentMutation,
} from "../../store"

const FILTER_DEFAULT_VALUE = 8
interface IProps {}

export const ProductPaymentCategorByProduct: FC<IProps> = (props) => {
  const { data, isLoading } = useGetProductPaymentsQuery({})
  const [update, { isLoading: isUpdating }] = useUpdateProductPaymentMutation()
  const [create, { isLoading: isCreating }] = useCreateProductPaymentMutation()
  const [deleteProduct, { isLoading: isDeleting }] =
    useDeleteProductPaymentMutation()
  const apiRef = useGridApiRef()

  const groupedData = useMemo(() => {
    const grouped =
      data?.reduce<Liga.ProductPaymentsGroupedByMeasure>((prev, cur) => {
        prev[cur.measure] = {
          ...prev[cur.measure],
          id: cur.measure,
          measure: cur.measure,
          [cur.payment_category_id.toString()]: cur.payment,
        }
        return prev
      }, {}) ?? {}
    return [...Object.values(grouped)]
  }, [data])

  const categoriesColumns = useMemo(
    () => [
      ...Object.values(
        data?.reduce<
          Record<
            number,
            GridColDef<Liga.ProductPaymentsGroupedByMeasure[number]>
          >
        >((prev, cur) => {
          prev[cur.payment_category_id] = {
            headerName: cur.payment_category?.name,
            field: cur.payment_category_id.toString(),
            valueGetter: (params) => {
              return params.value
            },
            type: "number",
            // filterable: false,
            editable: true,
            filterOperators: [
              {
                value: "is",
                getApplyFilterFn: (item, col) => {
                  return (params) => {
                    const columnVisibilityModel =
                      apiRef?.current.state?.columns.columnVisibilityModel
                    const columnIsVisible =
                      columnVisibilityModel[col.field] !== false
                    return !!params.value && columnIsVisible
                  }
                },
              },
            ],
            flex: 2,
          }
          return prev
        }, {}) ?? {},
      ),
    ],
    [data],
  )
  useEffect(() => {
    apiRef.current.setFilterModel({
      items: [
        ...categoriesColumns.map((c) => {
          return {
            id: c.field,
            operator: "is",
            field: c.field.toString(),
            value: undefined,
          }
        }),
      ],
      logicOperator: GridLogicOperator.Or,
    })
    apiRef.current.setSortModel([{ field: "measure", sort: "asc" }])
  }, [categoriesColumns])

  useEffect(() => {
    apiRef.current.subscribeEvent(
      "columnVisibilityModelChange",
      (params, event, details) => {
        apiRef.current.unstable_applyFilters()
      },
    )
  }, [])

  const columns: GridColDef<Liga.ProductPaymentsGroupedByMeasure[number]>[] = [
    {
      headerName: "Фасовка",
      field: "measure",
      valueGetter: ({ value }) => value,
      type: "number",
      filterable: false,
      hideable: false,
      flex: 2,
    },
    ...categoriesColumns,
  ]

  const updateProductPayment = (
    newRow: Liga.ProductPaymentsGroupedByMeasure[number],
    oldRow: Liga.ProductPaymentsGroupedByMeasure[number],
  ) => {
    const differentValues: (Pick<
      Liga.ProductPayment,
      "payment_category_id" | "measure" | "payment"
    > & { isNew: boolean })[] = []
    for (const key in newRow) {
      if (newRow[key] !== oldRow[key]) {
        differentValues.push({
          payment: newRow[key],
          payment_category_id: +key,
          measure: newRow.measure,
          isNew: oldRow[key] === undefined,
        })
      }
    }

    for (const upd of differentValues) {
      if (upd.isNew) {
        create({
          pathParams: {
            measure: upd.measure,
            payment_category_id: upd.payment_category_id,
          },
          body: {
            payment: upd.payment,
          },
        })
      } else if (upd.payment === null) {
        deleteProduct({
          pathParams: {
            measure: upd.measure,
            payment_category_id: upd.payment_category_id,
          },
        })
      } else {
        update({
          pathParams: {
            measure: upd.measure,
            payment_category_id: upd.payment_category_id,
          },
          body: {
            payment: upd.payment,
          },
        })
      }
    }
    return newRow
  }

  return (
    <DataGrid<Liga.ProductPaymentsGroupedByMeasure[number]>
      apiRef={apiRef}
      columns={isLoading ? [] : columns}
      rows={groupedData ?? []}
      loading={isLoading}
      paginationMode="client"
      editMode="cell"
      processRowUpdate={updateProductPayment}
      // unstable_headerFilters
      disableColumnFilter
      columnHeaderHeight={205}
      sx={(theme) => ({
        [`& .${gridClasses.columnHeaderDraggableContainer}`]: {
          height: "100%",
          alignItems: "end",
        },
        [`& .${gridClasses.columnHeaderTitleContainer}`]: {
          height: "100%",
          alignItems: "end",
        },
        [`& .${gridClasses.columnHeaderTitleContainerContent}`]: {
          height: "100%",
          width: "100%",
          alignItems: "end",
          overflow: "visible",
          justifyContent: "end",
          paddingBottom: 1,
        },
        [`& .${gridClasses.columnHeaderTitle}`]: {
          writingMode: "vertical-rl",
          overflow: "visible",
          wordWrap: "break-word",
          lineHeight: "1rem",
        },
        [`& .${gridClasses.columnsPanel}`]: {
          display: "flex",
          flexWrap: "wrap",
        },
      })}
      slots={{
        toolbar: GridColumnsPanel,
      }}
      initialState={{
        columns: {
          columnVisibilityModel: {
            [FILTER_DEFAULT_VALUE.toString()]: false,
          },
        },
      }}
    />
  )
}
