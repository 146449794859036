import { FC } from "react"
import { DataGrid } from "@core/components"
import { GridColDef, getGridStringOperators } from "@mui/x-data-grid-pro"
import { Liga } from "../interfaces"
import {
  useGetLocationsQuery,
  useGetMarketplaceCitiesQuery,
  useUpdateMarketplaceCitiesMutation,
} from "../store"
import { GridCellEditingWrapper } from "@/core/components/dataGrid/gridCellEditingWrapper"
import { LocationSelect } from "."
import {
  isEqualObjects,
  stringOrEmptySortingComparator,
  wrapFilterOperator,
} from "@/core/utils"

interface IProps {}

export const MarketplaceCitiesTable: FC<IProps> = (props) => {
  const { data, isFetching } = useGetMarketplaceCitiesQuery({})
  const { data: locations } = useGetLocationsQuery({
    queryParams: { type: "Город" },
  })

  const [update, { isLoading: isUpdating }] =
    useUpdateMarketplaceCitiesMutation()

  const columns: GridColDef<Liga.MarketplaceCity>[] = [
    {
      headerName: "Площадка",
      field: "marketplace",
      valueFormatter: (params) => params.value ?? "",
      valueGetter: (params) => params.row.shop?.marketplace.name,
      sortComparator: (v1, v2) => stringOrEmptySortingComparator(v1, v2),
      flex: 2,
    },
    {
      headerName: "Шоп",
      field: "shop",
      valueGetter: ({ value }) => value?.name,
      sortComparator: (v1, v2) => stringOrEmptySortingComparator(v1, v2),
      flex: 2,
    },
    {
      headerName: "Город",
      field: "name",
      flex: 2,
    },
    {
      headerName: "Сопоставленный город",
      field: "liga_city_id",
      editable: true,
      renderEditCell: (params) => (
        <GridCellEditingWrapper<typeof LocationSelect>
          id={params.id}
          field={params.field}
          component={LocationSelect}
          componentProps={{
            defaultValue: params.value?.id ?? null,
            type: "Город",
          }}
        />
      ),
      filterOperators: getGridStringOperators().map((f) =>
        wrapFilterOperator<Liga.MarketplaceCity>(
          f,
          (applyFilterFn, params) =>
            applyFilterFn({ ...params, value: params.value?.name }),
          (applyFilterFnV7, value, row, col, apiRef) =>
            applyFilterFnV7(value?.name, row, col, apiRef),
        ),
      ),
      valueFormatter: (params) => {
        return params.value?.name
      },
      valueGetter: (params) => {
        return params.row?.location
      },
      valueParser(value, params) {
        return typeof value === "string"
          ? value
          : locations?.find((l) => l.id === value)
      },
      valueSetter(params) {
        return {
          ...params.row,
          liga_city_id: params.value?.id ?? null,
          location: params.value,
        }
      },
      sortComparator: (v1, v2) =>
        stringOrEmptySortingComparator(v1?.name, v2?.name),

      flex: 2,
    },
  ]

  const updateMarketplaceCity = (
    upd: Liga.MarketplaceCity,
    old: Liga.MarketplaceCity,
  ) => {
    if (isEqualObjects(upd, old)) {
      return old
    }
    update({
      pathParams: {
        id: upd.id,
      },
      body: {
        liga_city_id: upd.liga_city_id,
      },
    })
    return upd
  }

  return (
    <DataGrid<Liga.MarketplaceCity>
      columns={columns}
      rows={data ?? []}
      loading={isFetching || isUpdating}
      paginationMode="client"
      editMode="cell"
      processRowUpdate={updateMarketplaceCity}
      unstable_headerFilters
      disableColumnFilter
      initialState={{
        sorting: {
          sortModel: [{ field: "liga_city_id", sort: "asc" }],
        },
      }}
    />
  )
}
