import { Stack, Button } from "@mui/material"
import { FieldValues, useFormContext } from "react-hook-form-mui"
import { Type } from "./typeSelect"
import { IOrderFormData } from "../forms/createOrderForm"
import { TransactionHash } from "./hash"
import { OrderEndDate } from "./orderEndDate"
import { OrderStartDate } from "./orderStartDate"
import { Status } from "./statusSelect"
import { SumToken } from "./sumToken"
import { Payment } from "./payment"
import { Comment } from "./comment"

interface IProps<T extends FieldValues> {}
interface IField {
  payment: IOrderFormData["payment"]
}

export function PaymentFields<T extends FieldValues = IField>(
  props: IProps<T>,
): JSX.Element {
  const ctx = useFormContext<IOrderFormData>()
  const payment = ctx?.watch("payment")

  return (
    <Stack spacing={4}>
      {!!payment && (
        <Stack spacing={2}>
          <OrderStartDate<IOrderFormData> required />
          <OrderEndDate<IOrderFormData> />
          <Status<IOrderFormData> for={"orders"} required />
          <Payment<IOrderFormData> required />
          <Type<IOrderFormData>
            name={"payment.type_id"}
            for={"payments"}
            label="Монета"
            required
          />
          <TransactionHash<IOrderFormData> name={"payment.hash"} required />
          <SumToken<IOrderFormData> name={"payment.sum_token"} required />
          <Comment<IOrderFormData> name={"payment.comment"} />
        </Stack>
      )}
      {payment ? (
        <Button onClick={() => ctx.setValue("payment", undefined as any)}>
          Убрать оплату
        </Button>
      ) : (
        <Button onClick={() => ctx.resetField("payment")}>
          Добавить оплату
        </Button>
      )}
    </Stack>
  )
}
