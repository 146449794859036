import { Grid, Link, List, ListItem, Typography } from "@mui/material"
import { FC } from "react"
import { useGetUsefulLinksQuery } from "../store"
import { ArrowOutward } from "@mui/icons-material"

interface IProps {}
export const UsefulLinks: FC<IProps> = (props) => {
  const { data } = useGetUsefulLinksQuery({})
  const categories = Object.keys(data ?? {})

  return (
    <Grid container spacing={2}>
      {categories.map((category) => (
        <Grid
          item
          xs={12}
          sm={Math.max(12 / categories.length, 6)}
          md={Math.max(12 / categories.length, 4)}
          lg={Math.max(12 / categories.length, 3)}
          key={category}
        >
          <List subheader={`${category}:`}>
            {data?.[category]
              .toSorted((l, r) => r.order - l.order)
              .map((ul) => (
                <ListItem key={ul.id}>
                  <Link
                    href={ul.url}
                    target="_blank"
                    rel="noreferrer"
                    sx={{
                      svg: {
                        transition: "ease-out .2s",
                      },
                      ":hover": {
                        svg: {
                          transform: "translateX(2px)",
                        },
                      },
                    }}
                  >
                    <Typography
                      sx={{
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      {ul.label}&nbsp;
                      <ArrowOutward fontSize="small" />
                    </Typography>
                  </Link>
                </ListItem>
              ))}
          </List>
        </Grid>
      ))}
    </Grid>
  )
}
