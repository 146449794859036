import { FC, useMemo } from "react"
import { DataGrid } from "@/core/components"
import { GridColDef } from "@mui/x-data-grid-pro"
import { GRID_PAGE_SIZE_OPTIONS } from "@core/constants"
import { useActiveMailingsQuery } from "../../store"
import { TgMailing } from "../../interfaces"

interface IProps {}
export const ActiveMailings: FC<IProps> = (props) => {
  const { isFetching, data } = useActiveMailingsQuery(
    {},
    { pollingInterval: 30000 },
  )

  const columns = useMemo<GridColDef<TgMailing.ActiveMailing>[]>(
    () => [
      {
        headerName: "ID",
        field: "id",
        flex: 1,
      },
      {
        headerName: "Название",
        field: "name",
        flex: 2,
      },
      {
        headerName: "В очереди",
        field: "pending",
        flex: 2,
      },
      {
        headerName: "Выполнено",
        field: "completed",
        flex: 2,
      },
      {
        headerName: "Ошибка",
        field: "failed",
        flex: 2,
      },
      {
        headerName: "Дата создания",
        field: "createdAt",
        flex: 2,
      },
      {
        headerName: "Запланирована на",
        field: "scheduledDate",
        flex: 2,
      },
    ],
    [],
  )

  return (
    <DataGrid<TgMailing.ActiveMailing>
      columns={columns}
      rows={data ?? []}
      loading={isFetching}
      rowCount={data?.length ?? 0}
      //   unstable_headerFilters
      pagination
      paginationMode="client"
      pageSizeOptions={GRID_PAGE_SIZE_OPTIONS}
      filterMode="client"
      sortingMode="client"
    />
  )
}
