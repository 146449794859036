import { GridRowId, useGridApiContext } from "@mui/x-data-grid-pro"
import React from "react"

interface IProps<P extends React.ElementType> {
  id: GridRowId
  field: string
  component: React.ElementType
  componentProps?: Omit<React.ComponentProps<P>, "onChange">
}
export const GridCellEditingWrapper = <P extends React.ElementType>(
  props: IProps<P>,
) => {
  const Children = props.component
  const gridApiRef = useGridApiContext()

  const onChange = (value: any) => {
    gridApiRef.current.setEditCellValue({
      id: props.id,
      field: props.field,
      value,
    })
  }
  return (
    <>
      <Children onChange={onChange} {...props.componentProps} />
    </>
  )
}
