import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react"
import { App } from "@app/interfaces"
import {
  RootState,
  StateFromRoot,
  dynamicMiddlewaresInstance,
  registerReducer,
} from "@/core/store"
import { AuthState } from "."

export const baseAuthApiURL = new URL(
  import.meta.env.REACT_APP_TGSTAT_API,
  window.location.origin,
).toString()
export const authApi = createApi({
  reducerPath: "authApi",
  baseQuery: fetchBaseQuery({
    baseUrl: `${
      baseAuthApiURL.endsWith("/") ? baseAuthApiURL : baseAuthApiURL + "/"
    }auth`,
    prepareHeaders: (headers, { getState }) => {
      // By default, if we have a token in the store, let's use that for authenticated requests
      const token = (getState() as RootState<StateFromRoot<AuthState>>).auth
        .token
      if (token) {
        headers.set("authorization", `Bearer ${token}`)
      }
      return headers
    },
  }),
  tagTypes: ["User"],
  endpoints: (build) => ({
    auth: build.mutation<App.UserAuthResult, App.UserAuthRequest>({
      query: (q) => ({
        url: `login`,
        method: "POST",
        body: q,
      }),
      invalidatesTags: ["User"],
    }),
  }),
})
dynamicMiddlewaresInstance.addMiddleware(authApi.middleware)
registerReducer(authApi.reducerPath, authApi.reducer)

export const { useAuthMutation } = authApi
