import {
  GridFilterOperator,
  GridCellParams,
  GetApplyFilterFnV7,
  GetApplyFilterFnLegacy,
  GridValidRowModel,
} from "@mui/x-data-grid-pro"

export const stringOrEmptySortingComparator = (
  v1: string | undefined | null,
  v2: string | undefined | null,
): number => {
  return v1 && v2 ? v1.localeCompare(v2) : v1 ? -1 : v2 ? 1 : 0
}

type ApplyFilterFn<
  R extends GridValidRowModel = any,
  V = any,
  F = V,
> = NonNullable<ReturnType<GetApplyFilterFnLegacy<R, V, F>>>
type ApplyFilterFnV7<
  R extends GridValidRowModel = any,
  V = any,
  F = V,
> = NonNullable<ReturnType<GetApplyFilterFnV7<R, V, F>>>
export const wrapFilterOperator = <
  R extends GridValidRowModel = any,
  V = any,
  F = V,
>(
  operator: GridFilterOperator<R, V, F>,
  filterFnWrapper: (
    applyFilterFn: ApplyFilterFn<R, V, F>,
    params: Parameters<ApplyFilterFn<R, V, F>>[0],
  ) => ReturnType<ApplyFilterFn>,
  filterFnV7Wrapper?: (
    applyFilterFnV7: ApplyFilterFnV7<R, V, F>,
    ...params: Parameters<ApplyFilterFnV7<R, V, F>>
  ) => ReturnType<ApplyFilterFnV7>,
): GridFilterOperator => {
  const getApplyFilterFn: GridFilterOperator<R, V, F>["getApplyFilterFn"] = (
    filterItem,
    column,
  ) => {
    const innerFilterFn = operator.getApplyFilterFn(filterItem, column)
    if (!innerFilterFn) {
      return innerFilterFn
    }

    return (params: GridCellParams<R, V, F>) => {
      return filterFnWrapper(innerFilterFn, params)
    }
  }

  let getApplyFilterFnV7: GridFilterOperator<R, V, F>["getApplyFilterFnV7"] =
    operator.getApplyFilterFnV7
  if (filterFnV7Wrapper) {
    const innerGetApplyFilterFnV7 = getApplyFilterFnV7
    if (innerGetApplyFilterFnV7) {
      getApplyFilterFnV7 = (filterItem, column) => {
        const innerFilterFnV7 = innerGetApplyFilterFnV7(filterItem, column)
        if (!innerFilterFnV7) {
          return innerFilterFnV7
        }
        return (value, row, column, apiRef) => {
          return filterFnV7Wrapper(innerFilterFnV7, value, row, column, apiRef)
        }
      }
    }
  }

  return {
    ...operator,
    getApplyFilterFn,
    getApplyFilterFnV7,
  }
}
