import { ArrowBack } from "@mui/icons-material"
import { Box, BoxProps, Button, Typography } from "@mui/material"
import { forwardRef } from "react"
import { Link, LinkProps, RelativeRoutingType } from "react-router-dom"
import { useLocation } from "../hooks"
import { StateWithPrev } from "../interfaces"

export interface PreviousLocation {
  from?: string
  title?: string
}
interface IProps {
  containerProps?: BoxProps
  relative?: RelativeRoutingType
}
interface ButtonLinkProps extends Omit<LinkProps, "to"> {
  href: string
}
export const GoBack = ({ containerProps, relative }: IProps) => {
  const { state } = useLocation<StateWithPrev<PreviousLocation>>()
  return (
    <Box pb={2} {...containerProps}>
      <Button
        href={state?.from ?? ".."}
        sx={{
          svg: {
            transition: "ease-out .2s",
          },
          ":hover": {
            svg: {
              transform: "translateX(-4px)",
            },
          },
        }}
        LinkComponent={forwardRef<HTMLAnchorElement, ButtonLinkProps>(
          (props, ref) => (
            <Link
              to={props.href}
              relative={relative ?? "path"}
              ref={ref}
              state={{ ...state?.prev }}
              {...props}
            />
          ),
        )}
      >
        <Typography
          component={"span"}
          sx={{
            display: "flex",
            alignItems: "center",
          }}
        >
          <ArrowBack fontSize="small" />
          &nbsp;{state?.title ?? "Назад"}
        </Typography>
      </Button>
    </Box>
  )
}
