import { FC } from "react"
import { DataGrid } from "@core/components"
import { GridColDef, getGridStringOperators } from "@mui/x-data-grid-pro"
import { Liga } from "../interfaces"
import {
  useGetAccountintCategoriesQuery,
  useGetPaymentCategoriesQuery,
  useGetProductsQuery,
  useUpdateMarketplaceProductMutation,
  useUpdateProductCategoryMutation,
} from "../store"
import { GridCellEditingWrapper } from "@/core/components/dataGrid/gridCellEditingWrapper"
import { ProductSelect } from "."
import {
  isEqualObjects,
  stringOrEmptySortingComparator,
  wrapFilterOperator,
} from "@/core/utils"
import { AccountingCategorySelect } from "./accountingCategorySelect"
import { PaymnetCategoriesSelect } from "./paymentCategorySelect"

interface IProps {}

export const ProductCategoriesTable: FC<IProps> = (props) => {
  const { data, isFetching } = useGetProductsQuery({})
  const { data: accountingCategories } = useGetAccountintCategoriesQuery({})
  const { data: paymentCategories } = useGetPaymentCategoriesQuery({})

  const [update, { isLoading: isUpdating }] = useUpdateProductCategoryMutation()

  const columns: GridColDef<Liga.Product>[] = [
    {
      headerName: "ID",
      field: "id",
      flex: 1,
    },
    { headerName: "Товар", field: "name", flex: 2 },
    {
      headerName: "Категория",
      field: "category_id",
      filterOperators: getGridStringOperators().map((f) =>
        wrapFilterOperator<Liga.Product>(
          f,
          (applyFilterFn, params) =>
            applyFilterFn({ ...params, value: params.value?.name }),
          (applyFilterFnV7, value, row, col, apiRef) =>
            applyFilterFnV7(value?.name, row, col, apiRef),
        ),
      ),
      valueGetter: (params) => params.row.category?.name,
      sortComparator: (v1, v2) => stringOrEmptySortingComparator(v1, v2),
      flex: 2,
    },
    {
      headerName: "Сопоставленная категория",
      field: "accounting_category_id",
      editable: true,
      filterOperators: getGridStringOperators().map((f) =>
        wrapFilterOperator<Liga.Product>(
          f,
          (applyFilterFn, params) =>
            applyFilterFn({ ...params, value: params.value?.name }),
          (applyFilterFnV7, value, row, col, apiRef) =>
            applyFilterFnV7(value?.name, row, col, apiRef),
        ),
      ),
      renderEditCell: (params) => {
        return (
          <GridCellEditingWrapper<typeof AccountingCategorySelect>
            id={params.id}
            field={params.field}
            component={AccountingCategorySelect}
            componentProps={{
              defaultValue: params.row.accounting_category_id ?? null,
            }}
          />
        )
      },
      valueFormatter: (params) => params.value?.name ?? "",
      valueGetter: (params) => {
        return params.row?.accounting_category
      },
      valueParser(value, params) {
        return accountingCategories?.find((c) => c.id === value?.id)
      },
      valueSetter(params) {
        return {
          ...params.row,
          accounting_category_id: params.value?.id ?? null,
          accounting_category: params.value,
        }
      },
      sortComparator: (v1, v2) => stringOrEmptySortingComparator(v1, v2),
      flex: 2,
    },
    {
      headerName: "Категория оплаты",
      field: "payment_category_id",
      editable: true,
      filterOperators: getGridStringOperators().map((f) =>
        wrapFilterOperator<Liga.Product>(
          f,
          (applyFilterFn, params) =>
            applyFilterFn({ ...params, value: params.value?.name }),
          (applyFilterFnV7, value, row, col, apiRef) =>
            applyFilterFnV7(value?.name, row, col, apiRef),
        ),
      ),
      renderEditCell: (params) => (
        <GridCellEditingWrapper<typeof PaymnetCategoriesSelect>
          id={params.id}
          field={params.field}
          component={PaymnetCategoriesSelect}
          componentProps={{
            defaultValue: params.row.payment_category_id ?? null,
          }}
        />
      ),
      valueFormatter: (params) => params.value?.name ?? "",
      valueGetter: (params) => {
        return params.row?.payment_category
      },
      valueParser(value, params) {
        return paymentCategories?.find((c) => c.id === value?.id)
      },
      valueSetter(params) {
        return {
          ...params.row,
          payment_category_id: params.value?.id ?? null,
          payment_category: params.value,
        }
      },
      sortComparator: (v1, v2) => stringOrEmptySortingComparator(v1, v2),
      flex: 2,
    },
  ]

  const updateProduct = (upd: Liga.Product, old: Liga.Product) => {
    if (isEqualObjects(upd, old)) {
      return old
    }
    update({
      pathParams: {
        id: upd.id,
      },
      body: {
        accounting_category_id: upd.accounting_category_id,
        payment_category_id: upd.payment_category_id,
      },
    })
    return upd
  }

  return (
    <DataGrid<Liga.Product>
      columns={columns}
      rows={data ?? []}
      loading={isFetching || isUpdating}
      paginationMode="client"
      editMode="cell"
      processRowUpdate={updateProduct}
      unstable_headerFilters
      disableColumnFilter
      initialState={{
        sorting: {
          sortModel: [{ field: "category_id", sort: "asc" }],
        },
      }}
    />
  )
}
