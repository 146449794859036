import { FC, useEffect } from "react";
import { Typography } from "@mui/material";
import { setToken, useAuthQuery } from "../store";
import { CHECK_AUTH_INTERVAL } from "../constants";
import { useAppDispatch } from "@/modules/app/hooks";

interface IProps {
  loginKey: string;
}

export const Auth: FC<IProps> = ({loginKey}) => {
  const dispatch = useAppDispatch();
  const {
    data: token,
    isError: isAuthError,
  } = useAuthQuery(
    {pathParams: {loginKey}}, 
    {pollingInterval: CHECK_AUTH_INTERVAL}
  );

  useEffect(() => {
    if (token) {
      dispatch(setToken(token));
    }
  }, [token]);

  if (isAuthError) {
    <Typography color='error'>При авторизации произошла ошибка!</Typography>
  }
  
  return <>
    <Typography color='info'>Ждём авторизацию...</Typography>
  </>

}
