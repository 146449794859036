import { FC, useMemo } from "react"
import { DataGrid, GridCellEditingWrapper } from "@core/components"
import { GridColDef } from "@mui/x-data-grid-pro"
import { Liga } from "../interfaces"
import { useGetCouriersQuery, useUpdateStaffMutation } from "../store"
import { LocationSelect } from "."
import equal from "fast-deep-equal"

interface IProps {}

export const QuestionnaireTable: FC<IProps> = (props) => {
  const { data, isFetching } = useGetCouriersQuery({
    queryParams: { active: true },
  })
  const [update, { isLoading: isUpdating }] = useUpdateStaffMutation()

  const columns = useMemo<GridColDef<Liga.Courier>[]>(
    () => [
      {
        headerName: "ID",
        field: "id",
        flex: 1,
      },
      {
        headerName: "Юзернейм",
        field: "name",
        flex: 1,
      },
      {
        headerName: "Активен",
        field: "active",
        type: "boolean",
        flex: 1,
      },
      {
        headerName: "Является сотрудником склада",
        field: "is_warehouse_staff",
        type: "boolean",
        flex: 1,
      },
      {
        headerName: "Характеристика",
        field: "characteristic",
        type: "string",
        editable: true,
        flex: 2,
      },
      {
        headerName: "Контакт",
        field: "questionnaire",
        type: "string",
        editable: true,
        flex: 1,
      },
      {
        headerName: "Город",
        field: "city",
        editable: true,
        type: "string",
        renderEditCell: (params) => (
          <GridCellEditingWrapper<typeof LocationSelect>
            id={params.id}
            field={params.field}
            component={LocationSelect}
            componentProps={{
              defaultValue: params.value ?? null,
              type: "Город",
              freeSolo: true,
            }}
          />
        ),
        flex: 2,
      },
      {
        headerName: "Район",
        field: "district",
        editable: true,
        type: "string",
        renderEditCell: (params) => (
          <GridCellEditingWrapper<typeof LocationSelect>
            id={params.id}
            field={params.field}
            component={LocationSelect}
            componentProps={{
              defaultValue: params.value ?? null,
              type: "Район",
              freeSolo: true,
              filter: (value: Liga.Location) =>
                value.parent?.name === params.row.city,
            }}
          />
        ),
        flex: 2,
      },
    ],
    [data],
  )
  const updateCourier = (upd: Liga.Courier, old: Liga.Courier) => {
    if (equal(upd, old)) {
      return old
    }

    update({
      pathParams: {
        id: upd.id,
      },
      body: {
        city: upd.city || null,
        district: upd.district || null,
        questionnaire: upd.questionnaire || null,
        characteristic: upd.characteristic || null,
        contact: upd.contact || null,
      },
    })
    return upd
  }

  return (
    <DataGrid<Liga.Courier>
      columns={columns}
      rows={data ?? []}
      loading={isFetching || isUpdating}
      paginationMode="client"
      editMode="cell"
      processRowUpdate={updateCourier}
      unstable_headerFilters
      // canEdit
      // onInternalEditSave={(params) => updateCourier(params.row)}
      // disableColumnFilter
      initialState={{
        sorting: {
          sortModel: [
            { field: "name", sort: "asc" },
            { field: "active", sort: "asc" },
          ],
        },
      }}
    />
  )
}
