import { ADV } from "../../interfaces"
import { AutocompleteElement, FieldValues, Path } from "react-hook-form-mui"
import { ListboxComponent } from "@core/components"
import { useLocationsListQuery } from "../../store"

interface IProps<T extends FieldValues> {
  name: Path<T>
  required?: boolean
  multiple?: boolean
}

type IField = {
  location_id?: ADV.Geolocation["id"]
}

export const Location = <T extends FieldValues = IField>(
  props: IProps<T>,
): JSX.Element => {
  const { required, name, multiple } = props
  const { data, isFetching: isLoading } = useLocationsListQuery({})

  return (
    <AutocompleteElement<T>
      name={name}
      label="Геолокация"
      loading={isLoading}
      matchId
      multiple={multiple}
      autocompleteProps={{
        fullWidth: true,
        getOptionLabel: (option: ADV.Geolocation) => option.name,
        ListboxComponent: ListboxComponent,
        renderOption: (props, option, state) =>
          [props, option?.name, state] as React.ReactNode,
      }}
      required={required}
      options={data ?? []}
    />
  )
}
