export const str2ab = (str: string): ArrayBuffer => {
  const buf = new ArrayBuffer(str.length)
  const bufView = new Uint8Array(buf)
  for (let i = 0, strLen = str.length; i < strLen; i++) {
    bufView[i] = str.charCodeAt(i)
  }
  return buf
}

export const ab2str = (ab: ArrayBuffer): string => {
  return String.fromCharCode.apply(null, Array.from(new Uint8Array(ab)))
}

export const getRandomString = (length: number = 12): string =>
  [...crypto.getRandomValues(new Uint8Array(length))]
    .map(
      (x, i) => (
        (i = ((x / 255) * 61) | 0),
        String.fromCharCode(i + (i > 9 ? (i > 35 ? 61 : 55) : 48))
      ),
    )
    .join("")
