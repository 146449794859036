import { CircularProgress, Stack } from "@mui/material"
import { useBalancesListQuery } from "../store"
import { FieldView } from "@/core/components"

interface IProps {}
export const Balances = (props: IProps) => {
  const { data, isLoading } = useBalancesListQuery({})

  if (isLoading) {
    return <CircularProgress />
  }

  return (
    <Stack direction="row" spacing={3}>
      {data?.map((b) => (
        <FieldView label={b.type_name} value={b.sum} key={b.id} />
      ))}
    </Stack>
  )
}
