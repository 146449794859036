import { FC, useEffect, useState } from "react"
import { FormContainer, useForm } from "react-hook-form-mui"
import { IFormProps } from "@/core/interfaces"
import {
  Box,
  Button,
  CircularProgress,
  Divider,
  Grid,
  Stack,
} from "@mui/material"
import { SnackbarAlert } from "@/core/components"
import { TgMailing } from "../../../interfaces/mailing"
import { useCreateMailingMutation } from "../../../store"
import { MethodSelectField } from "../methodSelectField"
import { CSVField } from "./csvField"
import { CSVPreview } from "./csvPreview"
import { CsvColsToBodyFieldsCollation } from "./csvColsToBodyFieldCollation"
import { BodyFieldsPropperties } from "../../../constants"
import { CsvStepper } from "./csvStepper"
import { Name } from "../name"
import { ScheduleDate } from "../scheduleDate"
import { Comment } from "../comment"
import { useMailingFieldsParameters } from "@/modules/telegram/hooks/useMailingFieldsParameters"
import { Type } from "../type"

export type CSVRow = Record<string, string>
interface IProps extends IFormProps<TgMailing.CreateMailingRes> {}
export type IMailingFormData<
  M extends TgMailing.AvailableMethods = TgMailing.AvailableMethods,
> = Omit<TgMailing.CreateMailingData<M>, "body" | "token" | "messages"> & {
  collation: Record<keyof (typeof BodyFieldsPropperties)[M], string>
  rows: CSVRow[]
  method: M
}

export const MailingCsvForm: FC<IProps> = (props) => {
  const [createMailing, { isLoading, isError, isSuccess, error }] =
    useCreateMailingMutation()

  const [isSnackbarOpen, setSnackbarOpen] = useState(isError)
  useEffect(() => {
    setSnackbarOpen(isError || isSuccess)
  }, [isError, isSuccess])

  const formContext = useForm<IMailingFormData>({
    defaultValues: {
      method: "sendMessage",
    },
  })
  const method = formContext.watch("method")
  const fieldsCollation = formContext.watch("collation")
  const { token, ...bodyFieldsProppertiesWithoutToken } =
    useMailingFieldsParameters(method)
  const fields = Object.entries(
    bodyFieldsProppertiesWithoutToken,
  ) as unknown as [
    keyof (typeof BodyFieldsPropperties)[typeof method],
    (typeof BodyFieldsPropperties)[typeof method][keyof (typeof BodyFieldsPropperties)[typeof method]],
  ][]

  const getBodyValues = ({
    method,
    collation,
    rows,
    ...data
  }: IMailingFormData): TgMailing.CreateMailingReq["body"] => ({
    messages: rows.map((data) => ({
      method: method,
      token: data[fieldsCollation["token"]],
      body: fields.reduce((prev, [field, parameters]) => {
        if (
          !parameters.hidden &&
          (data[fieldsCollation[field]] === null ||
            data[fieldsCollation[field]] === undefined)
        ) {
          return prev
        }

        const newField = parameters.hidden
          ? parameters.default
          : typeof parameters.parseValue == "function"
            ? parameters.parseValue(data[fieldsCollation[field]])
            : data[fieldsCollation[field]]

        return {
          ...prev,
          [field]: newField,
        }
      }, {}),
    })),
    ...data,
  })

  const onSubmit = (data: IMailingFormData) => {
    createMailing({
      body: getBodyValues(data),
    })
      .unwrap()
      .then(() => {
        formContext.reset()
      })
  }

  return (
    <FormContainer<IMailingFormData>
      onSuccess={onSubmit}
      formContext={formContext}
    >
      <SnackbarAlert
        isOpen={isSnackbarOpen}
        onClose={() => setSnackbarOpen(false)}
        variant={isError ? "error" : "success"}
        title={isError ? "Произошла ошибка" : "Успешно"}
        message={isError ? JSON.stringify(error) : "Рассылка создана"}
      />
      <Grid container spacing={1}>
        <Grid item xs={12} mb={4} mt={1}>
          <CsvStepper />
        </Grid>
        <Grid item xs={12} md={6}>
          <Stack spacing={2}>
            <Name<IMailingFormData> required />
            <ScheduleDate<IMailingFormData> />
            <Comment<IMailingFormData> />
            <Type<IMailingFormData> />
            <CSVField />
          </Stack>
        </Grid>
        <Grid item xs={12} md={6}>
          <Stack spacing={2}>
            <MethodSelectField<IMailingFormData> />
            <CsvColsToBodyFieldsCollation />
          </Stack>
        </Grid>
        <Grid item xs={12} mt={3}>
          <Box maxHeight={400} height={"100%"}>
            <CSVPreview />
          </Box>
        </Grid>
      </Grid>
      <Divider light sx={{ marginY: 2 }} />
      <Box display="flex" justifyContent="end">
        <Button disabled={isLoading} type="submit" variant="contained">
          Отправить&nbsp;{isLoading && <CircularProgress size={20} />}
        </Button>
      </Box>
    </FormContainer>
  )
}
