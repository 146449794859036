import { FC } from "react"
import { RtkHookForm } from "@/core/components"
import { ADV } from "../../interfaces"
import { useGetAgentQuery, useUpdateAgentMutation } from "../../store"
import { Grid, Stack } from "@mui/material"
import { Name } from "../formFields/name"
import { Url } from "../formFields/url"
import { Contacts } from "../formFields/contacts"
import { Location } from "../formFields/locationsSelect"

interface IProps {
  id: ADV.Agent["id"]
}
interface IFormData extends ADV.UpdateAgent {}

export const UpdateAgentForm: FC<IProps> = (props) => {
  return (
    <RtkHookForm<
      IFormData,
      ADV.UpdateAgentRes,
      typeof useUpdateAgentMutation,
      ADV.Agent,
      typeof useGetAgentQuery
    >
      successMessage="Агент обновлён успешно"
      mutationHook={useUpdateAgentMutation}
      getSubmitHandlerParams={(body) => ({
        body,
        pathParams: { id: props.id },
      })}
      initialHook={useGetAgentQuery}
      initialHookParams={[{ pathParams: { id: props.id } }]}
      getDefaultValues={(data) => ({
        geo: data?.geos?.map((g) => g.id),
        contacts: data?.contacts?.map((c) => c.name),
        name: data?.name,
        url: data?.url,
      })}
      actions={[
        {
          text: "Сохранить",
          type: "submit",
          variant: "contained",
          fullWidth: true,
        },
      ]}
    >
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <Stack spacing={1}>
            <Name<IFormData> required />
            <Url<IFormData> name={"url"} />
            <Location<IFormData> name={"geo"} multiple />
          </Stack>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Contacts<IFormData> required />
        </Grid>
      </Grid>
    </RtkHookForm>
  )
}
