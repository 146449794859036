import { FC, useMemo, useState } from "react"
import { useFindNcMembersQuery } from "../store"
import { DataGrid } from "@/core/components"
import { TG } from "../interfaces"
import {
  GridCallbackDetails,
  GridColDef,
  GridFilterModel,
  GridPaginationModel,
  GridToolbarExport,
  getGridNumericOperators,
  getGridSingleSelectOperators,
  getGridStringOperators,
  GridCsvGetRowsToExportParams,
} from "@mui/x-data-grid-pro"
import { GRID_PAGE_SIZE_OPTIONS } from "@core/constants"
import { Box, FormControlLabel, Switch } from "@mui/material"
import { ChatMember } from "@telegraf/types"
import { formatDateAndTime } from "@/core/utils"
import { AutoRefreshSwitch, IAutoRefreshSwitchProps } from "./autoRefreshSwitch"
import {
  NcMembersExport,
  IProps as INcMembersExportProps,
} from "./ncMembersExport"

interface IGroupByDateSwitchProps {
  value: boolean
  onGrouppngChange: (value: boolean) => void
}
const GroupByDateSwitch = ({
  value,
  onGrouppngChange,
}: IGroupByDateSwitchProps) => (
  <Box>
    <FormControlLabel
      control={
        <Switch
          value={value}
          onChange={(e, value) => onGrouppngChange(value)}
          inputProps={{ "aria-label": "Группировать по дням" }}
        />
      }
      label="Группировать по дням"
      labelPlacement="start"
    />
  </Box>
)

interface IToolbarProps {
  grouping: IGroupByDateSwitchProps
  refresh: IAutoRefreshSwitchProps
  exportNcMembers: INcMembersExportProps
}
const GridToolbar = ({ grouping, refresh, exportNcMembers }: IToolbarProps) => (
  <Box display="flex" flexDirection="row-reverse" px={2}>
    <GroupByDateSwitch {...grouping} />
    <AutoRefreshSwitch {...refresh} />
    <NcMembersExport {...exportNcMembers} />
  </Box>
)

const memberStatuses: ChatMember["status"][] = [
  "administrator",
  "creator",
  "member",
  "kicked",
  "left",
  "restricted",
]
const getStatusText = (status: ChatMember["status"]): string => {
  switch (status) {
    case "administrator":
      return "Администратор"
    case "creator":
      return "Создатель"
    case "member":
      return "Участник"
    case "kicked":
      return "Исключён"
    case "left":
      return "Покинул"
    case "restricted":
      return "Ограничен"
    default:
      return "-"
  }
}

const stringFilterOperators = getGridStringOperators().filter(
  (o) => o?.value === "equals",
)
const singleSelectFilterOperators = getGridSingleSelectOperators().filter(
  (o) => o?.value === "is",
)
const numericFilterOperators = getGridNumericOperators().filter(
  (o) => o?.value === ">=" && { ...o },
)

const AUTOREFRESH_INTERVAL = 60000 * 30

interface IProps {}
export const NcMembers: FC<IProps> = (props) => {
  const [autorefresh, setAutorefresh] = useState(AUTOREFRESH_INTERVAL)
  const [groupingByDate, setGrouppingByDate] = useState(false)
  const [page, setPage] = useState(0)
  const [pageSize, setPageSize] = useState(GRID_PAGE_SIZE_OPTIONS[2])
  const [find, setFind] = useState<
    Partial<TG.FindNcMembersReq["queryParams"]["find"]>
  >({ count: "2", status: "member" })

  const { isFetching, data } = useFindNcMembersQuery(
    {
      queryParams: {
        find,
        groupByDate: groupingByDate,
        take: pageSize,
        skip: page * pageSize,
      },
    },
    { pollingInterval: 60000 * 30 },
  )
  const [members, total] = data ?? []

  const columns = useMemo<GridColDef<TG.INcMember>[]>(
    () => [
      {
        headerName: "Дата обновления статуса",
        field: "join_date",
        valueFormatter: ({ value }) => formatDateAndTime(value),
        flex: 2,
        filterable: false,
      },
      {
        headerName: "Чат",
        field: "chat_title",
        flex: 2,
        filterOperators: stringFilterOperators,
        sortable: false,
      },
      {
        headerName: "Юзернейм чата",
        field: "chat_username",
        flex: 1,
        filterOperators: stringFilterOperators,
        sortable: false,
      },
      {
        headerName: "ID участника",
        field: "user_id",
        flex: 1,
        filterOperators: stringFilterOperators,
        sortable: false,
      },
      {
        headerName: "Юзернейм участника",
        field: "user_username",
        flex: 1,
        filterOperators: stringFilterOperators,
        sortable: false,
      },
      {
        headerName: "Имя участника",
        field: "user_first_name",
        flex: 2,
        filterOperators: stringFilterOperators,
        sortable: false,
      },
      {
        headerName: "Кол-во НЧ (всего)",
        field: "count",
        type: "number",
        valueGetter: ({ value }) => +value,
        valueParser: ({ value }) => +value,
        flex: 1,
        filterOperators: numericFilterOperators,
        sortable: false,
      },
      groupingByDate
        ? {
            headerName: "Вступил в НЧ за день",
            field: "member_count_grouped_by_day",
            type: "number",
            valueGetter: ({ value }) => +value,
            valueParser: ({ value }) => +value,
            flex: 1,
            filterOperators: numericFilterOperators,
            sortable: false,
            filterable: false,
          }
        : {
            headerName: "Участник НЧ",
            field: "member_count",
            type: "number",
            valueGetter: ({ value }) => +value,
            valueParser: ({ value }) => +value,
            flex: 1,
            filterOperators: numericFilterOperators,
            sortable: false,
            filterable: false,
          },
      groupingByDate
        ? {
            headerName: "Изгнан или ограничен в НЧ за день",
            field: "not_member_count_grouped_by_day",
            type: "number",
            valueGetter: ({ value }) => +value,
            valueParser: ({ value }) => +value,
            flex: 1,
            filterOperators: numericFilterOperators,
            sortable: false,
            filterable: false,
          }
        : {
            headerName: "Изгнан или ограничен в НЧ",
            field: "not_member_count",
            type: "number",
            valueGetter: ({ value }) => +value,
            valueParser: ({ value }) => +value,
            flex: 1,
            filterOperators: numericFilterOperators,
            sortable: false,
            filterable: false,
          },
      {
        headerName: "Статус",
        field: "status",
        valueFormatter: ({ value }) => getStatusText(value),
        type: "singleSelect",
        filterOperators: singleSelectFilterOperators,
        valueOptions: memberStatuses,
        getOptionLabel: getStatusText,
        flex: 1,
      },
    ],
    [groupingByDate],
  )

  const updatePagination = ({ page, pageSize }: GridPaginationModel) => {
    setPage(page)
    setPageSize(pageSize)
  }

  const handleFilterModeChange = (
    model: GridFilterModel,
    details: GridCallbackDetails<"filter">,
  ) => {
    setPage(0)

    const find = model.items.reduce<
      Partial<TG.FindNcMembersReq["queryParams"]["find"]>
    >((prev, cur) => ({ ...prev, [cur.field]: cur.value }), {})
    setFind(find)
  }

  return (
    <>
      <DataGrid<TG.INcMember>
        getRowId={(row) => row.update_id}
        columns={columns}
        rows={members ?? []}
        loading={isFetching}
        rowCount={total ?? 0}
        unstable_headerFilters
        pagination
        paginationMode="server"
        pageSizeOptions={GRID_PAGE_SIZE_OPTIONS}
        paginationModel={{
          page,
          pageSize,
        }}
        onPaginationModelChange={updatePagination}
        filterMode="server"
        sortingMode="server"
        filterDebounceMs={700}
        onFilterModelChange={handleFilterModeChange}
        disableColumnFilter
        initialState={{
          filter: {
            filterModel: {
              items: [
                {
                  id: 0,
                  field: "count",
                  operator: ">=",
                  value: "2",
                },
                {
                  id: 1,
                  field: "status",
                  value: "member",
                  operator: "is",
                },
              ],
            },
          },
          sorting: {
            sortModel: [
              {
                field: "join_date",
                sort: "desc",
              },
            ],
          },
        }}
        slotProps={{
          toolbar: {
            grouping: {
              value: groupingByDate,
              onGrouppngChange: setGrouppingByDate,
            },
            refresh: {
              onChange: (v: boolean) =>
                setAutorefresh(v ? AUTOREFRESH_INTERVAL : 0),
              value: !!autorefresh,
            },
            exportNcMembers: {
              find,
              groupingByDate,
            },
          },
        }}
        slots={{
          toolbar: GridToolbar,
          headerFilterMenu: null,
        }}
      />
    </>
  )
}
