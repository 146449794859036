import { Box, FormControlLabel, Switch } from "@mui/material"
import { useMemo } from "react"

export interface IAutoRefreshSwitchProps {
  value: boolean
  onChange: (value: boolean) => void
}
export const AutoRefreshSwitch = ({
  value,
  onChange,
}: IAutoRefreshSwitchProps) => {
  const defaultValue = useMemo(() => value, [])

  return (
    <Box>
      <FormControlLabel
        control={
          <Switch
            defaultChecked={defaultValue}
            value={value}
            onChange={(e, value) => onChange(value)}
            inputProps={{ "aria-label": "Автообновление" }}
          />
        }
        label="Автообновление"
        labelPlacement="start"
      />
    </Box>
  )
}
