import { FC } from "react"
import { Box, Container } from "@mui/material"
import { Navigate, useParams } from "react-router-dom"
import { Order } from "../components"
import { GoBack } from "@/core/components"
import { useTitle } from "@/core/hooks"

interface IProps {}

export const OrderPage: FC<IProps> = (props) => {
  const { orderId } = useParams()
  if (typeof orderId === "undefined" || isNaN(+orderId)) {
    return <Navigate to=".." relative="path" />
  }

  useTitle(`PR ADV - Заказ ID:${orderId}`)

  return (
    <Container maxWidth={false}>
      <GoBack />
      <Box pb={4}>
        <Order id={+orderId} />
      </Box>
    </Container>
  )
}
