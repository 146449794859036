import { Autocomplete, TextField } from "@mui/material"
import { FC } from "react"
import { useGetAccountintCategoriesQuery } from "../store"
import { Liga } from "../interfaces"

interface IProps {
  onChange: (value: Liga.ProductAccountingCategory | null) => void
  defaultValue?: Liga.ProductAccountingCategory["id"]
}

export const AccountingCategorySelect: FC<IProps> = (props) => {
  const { data, isFetching: isLoading } = useGetAccountintCategoriesQuery({})
  const defaultValue = data?.find((c) => c.id === props.defaultValue)
  const sortedOptions = data?.toSorted((l, r) => l?.name.localeCompare(r?.name))

  return (
    <Autocomplete<Liga.ProductAccountingCategory>
      fullWidth
      value={defaultValue ?? null}
      loading={isLoading}
      isOptionEqualToValue={(option, value) => option.id === value?.id}
      getOptionLabel={(option: Liga.ProductAccountingCategory) => option.name}
      options={sortedOptions ? sortedOptions : []}
      onChange={(e, value) => props.onChange(value ?? null)}
      renderInput={(params) => (
        <TextField {...params} label="Сопоставленная категория" />
      )}
    />
  )
}
