import React from "react"
import { FieldValues, Path, TextFieldElement } from "react-hook-form-mui"

interface IProps<T extends FieldValues> {
  name: Path<T>
  required?: boolean
  disabled?: boolean
}
interface IField extends FieldValues {}

export const SumToken = <T extends IField>({
  required,
  disabled,
  name,
}: IProps<T>) => {
  return (
    <>
      <TextFieldElement<IField>
        name={name}
        label="Кол-во монет"
        type="number"
        required={required}
        disabled={disabled}
        inputProps={{
          step: 0.01,
          min: 0,
        }}
      />
    </>
  )
}
