import React, { FC } from "react"
import { Container } from "@mui/system"
import { Box, Paper } from "@mui/material"
import { OutlinedBox } from "@/core/components"
import { LinksForm, LinksStatistic, LogoutButton } from "../components"
import { useAppSelector } from "@/modules/app/hooks"
import { getTGStatState } from "../store"
import { Login } from "../components/login"

interface IProps {}

export const LinkComparePage: FC<IProps> = (props) => {
  const state = useAppSelector(getTGStatState)

  return (
    <Container maxWidth={"xl"}>
      {!state.token ? (
        <Box display="flex" flexDirection="column" alignItems="center" my={4}>
          <Login />
        </Box>
      ) : (
        <>
          <Box display="flex" flexDirection="row-reverse" mb={2}>
            <LogoutButton />
          </Box>
          <OutlinedBox>
            <LinksForm />
          </OutlinedBox>
          {state.link1 && state.link2 && state.refCode && (
            <OutlinedBox my={2}>
              <LinksStatistic />
            </OutlinedBox>
          )}
        </>
      )}
    </Container>
  )
}
