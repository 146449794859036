import { useMemo } from "react"
import { DataGrid } from "@/core/components"
import { GridColDef } from "@mui/x-data-grid-pro"
import { CSVRow, IMailingFormData } from "./csvForm"
import { v4 as uuidv4 } from "uuid"
import { useFormContext } from "react-hook-form"
import { BodyFieldsPropperties } from "../../../constants"
import { useMailingFields } from "@/modules/telegram/hooks/useMailingFields"

interface IProps {}
export const CSVPreview = (props: IProps) => {
  const formContext = useFormContext<IMailingFormData>()
  const method = formContext.watch("method")
  const fields = useMailingFields(method).filter(
    (key) => !BodyFieldsPropperties[method][key].hidden,
  )
  const watchFields = fields.map(
    (f) => `collation.${f}`,
  ) as `collation.${string &
    keyof (typeof BodyFieldsPropperties)[typeof method]}`[]
  const csvRows = formContext.watch("rows")
  const fieldsCollation = formContext.watch(watchFields)
  const columns = useMemo<GridColDef<CSVRow>[]>(() => {
    return fields
      .filter((f) => formContext.getValues(`collation.${f}`) !== null)
      .map((key) => {
        return {
          type: "string",
          headerName: BodyFieldsPropperties[method][key].name ?? key,
          field: formContext.getValues(`collation.${key}`) ?? uuidv4(),
          flex: 1,
        }
      })
  }, [csvRows, fieldsCollation])

  return (
    <DataGrid<CSVRow>
      columns={columns}
      rows={csvRows ?? []}
      rowCount={csvRows?.length ?? 0}
      getRowId={(row) => uuidv4()}
      // initialState={{
      //   columns: {columnVisibilityModel: {}}
      // }}
      filterMode="client"
      sortingMode="client"
    />
  )
}
