import { ADV } from "../../interfaces"
import { AutocompleteElement } from "react-hook-form-mui"
import { useCampaignsListQuery } from "../../store"

interface IProps {
  required?: boolean
}
interface IField {
  campaign_id: ADV.Campaign["id"]
}

export const Campaign = <T extends IField>({ required }: IProps) => {
  const { data: campaigns, isFetching: isLoading } = useCampaignsListQuery({})

  return (
    <AutocompleteElement
      name="campaign_id"
      label="Кампания"
      required={required}
      matchId
      loading={isLoading}
      autocompleteProps={{
        fullWidth: true,
        getOptionLabel: (option: ADV.Campaign) => option?.name,
      }}
      options={campaigns ?? []}
    />
  )
}
