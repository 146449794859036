import React from "react"
import {
  DataGridPro,
  DataGridProProps,
  GridActionsCellItem,
  GridActionsCellItemProps,
  GridActionsColDef,
  GridColDef,
  GridRowId,
  GridRowModes,
  GridRowModesModel,
  GridRowParams,
  GridRowsProp,
  GridToolbarContainer,
  GridValidRowModel,
  gridClasses,
} from "@mui/x-data-grid-pro"
import {
  GridRowModesModelProps,
  GridStopRowEditModeParams,
} from "@mui/x-data-grid/models/api/gridEditingApi"
import {
  Box,
  Button,
  CircularProgress,
  LinearProgress,
  Theme,
  Typography,
  alpha,
} from "@mui/material"
import AddIcon from "@mui/icons-material/Add"
import EditIcon from "@mui/icons-material/Edit"
import DeleteIcon from "@mui/icons-material/DeleteOutlined"
import SaveIcon from "@mui/icons-material/Save"
import CancelIcon from "@mui/icons-material/Close"
import { GridColumnHeaderFilterCell } from "./gridColumnFilterCell"

type Without<T, U> = { [P in Exclude<keyof T, keyof U>]?: never }
type XOR<T, U> = T | U extends object
  ? (Without<T, U> & U) | (Without<U, T> & T)
  : T | U

interface INotCreatebleRowDataGrid {
  canCreate?: false
}
/**
 * @todo
 */
interface ICreatebleInternalRowDataGrid<R extends GridValidRowModel> {
  /** @deprecated */
  onInternalCreateSave?: (params: GridRowParams<R>) => void | Promise<void>
  onInternalCreateCancel?: (params: GridRowParams<R>) => void | Promise<void>
  onCreateClick: (
    params: EditToolbarProps,
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
  ) => void | Promise<void>
}

interface ICreatebleExternalRowDataGrid<R extends GridValidRowModel> {
  onCreateClick: (
    params: EditToolbarProps,
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
  ) => void | Promise<void>
}
type ICreatebleRowDataGrid<R extends GridValidRowModel> = XOR<
  ICreatebleInternalRowDataGrid<R>,
  ICreatebleExternalRowDataGrid<R>
> & {
  canCreate: true
}
type IMaybeCreatebleRowDataGrid<R extends GridValidRowModel> =
  | ICreatebleRowDataGrid<R>
  | INotCreatebleRowDataGrid

interface INotEditableRowDataGrid {
  canEdit?: false
}
interface IEditableInternalRowDataGrid<R extends GridValidRowModel> {
  /** @deprecated */
  onInternalEditSave?: (params: GridRowParams<R>) => void | Promise<void>
  onInternalEditCancel?: (params: GridRowParams<R>) => void | Promise<void>
}
interface IEditableExternalRowDataGrid<R extends GridValidRowModel> {
  onEditClick: (params: GridRowParams<R>) => void | Promise<void>
}
type IEditableRowDataGrid<R extends GridValidRowModel> = XOR<
  IEditableInternalRowDataGrid<R>,
  IEditableExternalRowDataGrid<R>
> & {
  canEdit: true
  createButtonText?: string
}
type IMaybeEditableRowDataGrid<R extends GridValidRowModel> =
  | IEditableRowDataGrid<R>
  | INotEditableRowDataGrid

interface INotDeletableRowDataGrid {
  canDelete?: false
}
interface IDeletableRowDataGrid<R extends GridValidRowModel> {
  canDelete: true
  onDelete: (params: GridRowParams<R>) => void | Promise<void>
}
type IMaybeDeletableRowDataGrid<R extends GridValidRowModel> =
  | IDeletableRowDataGrid<R>
  | INotDeletableRowDataGrid

type DataGridProps<R extends GridValidRowModel> = DataGridProProps<R> &
  IMaybeCreatebleRowDataGrid<R> &
  IMaybeEditableRowDataGrid<R> &
  IMaybeDeletableRowDataGrid<R> & {
    rowActions?: ((
      params: GridRowParams<R>,
    ) => React.ReactElement<GridActionsCellItemProps>)[]
    actionsColumnFlex?: number
  }

export enum GridRowModesExtended {
  Loading = "loading",
  Creating = "creating",
}
type GridRowModesModelExtendedProps = {
  mode: GridRowModesExtended.Loading | GridRowModesExtended.Creating
} & Omit<GridStopRowEditModeParams, "id" | "field">
type GridRowModesModelExtended = Record<
  GridRowId,
  GridRowModesModelProps | GridRowModesModelExtendedProps
>

interface EditToolbarProps {
  setRowModesModel: (
    newModel: (
      oldModel: GridRowModesModelExtended,
    ) => GridRowModesModelExtended,
  ) => void
}

const styles = (theme: Theme) => ({
  "&.MuiDataGrid-root": {
    height: "auto",
    maxHeight: "100%",
    ".MuiDataGrid-overlayWrapper": {
      minHeight: 4,
      width: "100%",
    },
    ".MuiDataGrid-row:hover": {
      cursor: "pointer",
    },
    // "&--editing .MuiDataGrid-cell": {
    //   backgroundColor: theme.palette.action.focus,
    //   '&[data-field="actions"]': {
    //     backgroundColor: theme.palette.primary.main,
    //   },
    //   "&.MuiDataGrid-cell--editing": {
    //     backgroundColor: `${theme.palette.primary.light} !important`,
    //   },
    // },
    ".MuiDataGrid-row--editing": {
      ".MuiDataGrid-cell": {
        backgroundColor: theme.palette.background.default,
        "&.MuiDataGrid-cell--editing": {
          backgroundColor: theme.palette.background.paper,
        },
      },
    },
    ".MuiDataGrid-virtualScroller": {
      // overflow: 'hidden',
      color: "velvet",
    },
    [`& .${gridClasses.row}:nth-of-type(even)`]: {
      backgroundColor: alpha(theme.palette.primary.main, 0.1),
    },
    "& .MuiDataGrid-columnHeader, .MuiDataGrid-cell": {
      borderRight: `1px solid ${
        theme.palette.mode === "light"
          ? theme.palette.grey[300]
          : theme.palette.grey[800]
      }`,
    },
    "& .MuiDataGrid-columnsContainer, .MuiDataGrid-cell": {
      borderBottom: `1px solid ${
        theme.palette.mode === "light"
          ? theme.palette.grey[300]
          : theme.palette.grey[800]
      }`,
    },
  },
})

const transformGridRowModesModel = (
  model: GridRowModesModelExtended,
): GridRowModesModel => {
  const res = { ...model }
  const extModes: string[] = Object.values(GridRowModesExtended)
  Object.keys(res).forEach((key) => {
    const row = res[key]
    if (row.mode === GridRowModesExtended.Creating) {
      res[key] = { ...row, mode: GridRowModes.Edit }
    } else if (extModes.includes(row.mode)) {
      res[key] = { ...row, mode: GridRowModes.View }
    }
  })

  return res as GridRowModesModel
}

const NoRowsOverlay = (props: {}) => (
  <Box
    sx={{
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      height: "80px",
    }}
  >
    <Typography>Нет данных</Typography>
  </Box>
)

export function DataGrid<R extends GridValidRowModel>(props: DataGridProps<R>) {
  const {
    canEdit,
    canDelete,
    canCreate,
    rows,
    columns,
    rowActions,
    actionsColumnFlex,
    ...otherProps
  } = props
  const { slotProps, slots, sx, ...gridProps } = otherProps
  const { toolbar: toolbarSlot, ...otherSlots } = slots ?? {}
  const { toolbar: toolbarSlotProps, ...otherSlotProps } = slotProps ?? {}

  const [rowModesModel, setRowModesModel] =
    React.useState<GridRowModesModelExtended>({})

  const handleEditClick = async (params: GridRowParams<R>) => {
    const { id } = params
    if (canEdit) {
      if ("onEditClick" in props && typeof props.onEditClick === "function") {
        props.onEditClick(params)
        return
      }
      // if ('onEdit' in props && typeof props.onEdit === 'function') {
      //   setRowModesModel(state => ({ ...state, [id]: { mode: GridRowModesExtended.Loading } }));
      //   await props.onEdit(params);
      // }
    }
    setRowModesModel((state) => ({
      ...state,
      [id]: { mode: GridRowModes.Edit },
    }))
  }
  const handleSaveClick = async (params: GridRowParams<R>) => {
    const { id } = params
    if (canEdit || canCreate) {
      if (
        "onInternalEditSave" in props &&
        typeof props.onInternalEditSave === "function" &&
        rowModesModel[params.id]?.mode === GridRowModes.Edit
      ) {
        setRowModesModel((state) => ({
          ...state,
          [id]: { mode: GridRowModesExtended.Loading },
        }))
        await props.onInternalEditSave(params)
      }
      if (
        "onInternalCreateSave" in props &&
        typeof props.onInternalCreateSave === "function" &&
        rowModesModel[params.id]?.mode === GridRowModesExtended.Creating
      ) {
        setRowModesModel((state) => ({
          ...state,
          [id]: { mode: GridRowModesExtended.Loading },
        }))
        await props.onInternalCreateSave(params)
      }
    }
    setRowModesModel((state) => ({
      ...state,
      [id]: { mode: GridRowModes.View },
    }))
  }
  const handleDeleteClick = async (params: GridRowParams<R>) => {
    const { id } = params
    if (canDelete) {
      if ("onDelete" in props && typeof props.onDelete === "function") {
        setRowModesModel((state) => ({
          ...state,
          [id]: { mode: GridRowModesExtended.Loading },
        }))
        await props.onDelete(params)
        setRowModesModel((state) => {
          const newState = { ...state }
          delete newState[id]
          return newState
        })
      }
    }
  }
  const handleCancelClick = async (params: GridRowParams<R>) => {
    const { id } = params
    if (
      "onInternalEditCancel" in props &&
      typeof props.onInternalEditCancel === "function"
    ) {
      setRowModesModel((state) => ({
        ...state,
        [id]: { mode: GridRowModesExtended.Loading },
      }))
      await props.onInternalEditCancel(params)
    }
    if (
      "onInternalCreateCancel" in props &&
      typeof props.onInternalCreateCancel === "function"
    ) {
      setRowModesModel((state) => ({
        ...state,
        [id]: { mode: GridRowModesExtended.Loading },
      }))
      await props.onInternalCreateCancel(params)
    }

    setRowModesModel((state) => ({
      ...state,
      [id]: {
        mode: GridRowModes.View,
        ignoreModifications: true,
      },
    }))
  }

  const getActions = (
    params: GridRowParams<R>,
  ): React.ReactElement<GridActionsCellItemProps>[] => {
    const { id } = params

    const gridRowActions: React.ReactElement<GridActionsCellItemProps>[] = []
    const isInEditMode =
      rowModesModel[id]?.mode === GridRowModes.Edit ||
      rowModesModel[id]?.mode === GridRowModesExtended.Creating
    const isInLoadingMode =
      rowModesModel[id]?.mode === GridRowModesExtended.Loading

    if (isInLoadingMode) {
      gridRowActions.push(
        <GridActionsCellItem
          label="Loading"
          icon={<CircularProgress size={"2rem"} />}
          disabled
        />,
      )
    } else if (isInEditMode && (canEdit || canCreate)) {
      gridRowActions.push(
        <GridActionsCellItem
          icon={<SaveIcon />}
          label="Save"
          onClick={() => handleSaveClick(params)}
        />,
        <GridActionsCellItem
          icon={<CancelIcon />}
          label="Cancel"
          className="textPrimary"
          onClick={() => handleCancelClick(params)}
          color="inherit"
        />,
      )
    } else {
      if (canEdit) {
        gridRowActions.push(
          <GridActionsCellItem
            icon={<EditIcon />}
            label="Edit"
            className="textPrimary"
            onClick={() => handleEditClick(params)}
            color="inherit"
          />,
        )
      }
      if (canDelete) {
        gridRowActions.push(
          <GridActionsCellItem
            icon={<DeleteIcon />}
            label="Delete"
            onClick={() => handleDeleteClick(params)}
            color="inherit"
          />,
        )
      }
    }

    if (rowActions) {
      gridRowActions.push(
        ...rowActions.map((actionGetter) => actionGetter(params)),
      )
    }

    return gridRowActions
  }

  const getToolbar = (params: EditToolbarProps) => {
    const elements: JSX.Element[] = []
    if (canCreate) {
      elements.push(
        <Button
          color="primary"
          startIcon={<AddIcon />}
          onClick={(ev) => props.onCreateClick(params, ev)}
        >
          {"createButtonText" in props && props.createButtonText
            ? props.createButtonText
            : "Создать"}
        </Button>,
      )
    }
    if (props.unstable_headerFilters) {
      elements.push()
    }
    return <GridToolbarContainer>{...elements}</GridToolbarContainer>
  }

  const actionsColumnFlexDefault = React.useMemo(
    () => Math.min(...columns.map((c) => c.flex ?? 0)),
    [props.columns],
  )
  const cols: GridColDef<R>[] = React.useMemo(() => {
    const columns = [...props.columns]
    let actionsColumn: GridActionsColDef
    if (canEdit || canDelete || props.rowActions) {
      actionsColumn = {
        field: "actions",
        type: "actions",
        headerName: "Действия",
        cellClassName: "actions",
        pinnable: true,
        disableColumnMenu: false,
        flex: actionsColumnFlex ?? actionsColumnFlexDefault,
        disableExport: true,
        getActions: getActions,
      }
      columns.push(actionsColumn)
    }

    return columns
  }, [props.columns, rowModesModel])

  const gridRowModesModel = React.useMemo<GridRowModesModel>(
    () => transformGridRowModesModel(rowModesModel),
    [props, rowModesModel],
  )

  return (
    <>
      <DataGridPro<R>
        editMode="row"
        columns={cols}
        rows={rows}
        rowModesModel={gridRowModesModel}
        density="compact"
        sx={(theme) => {
          const base = styles(theme)
          /**
           * @todo
           */
          const propsSx =
            typeof sx === "function"
              ? sx(theme)
              : sx && "length" in sx
                ? undefined
                : sx
          return { ...base, ...propsSx }
        }}
        slots={{
          toolbar: toolbarSlot ?? getToolbar,
          loadingOverlay: LinearProgress,
          noRowsOverlay: NoRowsOverlay,
          ...otherSlots,
        }}
        slotProps={{
          toolbar: {
            ...toolbarSlotProps,
            setRowModesModel,
          },
          ...otherSlotProps,
        }}
        {...gridProps}
      />
    </>
  )
}
