import { App } from "@app/interfaces"
import {
  Logout,
  Login,
  Telegram,
  ShoppingCart,
  Hail,
  TableChart,
  People,
  Analytics,
  JoinInner,
  DirectionsWalk,
  MoveUp,
  RateReview,
  Send,
  ListAlt,
  ElderlyWoman,
  ContactMail,
  CurrencyBitcoin,
  PointOfSale,
} from "@mui/icons-material"

export const menu: App.RecursiveMenuItem[] = [
  {
    title: "ADV",
    url: "adv",
    role: App.UserRole.MANAGER,
    icon: ContactMail,
    isOpen: true,
    items: [
      {
        title: "Заказы",
        url: "/adv/orders",
        role: App.UserRole.MANAGER,
        icon: ListAlt,
      },
      {
        title: "Контрагенты",
        url: "/adv/agents",
        role: App.UserRole.MANAGER,
        icon: ElderlyWoman,
      },
      {
        title: "Платежи",
        url: "/adv/payments",
        role: App.UserRole.MANAGER,
        icon: PointOfSale,
      },
      {
        title: "Пополнения",
        url: "/adv/top-up",
        role: App.UserRole.MANAGER,
        icon: CurrencyBitcoin,
      },
    ],
  },
  {
    title: "Telegram",
    url: "telegram",
    role: App.UserRole.MANAGER,
    icon: Telegram,
    isOpen: true,
    items: [
      {
        title: "Участники НЧ",
        url: "/telegram/nc-members",
        role: App.UserRole.MANAGER,
        icon: People,
      },
      {
        title: "События НЧ",
        url: "/telegram/nc-updates",
        role: App.UserRole.MANAGER,
        icon: RateReview,
      },
      {
        title: "Рассылки",
        url: "/telegram/mailings",
        role: App.UserRole.MANAGER,
        icon: Send,
      },
      {
        title: "TGStat",
        url: "tgstat",
        role: App.UserRole.MANAGER,
        icon: Analytics,
      },
    ],
  },
  {
    title: "Маркетплейсы",
    url: "marketplace",
    role: App.UserRole.MANAGER,
    icon: ShoppingCart,
    isOpen: true,
    items: [
      {
        title: "Конфигурация МП",
        url: "/marketplace/products",
        role: App.UserRole.MANAGER,
        icon: JoinInner,
      },
      {
        title: "Курьеры",
        url: "/marketplace/couriers-statistic",
        role: App.UserRole.MANAGER,
        icon: DirectionsWalk,
      },
      {
        title: "Продажи",
        url: "/marketplace/sales-statistic",
        role: App.UserRole.MANAGER,
        icon: Analytics,
      },
      {
        title: "Таблица простоя",
        url: "/marketplace/emptytime",
        role: App.UserRole.MANAGER,
        icon: TableChart,
      },
      {
        title: "Перегрузка",
        url: "/marketplace/reloading",
        role: App.UserRole.MANAGER,
        icon: MoveUp,
      },
    ],
  },
  { title: "HR", url: "hr", role: App.UserRole.MANAGER, icon: Hail },
  {
    title: "Вход",
    url: "/login",
    role: App.UserRole.ANON,
    exactRole: true,
    icon: Login,
  },
  { title: "Выход", url: "/logout", role: App.UserRole.USER, icon: Logout },
]
