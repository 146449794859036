import { Box, BoxProps } from "@mui/material";
import { FC } from "react";

interface IProps extends BoxProps {
    children: React.ReactNode;
    paper?: boolean;
}

export const OutlinedBox: FC<IProps> = ({children, paper, ...boxProps}) => <Box
    p={1}
    sx={theme => ({
        outline: `1px solid ${theme.palette.divider}`,
        borderRadius: 1,
        backgroundColor: theme.palette.background[paper ? 'paper' : 'default'],
    })}
    {...boxProps}
>
    {children}
</Box>