import {
  Collapse,
  Divider,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Paper,
  SvgIcon,
  SwipeableDrawer,
  Toolbar,
  useTheme,
} from "@mui/material"
import React, { FC, useEffect, useMemo, useState } from "react"
import { useSelector } from "react-redux"
import { useAppDispatch } from "@app/hooks"
import {
  getIsAppMenuOpen,
  setMenuButtonVisible,
  setMenuOpen,
  getAuthRole,
} from "@app/store"
import { App } from "@app/interfaces"
import { NavLink } from "react-router-dom"
import { ExpandMore, ExpandLess } from "@mui/icons-material"

interface IAppMenuProps {
  menu: App.RecursiveMenuItem[]
}
interface MenuListItemProps {
  item: App.MenuItem
  depth: number
}
interface MenuGroupProps {
  item: App.MenuGroup
  depth: number
}

export const drawerWidth = 440

interface IAppNavLinkProps {
  children: React.ReactElement
  to: string
  props?: [...any]
}

const getIcon = (Icon: typeof SvgIcon) => <Icon />

export const NavListItem: FC<IAppNavLinkProps> = ({
  to,
  children,
  ...props
}): React.ReactElement => {
  const theme = useTheme()
  const dispatch = useAppDispatch()

  return (
    <NavLink
      to={to}
      style={{
        textDecoration: "none",
        color: theme.palette.text.primary,
        width: "100%",
      }}
      onClick={() => dispatch(setMenuOpen(false))}
      children={({ isActive }) => (
        <ListItemButton selected={isActive} {...props}>
          {children}
        </ListItemButton>
      )}
    />
  )
}

export const MenuListItem: FC<MenuListItemProps> = ({ item, depth }) => {
  return (
    <ListItem
      key={item.url}
      disablePadding
      style={{ paddingLeft: `${depth * 20}px` }}
    >
      <NavListItem to={item.url}>
        <>
          {!!item.icon && (
            <ListItemIcon>{item.icon && getIcon(item.icon)}</ListItemIcon>
          )}
          <ListItemText primary={item.title} />
        </>
      </NavListItem>
    </ListItem>
  )
}

const renderMenuItem = (
  item: App.RecursiveMenuItem,
  depth: number = 0,
): JSX.Element[] => {
  if ("items" in item) {
    return [<MenuGroup key={item.url} item={item} depth={depth} />]
  } else {
    return [<MenuListItem key={item.url} item={item} depth={depth} />]
  }
}

const MenuGroup: FC<MenuGroupProps> = ({ item, depth }) => {
  const [isOpen, setIsOpen] = useState(!!item.isOpen)
  const handleToggle = () => setIsOpen(!isOpen)

  return (
    <>
      <ListItem
        key={item.url}
        disablePadding
        sx={{ paddingLeft: `${depth * 20}px` }}
      >
        <NavListItem to={item.url}>
          <>
            {!!item.icon && (
              <ListItemIcon>{item.icon && getIcon(item.icon)}</ListItemIcon>
            )}
            <ListItemText primary={item.title} />
          </>
        </NavListItem>
        <ListItemButton onClick={handleToggle} sx={{ alignSelf: "stretch" }}>
          {isOpen ? <ExpandLess /> : <ExpandMore />}
        </ListItemButton>
      </ListItem>
      <Collapse in={isOpen} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          {item.items.flatMap((subItem) => renderMenuItem(subItem, depth + 1))}
        </List>
      </Collapse>
    </>
  )
}

export const AppMenu: FC<IAppMenuProps> = (props) => {
  const { menu } = props
  const dispatch = useAppDispatch()

  const appUserRole = useSelector(getAuthRole)
  const open = useSelector(getIsAppMenuOpen)

  const filteredMenu = useMemo(
    () =>
      menu.filter((item) => {
        if (Array.isArray(item)) {
          return true
        }
        if (!("role" in item) || item.role === undefined) {
          return true
        }
        if (item.exactRole) {
          return item.role === appUserRole
        } else {
          return item.role <= appUserRole
        }
      }),
    [appUserRole],
  )

  useEffect(() => {
    if (filteredMenu.length > 0) {
      dispatch(setMenuButtonVisible(true))
    } else {
      dispatch(setMenuButtonVisible(false))
    }
  }, [filteredMenu])

  return (
    <>
      <Paper elevation={2}>
        <SwipeableDrawer
          anchor={"left"}
          variant="temporary"
          open={open}
          onClose={() => dispatch(setMenuOpen(false))}
          onOpen={() => dispatch(setMenuOpen(true))}
          sx={{
            width: drawerWidth,
            flexShrink: 0,
            "& .MuiDrawer-paper": {
              width: drawerWidth,
              boxSizing: "border-box",
            },
          }}
        >
          <Toolbar />
          <Divider />
          {/* <List onClick={() => dispatch(setMenuOpen(false))}> */}
          <List>{filteredMenu.map((item) => renderMenuItem(item))}</List>
        </SwipeableDrawer>
      </Paper>
    </>
  )
}
