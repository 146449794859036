import { FC } from "react"
import { IFormProps } from "@/core/interfaces"
import { RtkHookForm } from "@/core/components"
import { ADV } from "../../interfaces"
import { useGetOrderQuery, useUpdateOrderMutation } from "../../store"
import { Grid, Stack } from "@mui/material"
import { Status } from "../formFields/statusSelect"

interface IProps extends IFormProps<ADV.CreateOrderRes, ADV.UpdateOrderRes> {
  id: ADV.Order["id"]
}
interface IFormData extends ADV.UpdateOrder {}

export const UpdateOrderStatusForm: FC<IProps> = (props) => {
  return (
    <RtkHookForm<
      IFormData,
      ADV.UpdateOrderRes,
      typeof useUpdateOrderMutation,
      ADV.GetOrderRes,
      typeof useGetOrderQuery
    >
      mutationHook={useUpdateOrderMutation}
      getSubmitHandlerParams={(body) => ({
        body,
        pathParams: { id: props.id },
      })}
      initialHook={useGetOrderQuery}
      initialHookParams={[{ pathParams: { id: props.id } }]}
      getDefaultValues={(data) => ({
        status_id: data?.status_id,
      })}
      actions={[
        {
          text: "Сохранить",
          type: "submit",
          variant: "contained",
          fullWidth: true,
        },
      ]}
    >
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6} lg={4}>
          <Stack spacing={1}>
            <Status<IFormData> for="orders" />
          </Stack>
        </Grid>
      </Grid>
    </RtkHookForm>
  )
}
