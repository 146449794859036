import React from "react"
import { DatePickerElement } from "react-hook-form-mui"

interface IProps {
  required?: boolean
}
interface IField {
  date: Date
}

export const DateField = <T extends IField>({ required }: IProps) => {
  return (
    <DatePickerElement<IField> name={"date"} label="Дата" required={required} />
  )
}
