import { FC } from "react"
import { Box, Container, Grid } from "@mui/material"
import { MarketplaceMenu } from "../components"
import { Outlet } from "react-router-dom"
import { useTitle } from "@/core/hooks"

interface IProps {}

export const LigaPage: FC<IProps> = (props) => {
  useTitle(`PR Маркетплейсы`)

  return (
    <Container maxWidth={false}>
      <Grid container>
        <Grid item xs={12} md={2}>
          <MarketplaceMenu />
        </Grid>
        <Grid item xs={12} md={10}>
          <Box maxHeight="85vh" height="100%">
            <Outlet />
          </Box>
        </Grid>
      </Grid>
    </Container>
  )
}
