import { createSlice } from "@reduxjs/toolkit"
import { RootState, registerReducer, store } from "@core/store"

const STATE_KEY = "hr"
export const CONFIG_KEY = `${STATE_KEY}_state`

interface IState {}
type StateFromRoot = { [STATE_KEY]: IState }

const persistantState = localStorage.getItem(CONFIG_KEY)
const emptyState: IState = {}

const initialState: IState = persistantState
  ? JSON.parse(persistantState)
  : emptyState

export const hrSlice = createSlice({
  name: "hr",
  initialState: initialState,
  reducers: {},
})
registerReducer(hrSlice.name, hrSlice.reducer)
store.subscribe(() => {
  localStorage.setItem(CONFIG_KEY, JSON.stringify(store.getState().hr))
})

export const {} = hrSlice.actions

export const getHrState = (state: RootState<StateFromRoot>) => state.hr
