import { Api } from "@/core/interfaces"
import { ChatMember, Update } from "@telegraf/types"

export namespace TG {
  export type Updates = {
    id: number
    update: Update
  }
  export type FindUpdatesReq = Api.Request<{
    queryParams: {
      query?: string
      take?: number
      skip?: number
    }
  }>
  export type FindUpdatesRes = [updates: Updates[], total: number]

  export interface INcMember {
    update_id: number
    join_date: string
    chat_title: string
    chat_username: string
    user_id: string
    user_username: string
    user_first_name: string
    count: number | string
    status: ChatMember["status"]
  }
  export type FindNcMembersReq = Api.Request<{
    queryParams: {
      find?: Partial<Omit<INcMember, "update_id">>
      take?: number
      skip?: number
      groupByDate?: boolean
    }
  }>
  export type FindNcMembersRes = [data: INcMember[], total: number]
  export type ExportNcMembersReq = Api.Request<{
    queryParams: {
      find?: Partial<Omit<INcMember, "update_id">>
      groupByDate?: boolean
    }
  }>

  export enum NcUpdateType {
    TEXT_MESSAGE = "text",
    MEDIA_MESSAGE = "media",
    JOINED_TO_CHAT = "joined",
    LEFT_FROM_CHAT = "left",
  }
  export interface INcUpdateBase {
    id: number
    type: NcUpdateType
    from_first_name: string
    from_id: string
    from_username: string
    chat_id: string
    chat_username: string
    chat_title: string
    date: string
  }
  export interface ITgUpdateText extends INcUpdateBase {
    type: NcUpdateType.MEDIA_MESSAGE | NcUpdateType.TEXT_MESSAGE
    text: string
  }
  export interface ITgUpdateChatMember extends INcUpdateBase {
    type: NcUpdateType.JOINED_TO_CHAT | NcUpdateType.LEFT_FROM_CHAT
  }
  export type INcUpdate = ITgUpdateChatMember | ITgUpdateText
  export type FindNcMessagesReq = Api.Request<{
    queryParams: {
      find?: Partial<Omit<INcUpdate, "update_id">>
      take?: number
      skip?: number
    }
  }>
  export type FindNcMessagesRes = [data: INcUpdate[], total: number]
}
