import React from "react"
import { DatePickerElement } from "react-hook-form-mui"

interface IProps {
  required?: boolean
}
interface IField {
  extensions: { date_finish?: Date | undefined }
}

export const OrderExtensionEndDate = <T extends IField>({
  required,
}: IProps) => {
  return (
    <DatePickerElement<IField>
      name="extensions.date_finish"
      label="Дата окончания продления"
      required={required}
    />
  )
}
