import React from "react"
import { DatePickerElement } from "react-hook-form-mui"

interface IProps {
  required?: boolean
}
interface IField {
  extensions: { date_start?: Date | null }
}

export const OrderExtensionStartDate = <T extends IField>({
  required,
}: IProps) => {
  return (
    <DatePickerElement<IField>
      name={"extensions.date_start"}
      label="Дата начала продления"
      required={required}
    />
  )
}
