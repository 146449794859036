import { Stack, Button, Box } from "@mui/material"
import { FieldValues, useFormContext } from "react-hook-form-mui"
import { Type } from "./typeSelect"
import { RefCode } from "./refCodesSelect"
import { EMPTY_LINK } from "@/modules/adv2/constants/adv"
import { IOrderFormData } from "../forms/createOrderForm"
import { Bot } from "./botSelect"

interface IProps<T extends FieldValues> {
  minLength?: number
}
interface IField {
  links: string
}

export function Links<T extends FieldValues = IField>(
  props: IProps<T>,
): JSX.Element {
  const ctx = useFormContext<IOrderFormData>()
  const links = ctx?.watch("links")

  const deleteLink = (index: number) => {
    const newValue = links?.filter((v, i) => i !== index)
    ctx.setValue(
      "links",
      Array.isArray(newValue) && newValue.length > 0 ? newValue : undefined,
    )
  }

  const minArrLength =
    props.minLength !== undefined && props.minLength > -1 ? props.minLength : 1

  return (
    <Stack spacing={4}>
      {links?.map((link, i) => (
        <Stack spacing={2} key={i}>
          <Type<IOrderFormData>
            required
            name={`links.${i}.type_id`}
            for="bots"
          />
          <Bot<IOrderFormData>
            typeId={link.type_id}
            name={`links.${i}.bot_id`}
            required
          />
          <RefCode<IOrderFormData> name={`links.${i}.ref_code_id`} />
          {links?.length > minArrLength && (
            <Box display="flex" flexDirection="row-reverse">
              <Button variant="text" onClick={() => deleteLink(i)}>
                Удалить
              </Button>
            </Box>
          )}
        </Stack>
      ))}
      <Button
        onClick={() => ctx.setValue("links", [...(links ?? []), EMPTY_LINK])}
      >
        Добавить ссылку
      </Button>
    </Stack>
  )
}
