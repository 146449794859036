import {
  FieldValues,
  Path,
  TextFieldElement,
  useFormContext,
} from "react-hook-form-mui"

interface IProps<T extends FieldValues> {
  name: Path<T>
  required?: boolean
  disabled?: boolean
}
interface IField extends FieldValues {}

const TRANSACTION_HASH_REGEXP = /^[a-fA-F0-9]{64}$/

export const TransactionHash = <T extends IField>({
  required,
  disabled,
  name,
}: IProps<T>) => {
  const ctx = useFormContext()

  return (
    <TextFieldElement<IField>
      name={name}
      label="Хеш транзакции"
      required={required}
      disabled={disabled}
      validation={{ pattern: TRANSACTION_HASH_REGEXP }}
    />
  )
}
