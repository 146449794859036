import { TextFieldElement } from "react-hook-form-mui"

interface IProps {
  required?: boolean
}
interface IField {
  name: string
}

export const Name = <T extends IField>({ required }: IProps) => {
  return (
    <TextFieldElement<IField> name="name" label="Имя" required={required} />
  )
}
