import { FC } from "react"
import { TextFieldElement } from "react-hook-form-mui"

interface IProps {
  required?: boolean
}
interface IField {
  comment?: string
}

export const Comment = <T extends IField>({ required }: IProps) => {
  return (
    <TextFieldElement<IField>
      name="comment"
      label="Комментарий"
      required={required}
      rows={4}
      multiline
      fullWidth
    />
  )
}
