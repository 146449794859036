import { FC } from "react"
import { DataGrid } from "@core/components"
import { GridColDef, getGridStringOperators } from "@mui/x-data-grid-pro"
import { Liga } from "../interfaces"
import {
  useGetLocationsQuery,
  useGetMarketplaceDistrictsQuery,
  useUpdateMarketplaceDistrictMutation,
} from "../store"
import { GridCellEditingWrapper } from "@/core/components/dataGrid/gridCellEditingWrapper"
import { LocationSelect } from "."
import {
  isEqualObjects,
  stringOrEmptySortingComparator,
  wrapFilterOperator,
} from "@/core/utils"

interface IProps {}

export const MarketplaceDistrictsTable: FC<IProps> = (props) => {
  const { data, isFetching } = useGetMarketplaceDistrictsQuery({})
  const { data: locations, isFetching: isProductsLoading } =
    useGetLocationsQuery({
      queryParams: { type: "Район" },
    })

  const [update, { isLoading: isUpdating }] =
    useUpdateMarketplaceDistrictMutation()

  const columns: GridColDef<Liga.MarketplaceDistrict>[] = [
    {
      headerName: "Шоп",
      field: "shop",
      filterOperators: getGridStringOperators().map((f) =>
        wrapFilterOperator<Liga.MarketplaceDistrict>(
          f,
          (applyFilterFn, params) =>
            applyFilterFn({ ...params, value: params.value?.name }),
          (applyFilterFnV7, value, row, col, apiRef) =>
            applyFilterFnV7(value?.name, row, col, apiRef),
        ),
      ),
      valueGetter: ({ row }) => row?.city?.shop,
      valueFormatter: ({ value }) => value?.name,
      sortComparator: (v1, v2) =>
        stringOrEmptySortingComparator(v1?.name, v2?.name),
      flex: 2,
    },
    {
      headerName: "Город",
      field: "city",
      filterOperators: getGridStringOperators().map((f) =>
        wrapFilterOperator<Liga.MarketplaceDistrict>(
          f,
          (applyFilterFn, params) =>
            applyFilterFn({ ...params, value: params.value?.name }),
          (applyFilterFnV7, value, row, col, apiRef) =>
            applyFilterFnV7(value?.name, row, col, apiRef),
        ),
      ),
      valueFormatter: ({ value }) => value?.name,
      sortComparator: (v1, v2) =>
        stringOrEmptySortingComparator(v1?.name, v2?.name),
      flex: 2,
    },
    { headerName: "Район", field: "name", flex: 2 },
    {
      headerName: "Сопоставленный район",
      field: "liga_district_id",
      editable: true,
      filterOperators: getGridStringOperators().map((f) =>
        wrapFilterOperator<Liga.MarketplaceDistrict>(
          f,
          (applyFilterFn, params) =>
            applyFilterFn({ ...params, value: params.value?.name }),
          (applyFilterFnV7, value, row, col, apiRef) =>
            applyFilterFnV7(value?.name, row, col, apiRef),
        ),
      ),
      renderEditCell: (params) => (
        <GridCellEditingWrapper<typeof LocationSelect>
          id={params.id}
          field={params.field}
          component={LocationSelect}
          componentProps={{
            defaultValue: params.value?.id ?? null,
            type: "Район",
            filter: (value: Liga.Location) =>
              value.parent_id === params.row.city.liga_city_id,
          }}
        />
      ),
      valueFormatter: (params) => {
        return params.value?.name
      },
      valueGetter: (params) => {
        return params.row?.location
      },
      valueParser(value, params) {
        return typeof value === "string"
          ? value
          : locations?.find((l) => l.id === value)
      },
      valueSetter(params) {
        return {
          ...params.row,
          liga_district_id: params.value?.id ?? null,
          location: params.value,
        }
      },
      sortComparator: (v1, v2) =>
        stringOrEmptySortingComparator(v1?.name, v2?.name),
      flex: 2,
    },
  ]

  const updateMarketplaceDistrict = (
    upd: Liga.MarketplaceDistrict,
    old: Liga.MarketplaceDistrict,
  ) => {
    if (isEqualObjects(upd, old)) {
      return old
    }
    update({
      pathParams: {
        id: upd.id,
      },
      body: {
        liga_district_id: upd.liga_district_id,
      },
    })
    return upd
  }

  return (
    <DataGrid<Liga.MarketplaceDistrict>
      columns={columns}
      rows={data ?? []}
      loading={isFetching || isUpdating}
      paginationMode="client"
      editMode="cell"
      processRowUpdate={updateMarketplaceDistrict}
      unstable_headerFilters
      disableColumnFilter
      initialState={{
        sorting: {
          sortModel: [
            { field: "city", sort: "asc" },
            { field: "name", sort: "asc" },
            { field: "shop", sort: "asc" },
          ],
        },
      }}
    />
  )
}
