import { createSlice } from "@reduxjs/toolkit"
import { RootState, registerReducer, store } from "@core/store"

const STATE_KEY = "liga" as const
export const CONFIG_KEY = `${STATE_KEY}_state`

interface IState {}
type StateFromRoot = { [STATE_KEY]: IState }

const persistantState = localStorage.getItem(CONFIG_KEY)
const emptyState: IState = {}

const initialState: IState = persistantState
  ? JSON.parse(persistantState)
  : emptyState

export const ligaSlice = createSlice({
  name: "liga",
  initialState: initialState,
  reducers: {},
})
registerReducer(ligaSlice.name, ligaSlice.reducer)
store.subscribe(() => {
  localStorage.setItem(CONFIG_KEY, JSON.stringify(store.getState().liga))
})

export const {} = ligaSlice.actions

export const getLigaState = (state: RootState<StateFromRoot>) =>
  state[STATE_KEY]
