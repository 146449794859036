import { Alert, Button, CircularProgress } from "@mui/material"
import React, { FC, useEffect } from "react"
import { useAppDispatch, useAuth } from "@app/hooks"
import { useAuthMutation, setAuth } from "@app/store"
import { App } from "@app/interfaces"
import { PasswordElement, TextFieldElement, useForm } from "react-hook-form-mui"
import { Form } from "@core/components"

interface ILoginProps {}
interface IFormData {
  username: string
  password: string
}

export const Login: FC<ILoginProps> = (props) => {
  const dispatch = useAppDispatch()

  const [
    auth,
    {
      isLoading: isAuthLoading,
      isError: isAuthError,
      isSuccess: isAuthSuccess,
      error: authError,
    },
  ] = useAuthMutation()
  const submitForm = async (formData: IFormData) => {
    const res = await auth(formData).unwrap()
    dispatch(setAuth({ token: res.access_token, role: App.UserRole.ADMIN }))
  }

  const [errorMessage, setErrorMessage] = React.useState("")
  useEffect(() => {
    let text = ""
    if (!!authError && "status" in authError && authError.status === 401) {
      text = "Логин или пароль не верны"
    }
    setErrorMessage(text)
  }, [isAuthError])

  return (
    <>
      {isAuthError && (
        <Alert severity="error">Произошла ошибка! {errorMessage}</Alert>
      )}
      <Form<IFormData>
        onSubmit={submitForm}
        actions={[
          <Button
            type="submit"
            variant="contained"
            disabled={isAuthLoading}
            fullWidth
          >
            Войти&nbsp;{isAuthLoading && <CircularProgress size={20} />}
          </Button>,
        ]}
      >
        <TextFieldElement<IFormData>
          name="username"
          label="Логин"
          required={true}
        />
        <PasswordElement<IFormData>
          name="password"
          label="Пароль"
          required={true}
        />
      </Form>
    </>
  )
}
