import { FC } from "react"
import { Box, Container, Typography } from "@mui/material"
import { useTitle } from "@/core/hooks"
import { PaymentsTable } from "../components/tables/paymentsTable"
import { PaymentFilters } from "../components/filters/paymentFilters"

interface IProps {}

export const PaymentsPage: FC<IProps> = (props) => {
  useTitle(`PR ADV - Оплаты`)

  return (
    <Container maxWidth={false}>
      <Box mt={5}>
        <Typography variant="h5">Оплаты</Typography>
        <PaymentFilters />
        <Box height="85vh">
          <PaymentsTable />
        </Box>
      </Box>
    </Container>
  )
}
