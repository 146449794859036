import {
  NavigateOptions as ReactRouterNavigateOptions,
  To,
  useNavigate as useReactRouterNavigate,
} from "react-router-dom"
import { useLocation } from "."

export interface NavigateOptions<State extends any = void>
  extends ReactRouterNavigateOptions {
  state?: State
}

export function useNavigate<State extends any>() {
  const navigate = useReactRouterNavigate()
  const { state: currentState } = useLocation<unknown>()
  return (to: To, { state, ...options }: NavigateOptions<State>) =>
    navigate(to, {
      ...options,
      state: { ...(state ?? {}), prev: currentState },
    })
}
