import { Link as ReactRouterLink } from "react-router-dom"
import { useLocation } from "@/core/hooks"
import { Link, LinkProps } from "@mui/material"
import { StateWithPrev } from "../interfaces"

interface IProps<State extends any = void>
  extends LinkProps<typeof ReactRouterLink> {
  title: string
  state?: Omit<StateWithPrev<State>, "prev">
}
export function RouterLink<State extends any = void>({
  title,
  state,
  ...props
}: IProps<State>) {
  const { state: currentState } = useLocation<unknown>()
  return (
    <Link
      component={ReactRouterLink}
      state={{ ...state, prev: currentState }}
      {...props}
    >
      {title}
    </Link>
  )
}
