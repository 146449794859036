import { TextFieldElement, useFormContext } from "react-hook-form-mui"
import { Stack } from "@mui/material"
import { IMailingFormData } from "./mailingForm"
import { useEffect } from "react"
import { ApiMethods } from "typegram"
import { TgMailing } from "../../../interfaces"
import { getMediaFieldNameByMethod } from "./utils"
import { ParseModeSelectField } from "../parseModeSelectField"

interface IProps {}
interface IFormData<M extends TgMailing.AvailableMethods> {
  method: M
  body: IMailingFormData<M>["body"]
}

const DEFAULT_VALUES: {
  [M in TgMailing.AvailableMethods]: Omit<
    Parameters<ApiMethods<any>[M]>[0],
    "chat_id"
  >
} = {
  sendMessage: {
    message_thread_id: undefined,
    text: "",
    parse_mode: undefined,
    entities: undefined,
    disable_web_page_preview: undefined,
    disable_notification: undefined,
    protect_content: undefined,
    reply_to_message_id: undefined,
    allow_sending_without_reply: undefined,
    reply_markup: undefined,
  },
  sendChatAction: {
    action: "typing",
    message_thread_id: undefined,
  },
  sendAnimation: {
    animation: undefined,
    caption: "",
  },
  sendPhoto: {
    photo: undefined,
    caption: "",
  },
  sendVideo: {
    video: undefined,
    caption: "",
  },
}

export const MailingBody = <
  M extends TgMailing.AvailableMethods,
  T extends IFormData<M>,
>(
  props: IProps,
) => {
  const formContext = useFormContext<IFormData<TgMailing.AvailableMethods>>()
  const method = formContext.watch("method")

  useEffect(() => {
    formContext.setValue("body", DEFAULT_VALUES[method])
  }, [method])

  return (
    <Stack spacing={1}>
      {(method === "sendMessage" ||
        method === "sendPhoto" ||
        method === "sendVideo" ||
        method === "sendAnimation") && (
        <ParseModeSelectField<IMailingFormData> />
      )}
      {method === "sendMessage" && (
        <TextFieldElement
          label="Текст сообщения"
          name="body.text"
          required
          multiline
          rows={14}
          fullWidth
          sx={{ textarea: { resize: "both" } }}
        />
      )}
      {(method === "sendPhoto" ||
        method === "sendVideo" ||
        method === "sendAnimation") && (
        <>
          <TextFieldElement
            label="Подпись"
            name="body.caption"
            multiline
            rows={14}
            fullWidth
            sx={{ textarea: { resize: "both" } }}
          />
          <TextFieldElement
            label="Ссылка на файл"
            name={`body.${getMediaFieldNameByMethod(method)}`}
            fullWidth
            required
          />
        </>
      )}
    </Stack>
  )
}
