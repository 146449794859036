import {
  Button,
  ButtonTypeMap,
  ExtendButtonBase,
  Popover,
  Typography,
} from "@mui/material"
import { TimeoutId } from "@reduxjs/toolkit/dist/query/core/buildMiddleware/types"
import { useEffect, useState } from "react"

interface IPropsWithChildren {
  children: string
}
interface IPropsWithParameter {
  text: string
}
type IProps = {
  buttonProps?: Omit<ExtendButtonBase<ButtonTypeMap>, "onClick">
} & (IPropsWithChildren | IPropsWithParameter)

let timeoutId: TimeoutId | undefined
export const InlineCode = ({ buttonProps, ...props }: IProps) => {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null)
  const text = "text" in props ? props.text : props.children

  const writeTextToclipboard = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
  ) => {
    navigator.clipboard.writeText(text)
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
    clearTimeout(timeoutId)
    timeoutId = undefined
  }

  useEffect(() => {
    if (anchorEl === null) {
      return
    }
    timeoutId = setTimeout(() => handleClose(), 2000)
    return () => clearTimeout(timeoutId)
  }, [anchorEl])

  const open = !!anchorEl
  const id = open ? "simple-popover" : undefined

  return (
    <>
      <Button
        onClick={writeTextToclipboard}
        sx={(theme) => ({
          textTransform: "none",
          py: 0,
          px: "4px",
          backgroundColor: `${theme.palette.background.paper}`,
        })}
        color="secondary"
        {...buttonProps}
      >
        <Typography variant="caption" fontFamily="monospace" component="span">
          {text}
        </Typography>
      </Button>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
      >
        <Typography sx={{ p: 1 }} component="div">
          Скопировано
        </Typography>
      </Popover>
    </>
  )
}
