import { Autocomplete, TextField } from "@mui/material"
import { FC } from "react"
import { useGetProductsQuery } from "../store"
import { Liga } from "../interfaces"

interface IProps {
  onChange: (value: Liga.Product["id"] | null) => void
  defaultValue?: Liga.Product["id"]
  disableClearable?: boolean
}

export const ProductSelect: FC<IProps> = (props) => {
  const { data, isFetching: isLoading } = useGetProductsQuery({})
  const defaultValue = data?.find((l) => l.id === props.defaultValue)

  return (
    <Autocomplete<Liga.Product, false, typeof props.disableClearable>
      fullWidth
      disableClearable={props.disableClearable}
      value={defaultValue ?? null}
      loading={isLoading}
      isOptionEqualToValue={(option, value) => option.id === value?.id}
      getOptionLabel={(option: Liga.Product) => option.name}
      options={
        isLoading
          ? []
          : data?.toSorted((l, r) => l.name.localeCompare(r.name)) ?? []
      }
      onChange={(e, value) => props.onChange(value?.id ?? null)}
      renderInput={(params) => <TextField {...params} label="Товар" />}
    />
  )
}
