import { Autocomplete, TextField } from "@mui/material"
import { FC } from "react"
import { useGetLocationsQuery } from "../store"
import { Liga } from "../interfaces"

interface IPropsFreeSolo {
  onChange?: (value: string | null) => void
  defaultValue?: string
  freeSolo: true
}
interface IPropsNotFreeSolo {
  onChange?: (value: Liga.Location["id"] | null) => void
  defaultValue?: Liga.Location["id"]
  freeSolo?: false
}
type IProps = {
  type?: string
  filter?: (value: Liga.Location) => boolean
  disableClearable?: boolean
} & (IPropsFreeSolo | IPropsNotFreeSolo)

export const LocationSelect: FC<IProps> = (props) => {
  const { freeSolo, disableClearable, onChange } = props
  const { data, isFetching: isLoading } = useGetLocationsQuery({
    queryParams: {
      type: props.type,
    },
  })
  const defaultValue =
    data?.find((l) => (freeSolo ? l.name : l.id) === props.defaultValue) ??
    (typeof props.defaultValue === "string" ? props.defaultValue : null) ??
    null
  const filteredOptions = props.filter ? data?.filter(props.filter) : data

  {
    /* typeof freeSolo extends true ? string : Liga.Location, */
  }
  return (
    <Autocomplete<
      Liga.Location,
      false,
      typeof disableClearable,
      typeof freeSolo
    >
      fullWidth
      disableClearable={disableClearable}
      value={defaultValue ?? null}
      loading={isLoading}
      isOptionEqualToValue={(option, value) =>
        freeSolo === true ? option === value : option.id === value?.id
      }
      getOptionLabel={(option) =>
        typeof option === "string" ? option : option.name
      }
      options={filteredOptions ? filteredOptions : []}
      onChange={(e, value) => {
        if (typeof onChange === "function") {
          freeSolo === true
            ? onChange(typeof value === "string" ? value : value?.name ?? null)
            : onChange(!!value && typeof value === "object" ? value.id : null)
        }
      }}
      renderInput={(params) => <TextField {...params} label="Локация" />}
      freeSolo={freeSolo}
      autoSelect={!!freeSolo}
    />
  )
}
