import { FC, useEffect, useMemo, useState } from "react";
import { getTGStatState, useGetBotUsersQuery, useLazyGetLinkStatQuery } from "../store";
import { Tgstat } from "../interfaces";
import { useAppSelector } from "@/modules/app/hooks";
import { Alert, AlertTitle, Box, Button, CircularProgress, Typography } from "@mui/material";
import { LinksCompareTable } from "./linksCompareTable";
import { FieldView } from "@/core/components";

interface IProps {}

function parseStatLink(link: string): Tgstat.StatLinkParams | undefined {
  const channel = link.match(/(?<=channel|chat\/)@[0-9a-zA-Z_]+(?=\/stat)/i);
  const linkId = link.match(/(?<=invite-links\/)[0-9]+(?=\/.*)/i);
  if (!channel || !linkId) {
      return;
  }
  return {
      channel: channel[0],
      linkId: +linkId[0],
  }
}

export const LinksStatistic: FC<IProps> = (props) => {
  const state = useAppSelector(getTGStatState);
  const { token } = state;
  if (!token || !state.link1 || !state.link2) {
    return;
  }
  const link1 = useMemo(() => parseStatLink(state.link1 ?? ''), [state.link1]);
  const link2 = useMemo(() => parseStatLink(state.link2 ?? ''), [state.link2]);
  const refCode = state.refCode;
  const [intersection, setIntersection] = useState<Tgstat.UsersIntersection[]>([]);
  const [
    queryLink1,
    {
      isFetching: isLoadingLink1,
      data: dataLink1,
      isError: isErrorLink1,
    }
  ] = useLazyGetLinkStatQuery();
  const [
    queryLink2,
    {
      isFetching: isLoadingLink2, 
      data: dataLink2, 
      isError: isErrorLink2,
    }
  ] = useLazyGetLinkStatQuery();
  const {
    isFetching: isLoadingLink3, 
    data: dataLink3, 
    isError: isErrorLink3,
  } = useGetBotUsersQuery(
    {
      queryParams: {
        referral_code: refCode as string,
      }, 
    }
  );
  
  useEffect(() => {
    nextPage();
  }, []);

  useEffect(() => {
    if (!dataLink1 || !dataLink2) {
      return;
    }
    const intersection: Tgstat.UsersIntersection[] = Object.keys(dataLink1.users).map(id => {
      const l1 = dataLink1.users[+id];
      const l2 = dataLink2.users[+id];
      return {
        user: {
          id,
          name: l1.name,
          link: l1.link,
        },
        l1: {
          joinDate: l1.joinDate,
          leftDate: l1.leftDate,
          channel: dataLink1.channel,
        },
        l2: {
          joinDate: l2?.joinDate,
          leftDate: l2?.leftDate,
          channel: dataLink2?.channel,
        },
        bot: dataLink3?.[+id],
      }
    })
    setIntersection(intersection ?? []);
  }, [dataLink1, dataLink2]);

  const nextPage = () => {
    if (!dataLink1 || dataLink1?.hasMore) {
      queryLink1({
        queryParams: {
          ...link1 as Tgstat.StatLinkParams,
          page: dataLink1?.nextPage,
          offset: dataLink1?.nextOffset,
        }, 
        pathParams: {token},
      })
    }
    if (!dataLink2 || dataLink2?.hasMore) {
      queryLink2({
        queryParams: {
          ...link2 as Tgstat.StatLinkParams,
          page: dataLink2?.nextPage,
          offset: dataLink2?.nextOffset,
        }, 
        pathParams: {token},
      })
    }
  }

  const isTGStatError = isErrorLink1 || isErrorLink2;
  const isLoading = isLoadingLink1 || isLoadingLink2 || isLoadingLink3;

  const LoadMoreButton = () => <Button
    onClick={nextPage}
    fullWidth
    disabled={isLoading || (!dataLink1?.hasMore && !dataLink2?.hasMore)}
    variant='contained'
  >
    {dataLink1?.hasMore || dataLink2?.hasMore
      ? "Загрузить ещё"
      : "Загружено полностью"
    }
    &nbsp;
    {isLoading && <CircularProgress size='1rem' />}
  </Button>

  return <>
    <Box my={2}>
      {isTGStatError && <Alert severity='error'>
        <AlertTitle>Произошла ошибка.</AlertTitle>
        Попробуйте загрузить ещё раз. Если ошибка повторяется, попробуйте выйти и заново залогиниться в TGStat.
      </Alert>}
      {isErrorLink3 && <Alert severity='error'>
        <AlertTitle>Произошла ошибка.</AlertTitle>
        Попробуйте обновить страницу.
      </Alert>}
    </Box>
    <Box height={'70vh'}>
      <LinksCompareTable rows={intersection} />
    </Box>
    <Box my={2}>
      <Box>
        <Typography>Показано:</Typography>
        <FieldView label='Ссылка 1' value={dataLink1 ? Object.keys(dataLink1.users).length : 0} />
        <FieldView label='Ссылка 2' value={dataLink2 ? Object.keys(dataLink2.users).length : 0} />
        <FieldView label='Бот' value={dataLink3 ? Object.keys(dataLink3).length : 0} />
      </Box>
      <LoadMoreButton />
    </Box>
  </>
}
