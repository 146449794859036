import { FC, useEffect, useMemo } from "react"
import { AutocompleteElement, useFormContext } from "react-hook-form-mui"
import { IMailingFormData } from "./csvForm"
import { BodyFieldsPropperties } from "../../../constants"
import { Stack, Typography } from "@mui/material"
import { useMailingFieldsParameters } from "@/modules/telegram/hooks/useMailingFieldsParameters"
import { useMailingFields } from "@/modules/telegram/hooks/useMailingFields"

interface IProps {}
export interface IFormData {}

export const CsvColsToBodyFieldsCollation: FC<IProps> = (props) => {
  const formContext = useFormContext<IMailingFormData>()
  const csvRows = formContext.watch("rows")
  const method = formContext.watch("method")
  const fields = useMailingFields(method)
  const fieldsCollation = formContext.watch("collation")
  const columns = useMemo(() => Object.keys(csvRows?.[0] ?? {}), [csvRows])
  const fieldsParameters = useMailingFieldsParameters(method)

  useEffect(() => {
    for (const field in fieldsCollation) {
      if (!fields.includes(field as keyof typeof fieldsCollation)) {
        formContext.unregister(
          `collation.${field as keyof typeof fieldsCollation}`,
        )
      }
    }
    for (const field of fields) {
      formContext.resetField(`collation.${field}`)
      const matcher = BodyFieldsPropperties[method][field].columnHeaderMatch
      const findedValue = matcher && columns.find((c) => c.match(matcher))
      if (findedValue) {
        formContext.setValue(`collation.${field}`, findedValue)
      }
    }
  }, [method, columns])

  return (
    <Stack spacing={2}>
      {fields.map((field, i) => (
        <AutocompleteElement
          name={`collation.${field}`}
          label={fieldsParameters[field].name ?? field}
          required={fieldsParameters[field].required}
          options={columns.map((c) => ({
            id: c,
            label: c,
          }))}
          matchId
          key={`${field}_${i}`}
          autocompleteProps={{
            getOptionDisabled: (c) =>
              !!Object.values(fieldsCollation ?? {}).find((f) => f === c.label),
          }}
          textFieldProps={{
            helperText: fieldsParameters[field].helperText,
          }}
        />
      ))}
    </Stack>
  )
}
