import { FC } from "react"
import { Box, Container } from "@mui/material"
import { NcUpdates } from "../components"
import { useTitle } from "@/core/hooks"

interface IProps {}

export const TelegramPage: FC<IProps> = (props) => {
  useTitle(`PR Telegram - НЧ монитор`)
  return (
    <Container maxWidth={false}>
      <Box maxHeight="85vh" height="100%">
        <NcUpdates />
      </Box>
    </Container>
  )
}
