import { FC, useState } from "react"
import { Box, Container, Stack, Tab, Tabs, Typography } from "@mui/material"
import { MailingForm } from "../components/mailing/manual/mailingForm"
import { ActiveMailings } from "../components/mailing/activeMailingsTable"
import { FinishedMailings } from "../components/mailing/finishedMailingsTable"
import { MailingCsvForm } from "../components/mailing/csv/csvForm"
import { useTitle } from "@/core/hooks"

interface IProps {}

export const MailingPage: FC<IProps> = (props) => {
  const [tab, setTab] = useState(0)
  useTitle(`PR Telegram - Рассылки`)

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setTab(newValue)
  }
  return (
    <Container maxWidth={"xl"}>
      <Stack spacing={3}>
        <Typography variant="h5">Создание новой рассылки</Typography>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <Tabs
            value={tab}
            onChange={handleChange}
            aria-label="basic tabs example"
          >
            <Tab label={"CSV таблица"} tabIndex={0} />
            <Tab label={"Форма"} tabIndex={1} />
          </Tabs>
        </Box>
        {tab === 0 && <MailingCsvForm />}
        {tab === 1 && <MailingForm />}

        <Typography variant="h5">Запущенные рассылки</Typography>
        <ActiveMailings />

        <Typography variant="h5">Завершённые рассылки</Typography>
        <FinishedMailings />
      </Stack>
    </Container>
  )
}
