import {
  BaseQueryFn,
  createApi,
  fetchBaseQuery,
} from "@reduxjs/toolkit/query/react"
import { EndpointBuilder } from "@reduxjs/toolkit/dist/query/endpointDefinitions"
import {
  RootState,
  dynamicMiddlewaresInstance,
  registerReducer,
} from "@core/store"
import { baseQueryWithReauth } from "@/modules/app/store"
import { Liga } from "../interfaces"

const tagTypes = [
  "Products",
  "ProductPayments",
  "PaymentCategories",
  "AccountingCategories",
  "MarketplaceProducts",
  "MarketplaceCities",
  "MarketplaceDistricts",
  "Locations",
  "LocationTypes",
  "Staff",
  "WarehouseStaffList",
  "Procurements",
] as const

const reducerPath: string = "ligaApi"
export const baseTGStatApiURL = new URL(
  import.meta.env.REACT_APP_TGSTAT_API,
  window.location.origin,
).toString()
const baseQuery: BaseQueryFn = fetchBaseQuery({
  baseUrl: `${
    baseTGStatApiURL.endsWith("/") ? baseTGStatApiURL : baseTGStatApiURL + "/"
  }liga`,
  prepareHeaders: (headers, { getState }) => {
    const token = (getState() as RootState).auth.token
    if (token) {
      headers.set("authorization", `Bearer ${token}`)
    }
    return headers
  },
})

type ApiEndpointBuider = EndpointBuilder<
  typeof baseQuery,
  (typeof tagTypes)[number],
  typeof reducerPath
>

const tgstatEndpoints = (build: ApiEndpointBuider) => ({
  getProducts: build.query<Liga.GetProductsRes, Liga.GetProductsReq>({
    query: () => ({
      url: `products`,
      method: "GET",
    }),
    providesTags: ["Products"],
  }),
  getProductPayments: build.query<
    Liga.GetProductPaymentsRes,
    Liga.GetProductPaymentsReq
  >({
    query: () => ({
      url: `product-payments`,
      method: "GET",
    }),
    providesTags: ["ProductPayments"],
  }),
  getPaymentCategories: build.query<
    Liga.GetPaymentCategoriesRes,
    Liga.GetPaymentCategoriesReq
  >({
    query: () => ({
      url: `payment-categories`,
      method: "GET",
    }),
    providesTags: ["PaymentCategories"],
  }),
  updateProductCategory: build.mutation<
    Liga.UpdateProductCategoriesRes,
    Liga.UpdateProductCategoriesReq
  >({
    query: ({ pathParams: { id }, body }) => {
      return {
        url: `products/${id}`,
        method: "PUT",
        body,
      }
    },
    invalidatesTags: ["ProductPayments"],
  }),
  updateProductPayment: build.mutation<
    Liga.UpdateProductPaymentRes,
    Liga.UpdateProductPaymentReq
  >({
    query: ({ pathParams: { payment_category_id, measure }, body }) => {
      return {
        url: `product-payments/${measure}/${payment_category_id}`,
        method: "PUT",
        body,
      }
    },
    invalidatesTags: ["ProductPayments"],
  }),
  createProductPayment: build.mutation<
    Liga.CreateProductPaymentRes,
    Liga.CreateProductPaymentReq
  >({
    query: ({ pathParams: { payment_category_id, measure }, body }) => {
      return {
        url: `product-payments/${measure}/${payment_category_id}`,
        method: "POST",
        body,
      }
    },
    invalidatesTags: ["ProductPayments"],
  }),
  deleteProductPayment: build.mutation<
    Liga.DeleteProductPaymentRes,
    Liga.DeleteProductPaymentReq
  >({
    query: ({ pathParams: { payment_category_id, measure } }) => {
      return {
        url: `product-payments/${measure}/${payment_category_id}`,
        method: "Delete",
      }
    },
    invalidatesTags: ["ProductPayments"],
  }),
  getAccountintCategories: build.query<
    Liga.GetProductAccountingCategoryRes,
    Liga.GetProductAccountingCategoryReq
  >({
    query: () => ({
      url: `accounting-categories`,
      method: "GET",
    }),
    providesTags: ["AccountingCategories"],
  }),
  getLocationTypes: build.query<
    Liga.GetLocationTypesRes,
    Liga.GetLocationTypesReq
  >({
    query: () => ({
      url: `location-types`,
      method: "GET",
    }),
    providesTags: ["LocationTypes"],
  }),
  getLocations: build.query<Liga.GetLocationsRes, Liga.GetLocationsReq>({
    query: ({ queryParams }) => ({
      url: `locations`,
      method: "GET",
      params: queryParams,
    }),
    providesTags: ["Locations"],
  }),
  getMarketplaceProducts: build.query<
    Liga.GetMarketplaceProductsRes,
    Liga.GetMarketplaceProductsReq
  >({
    query: () => ({
      url: `marketplace-products`,
      method: "GET",
    }),
    providesTags: ["MarketplaceProducts"],
  }),
  updateMarketplaceProduct: build.mutation<
    Liga.UpdateMarketplaceProductRes,
    Liga.UpdateMarketplaceProductReq
  >({
    query: ({ pathParams: { id }, body }) => {
      return {
        url: `marketplace-products/${id}`,
        method: "PUT",
        body,
      }
    },
    invalidatesTags: ["MarketplaceProducts"],
  }),
  getMarketplaceDistricts: build.query<
    Liga.GetMarketplaceDistrictsRes,
    Liga.GetMarketplaceDistrictsReq
  >({
    query: () => ({
      url: `marketplace-districts`,
      method: "GET",
    }),
    providesTags: ["MarketplaceDistricts"],
  }),
  updateMarketplaceDistrict: build.mutation<
    Liga.UpdateMarketplaceDistrictRes,
    Liga.UpdateMarketplaceDistrictReq
  >({
    query: ({ pathParams: { id }, body }) => {
      return {
        url: `marketplace-districts/${id}`,
        method: "PUT",
        body,
      }
    },
    invalidatesTags: ["MarketplaceDistricts"],
  }),
  getMarketplaceCities: build.query<
    Liga.GetMarketplaceCitiesRes,
    Liga.GetMarketplaceCitiesReq
  >({
    query: () => ({
      url: `marketplace-cities`,
      method: "GET",
    }),
    providesTags: ["MarketplaceCities"],
  }),
  updateMarketplaceCities: build.mutation<
    Liga.UpdateMarketplaceCityRes,
    Liga.UpdateMarketplaceCityReq
  >({
    query: ({ pathParams: { id }, body }) => {
      return {
        url: `marketplace-cities/${id}`,
        method: "PUT",
        body,
      }
    },
    invalidatesTags: ["MarketplaceCities"],
  }),
  getCouriers: build.query<Liga.GetCouriersRes, Liga.GetCouriersReq>({
    query: ({ queryParams }) => ({
      url: `couriers`,
      method: "GET",
      params: queryParams,
    }),
    providesTags: ["Staff"],
  }),
  getStaff: build.query<Liga.GetStaffRes, Liga.GetStaffReq>({
    query: ({ queryParams }) => ({
      url: `staff`,
      method: "GET",
      params: queryParams,
    }),
    providesTags: ["Staff"],
  }),
  updateStaff: build.mutation<
    Liga.UpdateStaffRes | Liga.UpdateQuestionnaireRes,
    Liga.UpdateStaffReq | Liga.UpdateQuestionnaireReq
  >({
    query: ({ pathParams: { id }, body }) => {
      return {
        url: `staff/${id}`,
        method: "PUT",
        body,
      }
    },
    invalidatesTags: ["Staff"],
  }),
  getWarehouseStaffList: build.query<
    Liga.GetWarehouseStaffRes,
    Liga.GetWarehouseStaffReq
  >({
    query: ({ queryParams }) => ({
      url: `warehouse-staff-list`,
      method: "GET",
      params: queryParams,
    }),
    providesTags: ["WarehouseStaffList"],
  }),
  getProcurements: build.query<
    Liga.GetProcurementsRes,
    Liga.GetProcurementsReq
  >({
    query: () => ({
      url: `procurements`,
      method: "GET",
    }),
    providesTags: ["Procurements"],
  }),
  updateProcurements: build.mutation<
    Liga.UpdateProcurementsRes,
    Liga.UpdateProcurementsReq
  >({
    query: ({ pathParams: { id }, body }) => {
      return {
        url: `procurements/${id}`,
        method: "PUT",
        body,
      }
    },
    invalidatesTags: ["Procurements"],
  }),
  createProcurements: build.mutation<
    Liga.CreateProcurementsRes,
    Liga.CreateProcurementsReq
  >({
    query: ({ body }) => {
      return {
        url: `procurements`,
        method: "POST",
        body,
      }
    },
    invalidatesTags: ["Procurements"],
  }),
})

export const ligaApi = createApi({
  reducerPath,
  tagTypes,
  baseQuery: (...args) => baseQueryWithReauth(baseQuery, args),
  endpoints: (build) => ({
    ...tgstatEndpoints(build),
  }),
})
dynamicMiddlewaresInstance.addMiddleware(ligaApi.middleware)
registerReducer(ligaApi.reducerPath, ligaApi.reducer)

export const {
  useGetProductsQuery,
  useUpdateProductCategoryMutation,
  useCreateProductPaymentMutation,
  useDeleteProductPaymentMutation,
  useGetProductPaymentsQuery,
  useGetPaymentCategoriesQuery,
  useUpdateProductPaymentMutation,
  useGetAccountintCategoriesQuery,
  useGetMarketplaceProductsQuery,
  useUpdateMarketplaceProductMutation,
  useGetLocationsQuery,
  useGetMarketplaceCitiesQuery,
  useGetMarketplaceDistrictsQuery,
  useUpdateMarketplaceCitiesMutation,
  useUpdateMarketplaceDistrictMutation,
  useGetStaffQuery,
  useUpdateStaffMutation,
  useGetCouriersQuery,
  useGetWarehouseStaffListQuery,
  useGetProcurementsQuery,
  useUpdateProcurementsMutation,
  useCreateProcurementsMutation,
} = ligaApi
