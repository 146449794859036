import { AppBar, Box, IconButton, Toolbar, Typography } from "@mui/material"
import React, { FC } from "react"
import { Link } from "react-router-dom"
import { ThemeToggle } from "."
import { useAppDispatch, useAppSelector, useAuth } from "@app/hooks"
import {
  getIsAppMenuButtonVisible,
  getIsAppMenuOpen,
  setMenuButtonVisible,
  setMenuOpen,
} from "@app/store"
import { Menu } from "@mui/icons-material"

interface IAppHeaderProps {}

export const AppHeader: FC<IAppHeaderProps> = (props) => {
  const dispatch = useAppDispatch()
  const open = useAppSelector(getIsAppMenuOpen)
  const isAuth = !!useAuth().token
  dispatch(setMenuButtonVisible(isAuth))
  const menuButtonIsVisible = useAppSelector(getIsAppMenuButtonVisible)

  return (
    <>
      <AppBar position="sticky" sx={{ zIndex: 1300, mb: 2 }}>
        <Toolbar>
          <Box
            sx={{
              display: "flex",
              flexGrow: 2,
              alignItems: "center",
            }}
          >
            {menuButtonIsVisible && (
              <IconButton
                edge="start"
                color="inherit"
                aria-label="menu"
                sx={{ mr: 2 }}
                onClick={() => dispatch(setMenuOpen(!open))}
              >
                <Menu />
              </IconButton>
            )}
            <Typography
              component={Link}
              to="/"
              variant="subtitle1"
              sx={{
                mr: 2,
                fontFamily: "monospace",
                fontWeight: 700,
                letterSpacing: ".2rem",
                color: "inherit",
                textDecoration: "none",
              }}
            >
              PR ADMIN
            </Typography>
          </Box>
          <Box
            sx={{
              display: "flex",
              flexGrow: 0,
            }}
          >
            <ThemeToggle />
          </Box>
        </Toolbar>
      </AppBar>
    </>
  )
}
