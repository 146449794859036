import { ADV } from "../../interfaces"
import { AutocompleteElement } from "react-hook-form-mui"
import { useTopUpSourceListQuery } from "../../store"

interface IProps {
  required?: boolean
}
interface IField {
  from: ADV.CreateTopUp["from"]
}

export const TopUpSource = <T extends IField>(props: IProps) => {
  const { required } = props
  const { data, isFetching: isLoading } = useTopUpSourceListQuery({})

  return (
    <AutocompleteElement<IField>
      name="from"
      label="Источник"
      loading={isLoading}
      required={required}
      autocompleteProps={{
        fullWidth: true,
        getOptionLabel: (option: ADV.TopUpSource) => option,
      }}
      options={data ?? []}
    />
  )
}
