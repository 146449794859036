import { ADV } from "../../interfaces"
import { KeyboardReturn as KeyboardReturnIcon } from "@mui/icons-material"
import {
  Box,
  Chip,
  IconButton,
  Stack,
  TextField,
  Typography,
} from "@mui/material"
import { useEffect, useRef, useState } from "react"
import { useFormContext, useWatch } from "react-hook-form-mui"

export interface IField {
  contacts: ADV.CreateAgent["contacts"]
}
interface IProps {
  required?: boolean
}

export const Contacts = <T extends IField>({ required }: IProps) => {
  // const watch = useWatch<IField>()
  const ctx = useFormContext<IField>()
  const inputRef = useRef<HTMLInputElement>(null)

  ctx.register("contacts", { required })
  const state = ctx.getFieldState("contacts", ctx.formState)

  const contacts = ctx.watch("contacts")
  const [inputContactValue, setInputContactValue] = useState("")
  const [error, setError] = useState<string | undefined>(undefined)

  useEffect(() => {
    if (state.error) {
      setError(state.error.message || "Это поле обязательное")
      return
    }
    setError(undefined)
  }, [state])

  const handleDelete = (contact: string) => {
    if (typeof contact === "string") {
      const index = contacts?.findIndex((v) => v === contact)
      if (typeof index !== "number" || index < 0) {
        return
      }
      ctx.setValue("contacts", [
        ...(contacts ?? []).slice(0, index),
        ...(contacts ?? []).slice(index + 1),
      ])
    }
  }
  const handleAddContact = () => {
    const newContact = inputContactValue.trim().replace("@", "")

    if (newContact.length === 0) {
      setError("Значение не может быть пустым")
      return
    }
    if (contacts?.includes(newContact)) {
      setError("Этот контакт уже добавлен")
      return
    }
    ctx.setValue("contacts", [...(contacts ?? []), newContact])
    ctx.clearErrors("contacts")
    setInputContactValue("")
    setError(undefined)
    inputRef.current?.focus()
  }

  const handleEnterPress = (ev: React.KeyboardEvent<HTMLDivElement>) => {
    if (ev.key === "Enter") {
      ev.stopPropagation()
      ev.preventDefault()
      handleAddContact()
    }
  }

  return (
    <>
      <Typography variant="body1">Контакты</Typography>
      <Stack sx={{ display: "flex", alignItems: "start" }} my={1}>
        {contacts?.length ? (
          contacts.map((c, index) => (
            <Chip
              key={index}
              label={c}
              onDelete={() => handleDelete(c)}
              sx={(theme) => ({ margin: theme.spacing(0.5) })}
            />
          ))
        ) : (
          <Typography variant="caption">Контакты не добавлены</Typography>
        )}
      </Stack>
      <TextField
        id="contact-input"
        label="Добавить"
        value={inputContactValue}
        fullWidth
        error={!!error}
        helperText={<Typography variant="caption">{error}</Typography>}
        onChange={({
          target: { value },
        }: React.ChangeEvent<HTMLInputElement>) => {
          setInputContactValue(value)
        }}
        InputProps={{
          endAdornment: (
            <IconButton
              color="primary"
              type="button"
              onClick={handleAddContact}
              sx={{ height: "100%" }}
            >
              <KeyboardReturnIcon />
            </IconButton>
          ),
        }}
        inputRef={inputRef}
        onKeyDown={handleEnterPress}
      />
    </>
  )
}
