import { ADV } from "../../interfaces"
import { AutocompleteElement, useFormContext } from "react-hook-form-mui"
import { useStatusesListQuery } from "../../store"

interface IProps extends ADV.TypeBelong {
  required?: boolean
}
interface IField {
  status_id: ADV.Status["id"] | undefined
}

export const Status = <T extends IField>(props: IProps) => {
  const { for: forStatus, required } = props
  const { data, isFetching: isLoading } = useStatusesListQuery({
    queryParams: { for: forStatus },
  })

  return (
    <AutocompleteElement<IField>
      name="status_id"
      label="Статус"
      loading={isLoading}
      matchId
      required={required}
      autocompleteProps={{
        fullWidth: true,
        getOptionLabel: (option: ADV.Status) => option?.name,
      }}
      options={data ?? []}
    />
  )
}
