import { FC, useEffect, useState } from "react"
import { FormContainer, useForm } from "react-hook-form-mui"
import { IFormProps } from "@/core/interfaces"
import {
  Box,
  Button,
  CircularProgress,
  Divider,
  Grid,
  Stack,
} from "@mui/material"
import { SnackbarAlert } from "@/core/components"
import { TgMailing } from "../../../interfaces/mailing"
import { MailingBody } from "./mailingBody"
import { MailingCredentials } from "./mailingCredentials"
import { useCreateMailingMutation } from "../../../store"
import { MethodSelectField } from "../methodSelectField"
import { Name } from "../name"
import { ScheduleDate } from "../scheduleDate"
import { Comment } from "../comment"
import { Type } from "../type"

interface IProps extends IFormProps<TgMailing.CreateMailingRes> {}
export interface IMailingFormData<
  M extends TgMailing.AvailableMethods = TgMailing.AvailableMethods,
> extends Omit<TgMailing.CreateMailingData<M>, "body" | "token" | "messages"> {
  body: Omit<
    TgMailing.CreateMailingData<M>["messages"][number]["body"],
    "chat_id"
  >
  credentials: string
  method: TgMailing.AvailableMethods
}

// const ACTION_TYPE: Record<TgMailing.AvailableMethods, string> = {
//   sendMessage: "Текст",
//   sendChatAction: "Проверка на блокировку",
// }

export const MailingForm: FC<IProps> = (props) => {
  const [createMailing, { isLoading, isError, isSuccess, error }] =
    useCreateMailingMutation()

  const [isSnackbarOpen, setSnackbarOpen] = useState(isError)
  useEffect(() => {
    setSnackbarOpen(isError || isSuccess)
  }, [isError, isSuccess])

  const formContext = useForm<IMailingFormData>({
    defaultValues: {},
  })

  const method = formContext.watch("method")

  const getBodyValues = ({
    credentials,
    method,
    body,
    ...data
  }: IMailingFormData): TgMailing.CreateMailingReq["body"] => ({
    messages: credentials.split(/\s|,|;/g).flatMap((str) => {
      const [token, chatId] = str.trim().split("/")
      return {
        token,
        body: { ...body, chat_id: chatId },
        method: method,
      }
    }),
    ...data,
  })

  const onSubmit = (data: IMailingFormData) => {
    createMailing({
      body: getBodyValues(data),
    })
      .unwrap()
      .then(() => {
        formContext.reset()
      })
  }

  return (
    <FormContainer<IMailingFormData>
      onSuccess={onSubmit}
      formContext={formContext}
    >
      <SnackbarAlert
        isOpen={isSnackbarOpen}
        onClose={() => setSnackbarOpen(false)}
        variant={isError ? "error" : "success"}
        title={isError ? "Произошла ошибка" : "Успешно"}
        message={isError ? JSON.stringify(error) : "Рассылка создана"}
      />
      <Grid container spacing={1}>
        <Grid item xs={12} md={6} xl={4}>
          <Stack spacing={1}>
            <Name<IMailingFormData> required />
            <Type<IMailingFormData> />
            <ScheduleDate<IMailingFormData> />
            <Comment<IMailingFormData> />
          </Stack>
        </Grid>
        <Grid item xs={12} md={6} xl={4}>
          <Stack spacing={1}>
            <MethodSelectField<IMailingFormData> />
            <MailingBody<typeof method, IMailingFormData> />
          </Stack>
        </Grid>
        <Grid item xs={12} md={12} xl={4}>
          <MailingCredentials<IMailingFormData> />
        </Grid>
      </Grid>
      <Divider light sx={{ marginY: 2 }} />
      <Box display="flex" justifyContent="end">
        <Button disabled={isLoading} type="submit" variant="contained">
          Отправить&nbsp;{isLoading && <CircularProgress size={20} />}
        </Button>
      </Box>
    </FormContainer>
  )
}
