import { FC } from "react"
import { DataGrid } from "@core/components"
import { GridColDef, getGridStringOperators } from "@mui/x-data-grid-pro"
import { Liga } from "../interfaces"
import {
  useGetMarketplaceProductsQuery,
  useGetProductsQuery,
  useUpdateMarketplaceProductMutation,
} from "../store"
import { GridCellEditingWrapper } from "@/core/components/dataGrid/gridCellEditingWrapper"
import { ProductSelect } from "."
import {
  isEqualObjects,
  stringOrEmptySortingComparator,
  wrapFilterOperator,
} from "@/core/utils"

interface IProps {}

export const MarketplaceProductsTable: FC<IProps> = (props) => {
  const { data, isFetching } = useGetMarketplaceProductsQuery({})
  const { data: products, isFetching: isProductsLoading } = useGetProductsQuery(
    {},
  )

  const [update, { isLoading: isUpdating }] =
    useUpdateMarketplaceProductMutation()

  const columns: GridColDef<Liga.MarketplaceProduct>[] = [
    {
      headerName: "Площадка",
      field: "marketplace",
      valueGetter: (params) => params.row.shop?.marketplace.name ?? "",
      sortComparator: (v1, v2) =>
        stringOrEmptySortingComparator(v1?.name, v2?.name),
      flex: 2,
    },
    {
      headerName: "Шоп",
      field: "shop",
      valueGetter: ({ value }) => value?.name,
      sortComparator: (v1, v2) =>
        stringOrEmptySortingComparator(v1?.name, v2?.name),
      flex: 2,
    },
    { headerName: "Товар", field: "name", flex: 2 },
    {
      headerName: "Сопоставленный товар",
      field: "liga_product_id",
      editable: true,
      filterOperators: getGridStringOperators().map((f) =>
        wrapFilterOperator<Liga.Product>(
          f,
          (applyFilterFn, params) =>
            applyFilterFn({ ...params, value: params.value?.name }),
          (applyFilterFnV7, value, row, col, apiRef) =>
            applyFilterFnV7(value?.name, row, col, apiRef),
        ),
      ),
      renderEditCell: (params) => (
        <GridCellEditingWrapper<typeof ProductSelect>
          id={params.id}
          field={params.field}
          component={ProductSelect}
          componentProps={{
            defaultValue: params.value?.id ?? null,
          }}
        />
      ),
      valueFormatter: (params) => {
        return params.value?.name
      },
      valueGetter: (params) => {
        return params.row?.product
      },
      valueParser(value, params) {
        return typeof value === "string"
          ? value
          : products?.find((p) => p.id === value)
      },
      valueSetter(params) {
        return {
          ...params.row,
          liga_product_id: params.value?.id ?? null,
          product: params.value,
        }
      },
      sortComparator: (v1, v2) =>
        stringOrEmptySortingComparator(v1?.name, v2?.name),
      flex: 2,
    },
  ]

  const updateMarketplaceProduct = (
    upd: Liga.MarketplaceProduct,
    old: Liga.MarketplaceProduct,
  ) => {
    if (isEqualObjects(upd, old)) {
      return old
    }
    update({
      pathParams: {
        id: upd.id,
      },
      body: {
        liga_product_id: upd.liga_product_id,
      },
    })
    return upd
  }

  return (
    <DataGrid<Liga.MarketplaceProduct>
      columns={columns}
      rows={data ?? []}
      loading={isFetching || isUpdating}
      paginationMode="client"
      editMode="cell"
      processRowUpdate={updateMarketplaceProduct}
      unstable_headerFilters
      disableColumnFilter
      initialState={{
        sorting: {
          sortModel: [{ field: "liga_product_id", sort: "asc" }],
        },
      }}
    />
  )
}
