import { Autocomplete, TextField } from "@mui/material"
import { FC } from "react"
import { useGetPaymentCategoriesQuery } from "../store"
import { Liga } from "../interfaces"

interface IProps {
  onChange: (value: Liga.ProductPaymentCategory | null) => void
  defaultValue?: Liga.ProductPaymentCategory["id"]
}

export const PaymnetCategoriesSelect: FC<IProps> = (props) => {
  const { data, isFetching: isLoading } = useGetPaymentCategoriesQuery({})
  const defaultValue = data?.find((l) => l.id === props.defaultValue) ?? null
  const sortedOptions = data?.toSorted((l, r) => l?.name.localeCompare(r?.name))

  return (
    <Autocomplete<Liga.ProductPaymentCategory>
      fullWidth
      loading={isLoading}
      value={defaultValue}
      isOptionEqualToValue={(option, value) => option.id === value?.id}
      getOptionLabel={(option: Liga.ProductPaymentCategory) => option.name}
      options={sortedOptions ?? []}
      onChange={(e, value) => props.onChange(value ?? null)}
      renderInput={(params) => (
        <TextField {...params} label="Категория оплаты" />
      )}
    />
  )
}
