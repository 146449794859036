import {
  BaseQueryFn,
  QueryStatus,
  createApi,
  fetchBaseQuery,
} from "@reduxjs/toolkit/query/react"
import { EndpointBuilder } from "@reduxjs/toolkit/dist/query/endpointDefinitions"
import { Tgstat } from "../interfaces"
import {
  RootState,
  dynamicMiddlewaresInstance,
  registerReducer,
} from "@core/store"

const tagTypes = ["LinkStatistic", "AuthToken", "LoginUrl"] as const

const reducerPath: string = "tgstatApi"
export const baseTGStatApiURL = new URL(
  import.meta.env.REACT_APP_TGSTAT_API,
  window.location.origin,
).toString()
const baseQuery: BaseQueryFn = fetchBaseQuery({
  baseUrl: `${
    baseTGStatApiURL.endsWith("/") ? baseTGStatApiURL : baseTGStatApiURL + "/"
  }tgstat`,
  prepareHeaders: (headers, { getState }) => {
    // By default, if we have a token in the store, let's use that for authenticated requests
    const token = (getState() as RootState).auth.token
    if (token) {
      headers.set("authorization", `Bearer ${token}`)
    }
    return headers
  },
})

type ApiEndpointBuider = EndpointBuilder<
  typeof baseQuery,
  (typeof tagTypes)[number],
  typeof reducerPath
>

const tgstatEndpoints = (build: ApiEndpointBuider) => ({
  login: build.query<Tgstat.LoginRes, Tgstat.LoginReq>({
    query: () => ({
      url: `login`,
      method: "GET",
      responseHandler: "text",
    }),
    providesTags: ["LoginUrl"],
  }),
  auth: build.query<Tgstat.AuthRes, Tgstat.AuthReq>({
    query: ({ pathParams: { loginKey } }) => ({
      url: `auth/${loginKey}`,
      method: "GET",
      responseHandler: "text",
    }),
  }),
  getLinkStat: build.query<Tgstat.LinkStatisticRes, Tgstat.LinkStatisticReq>({
    query: ({ pathParams: { token }, queryParams }) => ({
      url: `${token}/link-stat`,
      params: queryParams,
      method: "GET",
    }),
    transformResponse: (res: Tgstat.InviteLinkStatisticPage) => {
      const { users, ...values } = res
      return {
        ...values,
        users: users.reduce<Tgstat.InviteLinkStatisticTransformed["users"]>(
          (prev, cur) => {
            const { id, ...values } = cur
            return { ...prev, [+id]: values }
          },
          {},
        ),
      }
    },
    providesTags: (res, err, arg) => [
      {
        type: "LinkStatistic" as const,
        id: `${arg.queryParams.channel}_${arg.queryParams.linkId}`,
      },
    ],
    merge: (currentCache, res, params) => {
      currentCache.users = { ...currentCache.users, ...res.users }
      currentCache.hasMore = res.hasMore
      if (res.hasMore && currentCache.hasMore) {
        currentCache.nextPage = res.nextPage
        currentCache.nextOffset = res.nextOffset
      }
      return currentCache
    },
    serializeQueryArgs: ({ queryArgs, endpointDefinition, endpointName }) => {
      return `${queryArgs.queryParams?.channel}_${queryArgs.queryParams?.linkId}`
    },
    forceRefetch: ({ currentArg, previousArg, endpointState }) => {
      return currentArg?.queryParams !== previousArg?.queryParams
    },
  }),
})

export const tgstatApi = createApi({
  reducerPath,
  tagTypes,
  baseQuery,
  endpoints: (build) => ({
    ...tgstatEndpoints(build),
  }),
})
dynamicMiddlewaresInstance.addMiddleware(tgstatApi.middleware)
registerReducer(tgstatApi.reducerPath, tgstatApi.reducer)

export const {
  useLoginQuery,
  useAuthQuery,
  useGetLinkStatQuery,
  useLazyGetLinkStatQuery,
} = tgstatApi
