import { Autocomplete, TextField } from "@mui/material"
import { FC } from "react"
import { useGetStaffQuery } from "../store"
import { Liga } from "../interfaces"

interface IPropsFreeSolo {
  onChange?: (value: string | null) => void
  defaultValue?: string
  freeSolo: true
}
interface IPropsNotFreeSolo {
  onChange?: (value: Liga.Staff["id"] | null) => void
  defaultValue?: Liga.Staff["id"]
  freeSolo?: false
}
type IProps = {
  queryParams?: Liga.GetCouriersReq["queryParams"]
  freeSolo?: boolean
  disableClearable?: boolean
} & (IPropsFreeSolo | IPropsNotFreeSolo)

export const StaffSelect: FC<IProps> = (props) => {
  const { freeSolo, disableClearable, onChange } = props
  const { data, isFetching: isLoading } = useGetStaffQuery({
    queryParams: { active: true, ...props.queryParams },
  })
  const defaultValue =
    data?.find((l) => (freeSolo ? l.name : l.id) === props.defaultValue) ??
    (typeof props.defaultValue === "string" ? props.defaultValue : null) ??
    null

  return (
    <Autocomplete<Liga.Staff, false, typeof disableClearable, typeof freeSolo>
      fullWidth
      freeSolo={!!freeSolo}
      autoSelect={!!freeSolo}
      disableClearable={!!disableClearable}
      value={defaultValue ?? null}
      loading={isLoading}
      isOptionEqualToValue={(option, value) =>
        freeSolo === true ? option === value : option.id === value?.id
      }
      getOptionLabel={(option: Liga.Staff | string) =>
        typeof option === "string" ? option : option.name
      }
      options={data ?? []}
      onChange={(e, value) => {
        if (typeof onChange === "function") {
          freeSolo === true
            ? onChange(typeof value === "string" ? value : value?.name ?? null)
            : onChange(!!value && typeof value === "object" ? value.id : null)
        }
      }}
      renderInput={(params) => <TextField {...params} label="Сотрудник" />}
    />
  )
}
