import { FC } from "react"
import {
  getAdvState,
  setOrdersPaginationModel,
  useOrdersListQuery,
} from "../../store"
import { DataGrid, PreviousLocation, RouterLink } from "@/core/components"
import { ADV } from "../../interfaces"
import {
  GridActionsCellItem,
  GridColDef,
  getGridStringOperators,
} from "@mui/x-data-grid-pro"
import { Link } from "@mui/material"
import parseISO from "date-fns/parseISO/index.js"
import { formatDate, wrapFilterOperator } from "@/core/utils"
import { formatTGLink } from "../utils/string"
import { GRID_PAGE_SIZE_OPTIONS } from "@/core/constants"
import { useAppDispatch, useAppSelector } from "@/modules/app/hooks"
import { Preview as PreviewIcon } from "@mui/icons-material"
import { useLocation, useNavigate } from "@/core/hooks"

interface IProps {
  agents?: ADV.Agent["id"]
}
export const OrdersTable: FC<IProps> = ({ agents }) => {
  const navigate = useNavigate<PreviousLocation>()
  const { state } = useLocation<PreviousLocation>()
  const dispatch = useAppDispatch()
  const {
    orders: { page, pageSize },
    ordersFilters,
  } = useAppSelector(getAdvState)
  const { isFetching, data } = useOrdersListQuery({
    queryParams: {
      page: page + 1,
      limit: pageSize,
      ...(agents !== undefined ? { agents } : ordersFilters),
    },
  })

  const columns: GridColDef<ADV.Order>[] = [
    {
      headerName: "ID",
      field: "id",
      flex: 1,
    },
    {
      headerName: "Тип",
      field: "type_name",
      flex: 1,
    },
    {
      headerName: "Кампания",
      field: "campaign_name",
      flex: 1,
    },
    {
      headerName: "Статус",
      field: "status_name",
      flex: 1,
    },
    {
      headerName: "Дата начала",
      field: "date_start",
      valueGetter: ({ value }) => value && parseISO(value),
      valueFormatter: ({ value }) => formatDate(value),
      type: "dateTime",
      flex: 1,
    },
    {
      headerName: "Дата окончания",
      field: "date_finish",
      valueGetter: ({ value }) => value && parseISO(value),
      valueFormatter: ({ value }) => formatDate(value),
      type: "dateTime",
      flex: 1,
    },
    {
      headerName: "Контрагент",
      field: "agent.name",
      valueGetter: ({ row }) => row.agent,
      renderCell: ({ value }) => (
        <RouterLink<PreviousLocation>
          color="secondary"
          title={`${value.name}`}
          to={`../agents/${value.id}`}
          state={{
            from: window.location.pathname,
            title: "К списку заказов",
          }}
        />
      ),
      filterOperators: getGridStringOperators().map((f) =>
        wrapFilterOperator<NonNullable<ADV.Agent["contacts"]>>(
          f,
          (applyFilterFn, params) =>
            applyFilterFn({
              ...params,
              value: params.value?.map((v: ADV.Agent) => v.name).join(),
            }),
          (applyFilterFnV7, value, row, col, apiRef) =>
            applyFilterFnV7(value?.join(), row, col, apiRef),
        ),
      ),
      getApplyQuickFilterFn: (value, col) => {
        return (params) =>
          params.value?.some(
            (v: any) =>
              v
                ?.toString()
                .toLowerCase()
                .includes(value?.name.toLowerCase()),
          )
      },
      flex: 1,
    },
    {
      headerName: "Ссылка",
      field: "agent.url",
      valueGetter: ({ row }) => row.agent.url,
      renderCell: ({ value }) => (
        <Link
          color="secondary"
          href={formatTGLink(value)}
          target="_blank"
          referrerPolicy="no-referrer"
        >
          {value}
        </Link>
      ),
      flex: 1,
    },
    {
      headerName: "Реф. коды",
      field: "ref_codes",
      flex: 1,
    },
  ]

  return (
    <DataGrid<ADV.Order>
      columns={columns}
      rows={data?.data ?? []}
      rowCount={data?.count ?? 0}
      loading={isFetching}
      // unstable_headerFilters
      filterMode="client"
      sortingMode="client"
      pagination
      paginationMode="server"
      pageSizeOptions={GRID_PAGE_SIZE_OPTIONS}
      onPaginationModelChange={(model) =>
        dispatch(setOrdersPaginationModel(model))
      }
      initialState={{
        sorting: {
          sortModel: [{ field: "id", sort: "desc" }],
        },
        pagination: { paginationModel: { page, pageSize } },
      }}
      rowActions={[
        (r) => (
          <GridActionsCellItem
            label="Открыть"
            icon={<PreviewIcon />}
            onClick={() =>
              navigate(r.id.toString(), {
                state: {
                  from: window.location.pathname,
                  title: "К списку заказов",
                },
              })
            }
          />
        ),
      ]}
      // slots={{ toolbar: QuickSearchToolbar }}
    />
  )
}
