import { Api } from "@/core/interfaces"

export namespace Metabase {
  export enum EmbedType {
    dashboard = "dashboard",
    chart = "question",
  }
  export type EmbedIFrame = { url: string; exp: number }

  export type EmbedStyles = {
    theme?: "night" | "transparent" | null
    bordered?: boolean
    titled?: boolean
  }
  export type EmbedQueryParams = {
    [param in string]: any
  } & EmbedStyles

  export type GetMetabaseEmbedUrlReq = Api.Request<{
    pathParams: { type: EmbedType; id: number }
    queryParams?: EmbedQueryParams
  }>
  export type GetMetabaseEmbedUrlRes = EmbedIFrame
}
