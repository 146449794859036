import { str2ab } from "."

export const encrypt = async (
  data: ArrayBuffer,
  publicKey: string,
): Promise<ArrayBuffer> => {
  const key = await crypto.subtle.importKey(
    "spki",
    str2ab(atob(publicKey)),
    {
      name: "RSA-OAEP",
      hash: { name: "SHA-256" },
    },
    false,
    ["encrypt"],
  )
  const encrypted = await crypto.subtle.encrypt({ name: "RSA-OAEP" }, key, data)
  return encrypted
}
