import { PayloadAction, createSlice } from "@reduxjs/toolkit"
import { RootState, StateFromRoot, registerReducer, store } from "@core/store"
import { GridPaginationModel } from "@mui/x-data-grid-pro"
import { GRID_PAGE_SIZE_OPTIONS } from "@/core/constants"
import { ADV } from "../interfaces"

const STATE_KEY = "adv2"
export const CONFIG_KEY = `${STATE_KEY}_state`

type AdvTableName = "orders" | "agents" | "payments"
const defaultTableState: GridPaginationModel = {
  page: 0,
  pageSize: GRID_PAGE_SIZE_OPTIONS[2],
}

type IState = {
  [table in AdvTableName]: GridPaginationModel
} & {
  ordersFilters: ADV.Filters
  agentsFilters: ADV.Filters
  paymentsFilters: ADV.Filters
}

const persistantState = localStorage.getItem(CONFIG_KEY)
const initialState: IState = {
  ...(persistantState && JSON.parse(persistantState)),
  ...({
    orders: defaultTableState,
    agents: defaultTableState,
    payments: defaultTableState,
    ordersFilters: {},
    agentsFilters: {},
    paymentsFilters: {},
  } satisfies IState),
}

export const advSlice = createSlice({
  name: "adv",
  initialState: initialState,
  reducers: {
    setOrdersPaginationModel: (
      state,
      { payload: model }: PayloadAction<GridPaginationModel>,
    ) => {
      state.orders = model
    },
    setOrdersFilters: (
      state,
      { payload: filters }: PayloadAction<ADV.Filters>,
    ) => {
      state.ordersFilters = filters
    },
    setAgentsPaginationModel: (
      state,
      { payload: model }: PayloadAction<GridPaginationModel>,
    ) => {
      state.agents = model
    },
    setAgentsFilters: (
      state,
      { payload: filters }: PayloadAction<ADV.Filters>,
    ) => {
      state.agentsFilters = filters
    },
    setPaymentsPaginationModel: (
      state,
      { payload: model }: PayloadAction<GridPaginationModel>,
    ) => {
      state.payments = model
    },
    setPaymentsFilters: (
      state,
      { payload: filters }: PayloadAction<ADV.Filters>,
    ) => {
      state.paymentsFilters = filters
    },
  },
})
registerReducer(advSlice.name, advSlice.reducer)
store.subscribe(() => {
  localStorage.setItem(CONFIG_KEY, JSON.stringify(store.getState().adv))
})

export const {
  setOrdersPaginationModel,
  setOrdersFilters,
  setAgentsPaginationModel,
  setAgentsFilters,
  setPaymentsPaginationModel,
  setPaymentsFilters,
} = advSlice.actions

export const getAdvState = (state: RootState<StateFromRoot<IState>>) =>
  state.adv
