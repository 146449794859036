import { FC, useState } from "react"
import { Box, Button, Collapse, Container, Typography } from "@mui/material"
import { CreateOrderForm, OrdersTable } from "../components"
import { OrderFilters } from "../components"
import { useTitle } from "@/core/hooks"

interface IProps {}

export const OrdersPage: FC<IProps> = (props) => {
  const [expanded, setExpanded] = useState(false)
  useTitle(`PR ADV - Заказы`)

  return (
    <Container maxWidth={false}>
      <Box mb={3}>
        <Box mb={1} sx={{ display: "flex", flexDirection: "row-reverse" }}>
          <Button onClick={() => setExpanded(!expanded)}>
            {expanded ? "Отмена" : "Создать новый заказ"}
          </Button>
        </Box>
        <Collapse in={expanded}>
          <Typography variant="h5">Создание нового заказа</Typography>
          <CreateOrderForm />
        </Collapse>
      </Box>
      <Box>
        <Typography variant="h5">Заказы</Typography>
        <OrderFilters />
        <Box height="85vh">
          <OrdersTable />
        </Box>
      </Box>
    </Container>
  )
}
