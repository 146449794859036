import { FC } from "react"
import { AutocompleteElement } from "react-hook-form-mui"
import { useMailingTypesQuery } from "../../store"

interface IProps {
  required?: boolean
}
interface IField {
  typeId?: number
}

export const Type = <T extends IField>({ required }: IProps) => {
  const { data, isLoading } = useMailingTypesQuery({})

  return (
    <AutocompleteElement<IField>
      name="typeId"
      label="Тип"
      required={required}
      options={data ?? []}
      loading={isLoading}
      autocompleteProps={{
        getOptionLabel: (option) => option.name,
        getOptionKey: (option) => option.id,
      }}
      matchId
    />
  )
}
