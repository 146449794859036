import {
  BaseQueryFn,
  createApi,
  fetchBaseQuery,
} from "@reduxjs/toolkit/query/react"
import { EndpointBuilder } from "@reduxjs/toolkit/dist/query/endpointDefinitions"
import {
  RootState,
  dynamicMiddlewaresInstance,
  registerReducer,
} from "@core/store"
import { baseQueryWithReauth } from "@/modules/app/store"
import { Metabase, PR } from "../interfaces"

const tagTypes = ["UsefulLinks", "MetabaseEmbed"] as const

const reducerPath: string = "prApi"
export const baseTGStatApiURL = new URL(
  import.meta.env.REACT_APP_TGSTAT_API,
  window.location.origin,
).toString()
const baseQuery: BaseQueryFn = fetchBaseQuery({
  baseUrl: `${
    baseTGStatApiURL.endsWith("/") ? baseTGStatApiURL : baseTGStatApiURL + "/"
  }`,
  prepareHeaders: (headers, { getState }) => {
    const token = (getState() as RootState).auth.token
    if (token) {
      headers.set("authorization", `Bearer ${token}`)
    }
    return headers
  },
})

type ApiEndpointBuider = EndpointBuilder<
  typeof baseQuery,
  (typeof tagTypes)[number],
  typeof reducerPath
>

const endpoints = (build: ApiEndpointBuider) => ({
  getUsefulLinks: build.query<PR.GetUsefulLinksRes, PR.GetUsefulLinksReq>({
    query: () => ({
      url: `pr/useful-links`,
      method: "GET",
    }),
    providesTags: ["UsefulLinks"],
  }),
  metabaseEmbed: build.query<
    Metabase.GetMetabaseEmbedUrlRes,
    Metabase.GetMetabaseEmbedUrlReq
  >({
    query: ({ pathParams: { type, id }, queryParams }) => ({
      url: `metabase/embed/${type}/${id}`,
      method: "GET",
      params: queryParams,
    }),
    providesTags: ["MetabaseEmbed"],
  }),
})

export const prApi = createApi({
  reducerPath,
  tagTypes,
  baseQuery: (...args) => baseQueryWithReauth(baseQuery, args),
  endpoints: (build) => ({
    ...endpoints(build),
  }),
})
dynamicMiddlewaresInstance.addMiddleware(prApi.middleware)
registerReducer(prApi.reducerPath, prApi.reducer)

export const { useGetUsefulLinksQuery, useMetabaseEmbedQuery } = prApi
