import {
  BaseQueryFn,
  createApi,
  fetchBaseQuery,
} from "@reduxjs/toolkit/query/react"
import { EndpointBuilder } from "@reduxjs/toolkit/dist/query/endpointDefinitions"
import {
  RootState,
  dynamicMiddlewaresInstance,
  registerReducer,
} from "@core/store"
import { baseQueryWithReauth } from "@app/store"
import { Tgstat } from "../interfaces"

const tagTypes = ["BotUsers"] as const

const reducerPath: string = "advStatApi"
export const baseAdvApiURL = new URL(
  import.meta.env.REACT_APP_ADV_API,
  window.location.origin,
).toString()
const baseQuery: BaseQueryFn = fetchBaseQuery({
  baseUrl: `${
    baseAdvApiURL.endsWith("/") ? baseAdvApiURL : baseAdvApiURL + "/"
  }`,
  prepareHeaders: (headers, { getState }) => {
    // By default, if we have a token in the store, let's use that for authenticated requests
    const token = (getState() as RootState).auth.token
    if (token) {
      headers.set("authorization", `Bearer ${token}`)
    }
    return headers
  },
})

type ApiEndpointBuider = EndpointBuilder<
  typeof baseQuery,
  (typeof tagTypes)[number],
  typeof reducerPath
>

const advEndpoints = (build: ApiEndpointBuider) => ({
  getBotUsers: build.query<Tgstat.BotUsersRes, Tgstat.BotUsersReq>({
    query: ({ queryParams }) => ({
      url: `customers/buy_adv`,
      params: queryParams,
      method: "GET",
    }),
    providesTags: ["BotUsers"],
    transformResponse: (res: Tgstat.BotUsers) => {
      return Object.values(res).reduce<Tgstat.BotUsersRes>(
        (prev, cur: Tgstat.BotUsers[number]) => {
          if (cur.telegram_id) {
            prev[cur.telegram_id] = {
              createdAt: cur.created_at,
              completedOrdersCount: cur.completed_orders_count,
              completedOrdersSum: cur.completed_orders_sum,
              confirmedOrdersCount: cur.confirmed_orders_count,
              confirmedOrdersSum: cur.confirmed_orders_sum,
              otherOrdersCount: cur.other_orders_count,
              otherOrdersSum: cur.other_orders_sum,
            }
          }
          return prev
        },
        {},
      )
    },
  }),
})

export const advStatApi = createApi({
  reducerPath,
  tagTypes,
  baseQuery: (...args) => baseQueryWithReauth(baseQuery, args),
  endpoints: (build) => ({
    ...advEndpoints(build),
  }),
})
dynamicMiddlewaresInstance.addMiddleware(advStatApi.middleware)
registerReducer(advStatApi.reducerPath, advStatApi.reducer)

export const { useGetBotUsersQuery } = advStatApi
