import { FC, useState } from "react"
import { Box, Button, Collapse, Container, Typography } from "@mui/material"
import { AgentsTable, CreateAgentForm } from "../components"
import { useTitle } from "@/core/hooks"
import { AgentFilters } from "../components/filters/agentFilters"

interface IProps {}

export const AgentsPage: FC<IProps> = (props) => {
  const [expanded, setExpanded] = useState(false)
  useTitle(`PR ADV - Контрагенты`)

  return (
    <Container maxWidth={false}>
      <Box mb={3}>
        <Box mb={1} sx={{ display: "flex", flexDirection: "row-reverse" }}>
          <Button onClick={() => setExpanded(!expanded)}>
            {expanded ? "Отмена" : "Создать контрагента"}
          </Button>
        </Box>
        <Collapse in={expanded}>
          <Typography variant="h5">Создание нового контрагента</Typography>
          <CreateAgentForm />
        </Collapse>
      </Box>
      <Box mt={5}>
        <Typography variant="h5">Контрагенты</Typography>
        <AgentFilters />
        <Box height="85vh">
          <AgentsTable />
        </Box>
      </Box>
    </Container>
  )
}
