import { FC, useEffect, useMemo, useState } from "react"
import { Tgstat } from "../interfaces"
import { DataGrid } from "@/core/components"
import { GridColDef } from "@mui/x-data-grid-pro"
import { Box, Divider, Link, Typography } from "@mui/material"
import { Check, Close } from "@mui/icons-material"
import { formatDate } from "@/core/utils"
import { useAppSelector } from "@/modules/app/hooks"
import { getTGStatState } from "../store"

interface IProps {
  rows: Tgstat.UsersIntersection[]
}

const UserCell = ({ link, id, name }: Tgstat.UsersIntersection["user"]) => {
  return (
    <Box display="flex" flexDirection="column">
      <Link color="secondary" href={link}>
        {name}
      </Link>
      <Typography variant="caption" color={(theme) => theme.palette.grey[600]}>
        id: {id}
      </Typography>
    </Box>
  )
}
const InviteLinkCell = (
  props: Tgstat.UsersIntersection["l1" | "l2"] | undefined,
) => {
  return (
    <Box display="flex" flexDirection="row" alignItems="center" gap={1}>
      {props?.joinDate ? <Check color="success" /> : <Close color="error" />}
      {!!props?.joinDate && (
        <>
          <Divider orientation="vertical" flexItem />
          <Box display="flex" flexDirection="column">
            {props?.joinDate && (
              <Typography
                color={(theme) => theme.palette.grey[600]}
                variant="caption"
              >
                Присоединился: {formatDate(props?.joinDate)}
              </Typography>
            )}
            {props?.leftDate && (
              <Typography
                color={(theme) => theme.palette.grey[600]}
                variant="caption"
              >
                Покинул: {formatDate(props?.leftDate)}
              </Typography>
            )}
          </Box>
        </>
      )}
    </Box>
  )
}
const BotCell = (props: Tgstat.UsersIntersection["bot"] | undefined) => {
  return (
    <Box display="flex" flexDirection="row">
      {props?.createdAt ? <Check color="success" /> : <Close color="error" />}
      {!!props?.createdAt && (
        <>
          <Divider orientation="vertical" flexItem />
          <Box>
            <Typography variant="caption">
              Завершенных заказов: {props?.completedOrdersCount}
            </Typography>
            <Typography variant="caption">
              На сумму: {props?.completedOrdersSum}
            </Typography>
          </Box>
        </>
      )}
    </Box>
  )
}

export const LinksCompareTable: FC<IProps> = ({ rows }) => {
  const state = useAppSelector(getTGStatState)

  const columns = useMemo<GridColDef<Tgstat.UsersIntersection>[]>(
    () => [
      {
        headerName: "Участник",
        field: "user",
        flex: 1,
        renderCell: (params) =>
          params.row["user"] ? <UserCell {...params.row["user"]} /> : null,
        sortComparator: (
          v1: Tgstat.UsersIntersection["user"],
          v2: Tgstat.UsersIntersection["user"],
          p1,
          p2,
        ) => v2?.name.localeCompare(v1?.name),
      },
      {
        headerName: rows.at(0)?.l1.channel,
        field: "l1",
        flex: 1,
        renderCell: (params) => <InviteLinkCell {...params.row["l1"]} />,
        sortComparator: (
          v1: Tgstat.UsersIntersection["l1"],
          v2: Tgstat.UsersIntersection["l2"],
          p1,
          p2,
        ) =>
          new Date(v2?.joinDate ?? 0).valueOf() -
          new Date(v1?.joinDate ?? 0).valueOf(),
      },
      {
        headerName: rows.at(0)?.l1.channel,
        field: "l2",
        flex: 1,
        renderCell: (params) =>
          params.row["l2"] ? <InviteLinkCell {...params.row["l2"]} /> : null,
        sortComparator: (
          v1: Tgstat.UsersIntersection["l1"],
          v2: Tgstat.UsersIntersection["l2"],
          p1,
          p2,
        ) =>
          new Date(v2?.joinDate ?? 0).valueOf() -
          new Date(v1?.joinDate ?? 0).valueOf(),
      },
      {
        headerName: `Бот ${state.refCode}`,
        field: "bot",
        flex: 1,
        renderCell: (params) =>
          params.row["bot"] ? <BotCell {...params.row["bot"]} /> : null,
        sortComparator: (
          v1: Tgstat.UsersIntersection["bot"],
          v2: Tgstat.UsersIntersection["bot"],
          p1,
          p2,
        ) =>
          new Date(v2?.createdAt ?? 0).valueOf() -
          new Date(v1?.createdAt ?? 0).valueOf(),
      },
    ],
    [rows],
  )

  return (
    <DataGrid
      columns={columns}
      rows={rows}
      getRowId={(row) => row.user.id}
      initialState={{
        sorting: {
          sortModel: [
            { field: "l2", sort: "asc" },
            { field: "bot", sort: "asc" },
          ],
        },
      }}
    />
  )
}
