import { ADV } from "../../interfaces"
import { AutocompleteElement, FieldValues, Path } from "react-hook-form-mui"
import { ListboxComponent } from "@core/components"
import { useRefCodesListQuery } from "../../store"

interface IProps<T extends FieldValues> {
  name: Path<T>
  required?: boolean
}

type IField = {
  ref_code_id?: ADV.RefCode["id"]
}

export const RefCode = <T extends FieldValues = IField>(
  props: IProps<T>,
): JSX.Element => {
  const { required, name } = props
  const { data, isFetching: isLoading } = useRefCodesListQuery({})

  return (
    <AutocompleteElement<T>
      name={name}
      label="Реф. код"
      loading={isLoading}
      matchId
      autocompleteProps={{
        fullWidth: true,
        getOptionLabel: (option: ADV.RefCode) => option.name,
        ListboxComponent: ListboxComponent,
        renderOption: (props, option, state) =>
          [props, option?.name, state] as React.ReactNode,
      }}
      required={required}
      options={data ?? []}
    />
  )
}
