import { FC } from "react"
import { DataGrid } from "@/core/components"
import { ADV } from "../../interfaces"
import { GridColDef } from "@mui/x-data-grid-pro"
import { useTopUpListQuery } from "../../store"
import { parseISO } from "date-fns"
import { formatDate } from "@/core/utils"

interface IProps {}
export const TopUpTable: FC<IProps> = (props) => {
  const { isFetching, data } = useTopUpListQuery({})

  const columns: GridColDef<ADV.TopUpListRes[number]>[] = [
    {
      headerName: "ID",
      field: "id",
      flex: 1,
    },
    {
      headerName: "Дата",
      field: "date",
      valueGetter: ({ value }) => value && parseISO(value),
      valueFormatter: ({ value }) => formatDate(value),
      flex: 3,
    },
    {
      headerName: "Монета",
      field: "type_name",
      flex: 3,
    },
    {
      headerName: "Кол-во монет",
      field: "sum",
      flex: 2,
    },
  ]

  return (
    <DataGrid<ADV.TopUpListRes[number]>
      columns={columns}
      rows={data ?? []}
      loading={isFetching}
      filterMode="client"
      sortingMode="client"
    />
  )
}
