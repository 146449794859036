import { format, formatDistanceToNow, lightFormat } from "date-fns"
import { ru } from "date-fns/locale"

export const formatDateDistanceToNow = (
  date?: Date | number | string,
): string => {
  if (!date) {
    return ""
  }
  if (typeof date === "string") {
    date = new Date(date)
  }
  return formatDistanceToNow(date, { locale: ru, addSuffix: true })
}
export const formatDateAndTime = (date?: Date | number | string): string => {
  if (!date) {
    return ""
  }
  if (typeof date === "string") {
    date = new Date(date)
  }
  return format(date, "dd MMMM yyyy HH:mm:ss", { locale: ru })
}
export const formatDate = (date?: Date | number | string): string => {
  if (!date) {
    return ""
  }
  if (typeof date === "string") {
    date = new Date(date)
  }
  return format(date, "dd MMMM yyyy", { locale: ru })
}
