import { Api } from "@/core/interfaces"

export namespace Liga {
  export interface Marketplace {
    id: number
    name: string
    short_name: string
    name_variations: string[]
  }

  export interface MarketplaceShop {
    id: number
    name: string
    short_name: string
    marketplace_id: number
    marketplace: Marketplace
  }

  export interface MarketplaceProduct {
    id: number
    name: string
    liga_product_id: number | null
    product: Product
    shop_id: number
    shop: MarketplaceShop
    updated_at: Date
  }

  export interface MarketplaceCity {
    id: number
    name: string
    liga_city_id: number | null
    location: Location
    shop_id: number
    shop: MarketplaceShop
    updated_at: Date
  }

  export interface MarketplaceDistrict {
    id: number
    name: string
    liga_district_id: number | null
    location: Location
    city_id: number
    city: MarketplaceCity
    updated_at: Date
  }

  export interface Product {
    id: number
    name: string
    category_id: Category["id"]
    category: Category
    payment_category_id: ProductPaymentCategory["id"]
    payment_category: ProductPaymentCategory
    accounting_category_id: ProductAccountingCategory["id"]
    accounting_category: ProductAccountingCategory
    discount: string
    updated_at: Date
    deleted_at: Date
  }

  export interface Category {
    id: number
    name: string
    parent_id?: Category["id"]
    parent?: Category
    updated_at: Date
    deleted_at: Date
  }

  export interface ProductPaymentCategory {
    id: number
    name: string
  }

  export interface ProductAccountingCategory {
    id: number
    name: string
  }

  export interface ProductPayment {
    measure: number
    payment: number
    payment_category_id: ProductPaymentCategory["id"]
    payment_category: ProductPaymentCategory
  }
  export type ProductPaymentsGroupedByCategory = Record<
    ProductPayment["payment_category_id"],
    ProductPayment["payment"]
  >
  export type ProductPaymentsGroupedByMeasure = Record<
    ProductPayment["measure"],
    ProductPaymentsGroupedByCategory & {
      id: ProductPayment["payment_category_id"]
      measure: ProductPayment["measure"]
    }
  >

  export type LocationType = string

  export interface Location {
    id: number
    name: string
    type: LocationType
    parent_id: number | null
    parent?: Location | null
    updated_at: Date
    deleted_at: Date | null
  }

  export enum StaffRoles {
    COURIER = "Курьеры",
    OPERATOR_JR = "Младшие операторы",
    CITY_MANAGER = "Управляющий по городу",
    MANAGER = "Управляющие",
    OPERATOR_AVG = "Средний оператор",
    OPERATOR_SN = "Старшие операторы",
  }
  export interface Staff {
    id: number
    name: string
    role: StaffRoles
    note: string | null
    contact: string | null
    characteristic: string | null
    questionnaire: string | null
    city: string | null
    district: string | null
    active: boolean
    is_warehouse_staff: boolean
    warehouse_staff_id: number | null
    warehouse_staff: Staff | null
    is_franchisee: boolean
    moderate: boolean | null
    created_at: Date
    updated_at: Date
    deleted_at: Date | null
  }
  export interface Courier extends Staff {
    role: StaffRoles.COURIER
  }
  export type WarehouseStaffListItem = Pick<Staff, "id" | "name">

  export enum ProcurementStatus {
    NOT_FOUND = "Не найден",
    ISSUE = "Выдан",
    PICKED_UP = "Забрали",
  }
  export enum ProcurementType {
    PREPAID_EXPENSE = "Аванс",
    WAGE = "ЗП",
    BYING_MASTER_STAFF = "Покупка МК",
    ACCOUNTING = "Расчет",
    TOP_UP = "Пополнение",
  }
  export interface Procurement {
    id: number | string
    curator_id?: number
    curator?: Staff
    worker_username?: string
    city_id?: number
    city?: Location
    shop?: string
    site?: string
    product_id?: number
    product?: Product
    count?: number
    sum: string
    status: ProcurementStatus
    type: ProcurementType
    write_off?: string
    comment: string
  }

  export type GetProductsReq = Api.Request
  export type GetProductsRes = Liga.Product[]
  export type UpdateProductPaymentReq = Api.Request<{
    pathParams: {
      payment_category_id: ProductPayment["payment_category_id"]
      measure: ProductPayment["measure"]
    }
    body: Pick<ProductPayment, "payment">
  }>
  export type UpdateProductPaymentRes = Pick<
    Liga.ProductPayment,
    "measure" | "payment_category_id"
  >
  export type CreateProductPaymentReq = Api.Request<{
    pathParams: {
      payment_category_id: ProductPayment["payment_category_id"]
      measure: ProductPayment["measure"]
    }
    body: Pick<ProductPayment, "payment">
  }>
  export type CreateProductPaymentRes = Pick<
    Liga.ProductPayment,
    "measure" | "payment_category_id"
  >
  export type DeleteProductPaymentReq = Api.Request<{
    pathParams: {
      payment_category_id: ProductPayment["payment_category_id"]
      measure: ProductPayment["measure"]
    }
  }>
  export type DeleteProductPaymentRes = Pick<
    Liga.ProductPayment,
    "measure" | "payment_category_id"
  >

  export type GetProductPaymentsReq = Api.Request
  export type GetProductPaymentsRes = Liga.ProductPayment[]
  // export type UpdateMarketplaceProductPaymentReq = Api.Request<{
  //   pathParams: { id: number }
  //   body: Pick<ProductPayment, "measure" | "payment">
  // }>
  // export type UpdateMarketplaceProductPaymentRes = Pick<
  //   Liga.ProductPayment,
  //   "id"
  // >

  export type GetProductAccountingCategoryReq = Api.Request
  export type GetProductAccountingCategoryRes = Liga.ProductAccountingCategory[]

  export type GetPaymentCategoriesReq = Api.Request
  export type GetPaymentCategoriesRes = Liga.ProductPaymentCategory[]
  export type UpdateProductCategoriesReq = Api.Request<{
    pathParams: { id: number }
    body: Pick<Product, "accounting_category_id" | "payment_category_id">
  }>
  export type UpdateProductCategoriesRes = Pick<
    Liga.ProductPaymentCategory,
    "id"
  >

  export type GetLocationsReq = Api.Request<{
    queryParams?: { type?: LocationType }
  }>
  export type GetLocationsRes = Liga.Location[]

  export type GetLocationTypesReq = Api.Request
  export type GetLocationTypesRes = Liga.LocationType[]

  export type GetMarketplaceProductsReq = Api.Request
  export type GetMarketplaceProductsRes = Liga.MarketplaceProduct[]
  export type UpdateMarketplaceProductReq = Api.Request<{
    pathParams: { id: number }
    body: Pick<MarketplaceProduct, "liga_product_id">
  }>
  export type UpdateMarketplaceProductRes = Pick<Liga.MarketplaceProduct, "id">

  export type GetMarketplaceDistrictsReq = Api.Request
  export type GetMarketplaceDistrictsRes = Liga.MarketplaceDistrict[]
  export type UpdateMarketplaceDistrictReq = Api.Request<{
    pathParams: { id: number }
    body: Pick<MarketplaceDistrict, "liga_district_id">
  }>
  export type UpdateMarketplaceDistrictRes = Pick<
    Liga.MarketplaceDistrict,
    "id"
  >

  export type GetMarketplaceCitiesReq = Api.Request
  export type GetMarketplaceCitiesRes = Liga.MarketplaceCity[]
  export type UpdateMarketplaceCityReq = Api.Request<{
    pathParams: { id: number }
    body: Pick<MarketplaceCity, "liga_city_id">
  }>
  export type UpdateMarketplaceCityRes = Pick<Liga.MarketplaceCity, "id">

  export type GetStaffReq = Api.Request<{
    queryParams?: { active?: boolean }
  }>
  export type GetStaffRes = Liga.Staff[]
  export type UpdateStaffReq = Api.Request<{
    pathParams: {
      id: Staff["id"]
    }
    body: Pick<
      Staff,
      "is_warehouse_staff" | "is_franchisee" | "warehouse_staff_id" | "contact"
    >
  }>
  export type UpdateStaffRes = Pick<Liga.Staff, "id">
  export type UpdateQuestionnaireReq = Api.Request<{
    pathParams: {
      id: Staff["id"]
    }
    body: Pick<Staff, "characteristic" | "questionnaire" | "city" | "district">
  }>
  export type UpdateQuestionnaireRes = Pick<Liga.Staff, "id">
  export type GetCouriersReq = Api.Request<{
    queryParams?: { active?: boolean; role?: StaffRoles }
  }>
  export type GetCouriersRes = Liga.Courier[]
  export type GetWarehouseStaffReq = Api.Request<{
    queryParams?: { active?: boolean }
  }>
  export type GetWarehouseStaffRes = Liga.WarehouseStaffListItem[]

  export type GetProcurementsReq = Api.Request
  export type GetProcurementsRes = Liga.Procurement[]
  export type UpdateProcurementsReq = Api.Request<{
    pathParams: {
      id: Procurement["id"]
    }
    body: Omit<Procurement, "id">
  }>
  export type UpdateProcurementsRes = Pick<Liga.Procurement, "id">
  export type CreateProcurementsReq = Api.Request<{
    body: Omit<Procurement, "id">
  }>
  export type CreateProcurementsRes = Pick<Liga.Procurement, "id">
}
