import { TgMailing } from "@/modules/telegram/interfaces"

export function getMediaFieldNameByMethod<M extends TgMailing.AvailableMethods>(
  method: M,
): "animation" | "photo" | "video" {
  switch (method) {
    case "sendAnimation":
      return "animation" satisfies keyof TgMailing.CallApiBody<"sendAnimation">["body"]
    case "sendPhoto":
      return "photo" satisfies keyof TgMailing.CallApiBody<"sendPhoto">["body"]
    case "sendVideo":
      return "video" satisfies keyof TgMailing.CallApiBody<"sendVideo">["body"]
  }

  throw new Error(`unknown method ${method}`)
}
