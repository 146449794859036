import { FieldValues, Path, TextFieldElement } from "react-hook-form-mui"

interface IProps<T extends FieldValues> {
  name: Path<T>
  required?: boolean
  disabled?: boolean
}
interface IField extends FieldValues {}

export const Comment = <T extends IField>({
  required,
  disabled,
  name,
}: IProps<T>) => {
  return (
    <TextFieldElement<IField>
      name={name}
      label="Комментарий"
      required={required}
      disabled={disabled}
      multiline
      rows={3}
    />
  )
}
