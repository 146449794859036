import { FC } from "react";
import { Button } from "@mui/material";
import { useAppDispatch } from "@/modules/app/hooks";
import { logout } from "../store";

interface IProps {}

export const LogoutButton: FC<IProps> = (props) => {
  const dispatch = useAppDispatch();

  const handleLogout = () => {
    dispatch(logout());
  }

  return <>
    <Button onClick={handleLogout} variant='contained'>Выйти</Button>
  </>

}
