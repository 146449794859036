import { FC } from "react"
import { useAppDispatch } from "@app/hooks"
import { setAuth } from "@app/store"
import { App } from "@app/interfaces"
import { useSearchParams } from "react-router-dom"

interface IProps {}

export const Auth: FC<IProps> = (props) => {
  const dispatch = useAppDispatch()
  const [searchParams, setSearchParams] = useSearchParams()
  const authToken = searchParams.get("auth_token")

  if (!authToken) {
    return <></>
  }

  dispatch(setAuth({ token: authToken, role: App.UserRole.ADMIN }))

  return <></>
}
