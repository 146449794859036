import { Box, BoxProps, Typography } from "@mui/material"
type IProps = BoxProps & {
  label: string
  value: string | number | undefined | null | React.ReactElement
}
export const FieldView = ({ value, label, ...boxProps }: IProps) => (
  <Box {...boxProps}>
    <Typography variant="body1" component={"span"}>
      {label}:
    </Typography>
    &nbsp;
    <Typography variant="body2" component={"span"}>
      {value ?? "-"}
    </Typography>
  </Box>
)
