import { FC } from "react"
import { Box, Container, Grid } from "@mui/material"
import { HrCandidateForm, HrCandidatesTable } from "../components"
import { MetabaseEmbed } from "@/modules/pr/components/metabase/embed"
import { Metabase } from "@/modules/pr/interfaces"
import { useTitle } from "@/core/hooks"

interface IProps {}

export const HrCandidatePage: FC<IProps> = (props) => {
  useTitle(`PR - HR`)
  return (
    <Container maxWidth={"xl"}>
      <Grid container spacing={2}>
        <Grid item xs={12} md={7}>
          <HrCandidateForm />
        </Grid>
        <Grid item xs={12} md={5}>
          <MetabaseEmbed
            type={Metabase.EmbedType.chart}
            id={264}
            height="100%"
            params={{ theme: "transparent" }}
          />
        </Grid>
        <Grid item xs={12} md={12}>
          <Box height="85vh" mb={1}>
            <HrCandidatesTable />
          </Box>
        </Grid>
      </Grid>
    </Container>
  )
}
