import { InlineCode } from "@/core/components"
import { Link } from "@mui/material"

export const ParseModeHelperText = (
  <>
    <InlineCode>Markdown</InlineCode>/<InlineCode>MarkdownV2</InlineCode>/
    <InlineCode>HTML</InlineCode> (
    <Link
      color="secondary"
      href="https://core.telegram.org/bots/api#formatting-options"
      target="_blank"
      referrerPolicy="no-referrer"
    >
      инструкция
    </Link>
    )
  </>
)
