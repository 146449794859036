import { FC, useState } from "react"
import { CircularProgress, Link, Typography } from "@mui/material"
import { useLoginQuery } from "../store"
import { Auth } from "./auth"
import { ArrowOutward } from "@mui/icons-material"

interface IProps {}

export const Login: FC<IProps> = (props) => {
  const [needCheckAuth, setNeedCheckAuth] = useState(false)
  const {
    data: loginUrl,
    isFetching: isLoginUrlFetching,
    isError: isLoginUrlError,
  } = useLoginQuery({})

  if (isLoginUrlFetching) {
    return <CircularProgress color="secondary" />
  }

  return (
    <>
      <Link
        href={loginUrl}
        target="_blank"
        rel="noreferrer"
        color="secondary"
        onClick={() => setNeedCheckAuth(true)}
      >
        <Typography
          sx={{
            display: "flex",
            alignItems: "center",
          }}
        >
          Войти&nbsp;
          <ArrowOutward fontSize="small" />
        </Typography>
      </Link>
      {needCheckAuth && (
        <Auth loginKey={loginUrl?.match(/(?<=start=).*/i)?.[0] ?? ""} />
      )}
    </>
  )
}
