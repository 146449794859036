import type { BaseQueryApi, BaseQueryFn } from "@reduxjs/toolkit/query"
import { Mutex } from "async-mutex"
import { resetAuth } from "."
import { BaseQueryMeta } from "@reduxjs/toolkit/dist/query/baseQueryTypes"

const mutex = new Mutex()

export const baseQueryWithReauth = async <
  BaseQuery extends BaseQueryFn = BaseQueryFn,
>(
  query: BaseQuery,
  args: [args: any, api: BaseQueryApi, extraOptions: {}],
) => {
  // wait until the mutex is available without locking it
  await mutex.waitForUnlock()
  let result = await query(...args)

  if (
    "error" in result &&
    result.meta &&
    "response" in result.meta &&
    "status" in (result.meta.response as any) &&
    (result.meta.response as any)?.status === 401
  ) {
    const api = args[1]
    // checking whether the mutex is locked
    if (!mutex.isLocked()) {
      const release = await mutex.acquire()
      try {
        api.dispatch(resetAuth())
      } finally {
        // release must be called once the mutex should be released again.
        release()
      }
    } else {
      // wait until the mutex is available without locking it
      await mutex.waitForUnlock()
      result = await query(...args)
    }
  }
  return result
}
