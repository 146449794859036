import { Autocomplete, TextField } from "@mui/material"
import { FC } from "react"
import { useGetWarehouseStaffListQuery } from "../store"
import { Liga } from "../interfaces"

interface IProps {
  onChange?: (value: Liga.WarehouseStaffListItem["id"] | null) => void
  defaultValue?: Liga.WarehouseStaffListItem["id"]
}

export const WarehouseStaffSelect: FC<IProps> = (props) => {
  const { data, isFetching: isLoading } = useGetWarehouseStaffListQuery({
    queryParams: { active: true },
  })
  const defaultValue = data?.find((l) => l.id === props.defaultValue)

  return (
    <Autocomplete<Liga.WarehouseStaffListItem>
      fullWidth
      value={defaultValue ?? null}
      loading={isLoading}
      isOptionEqualToValue={(option, value) => option?.id === value?.id}
      getOptionLabel={(option: Liga.WarehouseStaffListItem) => option.name}
      options={data ?? []}
      onChange={(e, value) =>
        typeof props.onChange === "function" &&
        props.onChange(value?.id ?? null)
      }
      renderInput={(params) => (
        <TextField {...params} label="Сотрудник склада" />
      )}
    />
  )
}
