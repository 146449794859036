import { FC } from "react"
import { Box, Container } from "@mui/material"
import { NcMembers } from "../components/ncMembers"
import { useTitle } from "@/core/hooks"

interface IProps {}

export const NcMembersPage: FC<IProps> = (props) => {
  useTitle(`PR Telegram - Участники НЧ`)

  return (
    <Container maxWidth={false}>
      <Box maxHeight="85vh" height="100%">
        <NcMembers />
      </Box>
    </Container>
  )
}
