import {
  BaseQueryFn,
  createApi,
  fetchBaseQuery,
} from "@reduxjs/toolkit/query/react"
import { EndpointBuilder } from "@reduxjs/toolkit/dist/query/endpointDefinitions"
import {
  RootState,
  dynamicMiddlewaresInstance,
  registerReducer,
} from "@core/store"
import { baseQueryWithReauth } from "@/modules/app/store"
import { Hr } from "../interfaces"

const tagTypes = ["Candidates", "CandidateSources"] as const

const reducerPath: string = "hrApi"
export const baseTGStatApiURL = new URL(
  import.meta.env.REACT_APP_TGSTAT_API,
  window.location.origin,
).toString()
const baseQuery: BaseQueryFn = fetchBaseQuery({
  baseUrl: `${
    baseTGStatApiURL.endsWith("/") ? baseTGStatApiURL : baseTGStatApiURL + "/"
  }liga`,
  prepareHeaders: (headers, { getState }) => {
    const token = (getState() as RootState).auth.token
    if (token) {
      headers.set("authorization", `Bearer ${token}`)
    }
    return headers
  },
})

type ApiEndpointBuider = EndpointBuilder<
  typeof baseQuery,
  (typeof tagTypes)[number],
  typeof reducerPath
>

const endpoints = (build: ApiEndpointBuider) => ({
  getCandidates: build.query<Hr.GetCandidatesRes, Hr.GetCandidatesReq>({
    query: () => ({
      url: `hr-candidates`,
      method: "GET",
    }),
    providesTags: ["Candidates"],
  }),
  createCandidate: build.mutation<
    Hr.CreateCandidateRes,
    Hr.CreateCandidatesReq
  >({
    query: ({ body }) => {
      return {
        url: `hr-candidates`,
        method: "POST",
        body,
      }
    },
    invalidatesTags: ["Candidates"],
  }),
  updateCandidate: build.mutation<Hr.UpdateCandidateReq, Hr.UpdateCandidateReq>(
    {
      query: ({ body, pathParams: { id } }) => {
        return {
          url: `hr-candidates/${id}`,
          method: "PUT",
          body,
        }
      },
      invalidatesTags: ["Candidates"],
    },
  ),
  getCandidateSources: build.query<
    Hr.GetCandidateSourcesRes,
    Hr.GetCandidateSourcesReq
  >({
    query: () => ({
      url: `hr-candidate-sources`,
      method: "GET",
    }),
    providesTags: ["CandidateSources"],
  }),
})

export const hrApi = createApi({
  reducerPath,
  tagTypes,
  baseQuery: (...args) => baseQueryWithReauth(baseQuery, args),
  endpoints: (build) => ({
    ...endpoints(build),
  }),
})
dynamicMiddlewaresInstance.addMiddleware(hrApi.middleware)
registerReducer(hrApi.reducerPath, hrApi.reducer)

export const {
  useGetCandidatesQuery,
  useCreateCandidateMutation,
  useUpdateCandidateMutation,
  useGetCandidateSourcesQuery,
} = hrApi
