import { Box } from "@mui/material"
import {
  GridToolbarQuickFilterProps,
  GridToolbarQuickFilter,
} from "@mui/x-data-grid-pro"

export function QuickSearchToolbar(props?: GridToolbarQuickFilterProps) {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row-reverse",
        marginX: 2,
      }}
    >
      <Box
        sx={{
          p: 1,
          pb: 0,
        }}
      >
        <GridToolbarQuickFilter {...props} />
      </Box>
    </Box>
  )
}
