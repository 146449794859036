import { FC, useMemo } from "react"
import { DataGrid, GridCellEditingWrapper } from "@core/components"
import { GridColDef, getGridStringOperators } from "@mui/x-data-grid-pro"
import { Liga } from "../interfaces"
import { useGetCouriersQuery, useUpdateStaffMutation } from "../store"
import {
  isEqualObjects,
  stringOrEmptySortingComparator,
  wrapFilterOperator,
} from "@/core/utils"
import { WarehouseStaffSelect } from "./warehouseStaffSelect"

interface IProps {}

export const StaffTable: FC<IProps> = (props) => {
  const { data, isFetching } = useGetCouriersQuery({
    queryParams: { active: true },
  })
  const [update, { isLoading: isUpdating }] = useUpdateStaffMutation()

  const columns = useMemo<GridColDef<Liga.Courier>[]>(
    () => [
      {
        headerName: "ID",
        field: "id",
        flex: 1,
      },
      {
        headerName: "Юзернейм",
        field: "name",
        flex: 2,
      },
      {
        headerName: "Является сотрудником склада",
        field: "is_warehouse_staff",
        type: "boolean",
        editable: true,
        flex: 1,
      },
      {
        headerName: "Сотрудник склада",
        field: "warehouse_staff",
        editable: true,
        filterable: true,
        type: "string",
        valueFormatter: (params) => {
          return params.value?.name
        },
        filterOperators: getGridStringOperators().map((f) =>
          wrapFilterOperator<Liga.Courier>(
            f,
            (applyFilterFn, params) =>
              applyFilterFn({ ...params, value: params.value?.name }),
            (applyFilterFnV7, value, row, col, apiRef) =>
              applyFilterFnV7(value?.name, row, col, apiRef),
          ),
        ),
        renderEditCell: (params) => (
          <GridCellEditingWrapper<typeof WarehouseStaffSelect>
            id={params.id}
            field={params.field}
            component={WarehouseStaffSelect}
            componentProps={{
              defaultValue: params.value?.id ?? null,
            }}
          />
        ),
        valueParser(value, params) {
          return typeof value === "string"
            ? value
            : data?.find((s) => s.id === value)
        },
        valueSetter(params) {
          return {
            ...params.row,
            warehouse_staff: params.value,
            warehouse_staff_id: params.value?.id ?? null,
          }
        },
        sortComparator: (v1, v2) =>
          stringOrEmptySortingComparator(v1?.name, v2?.name),
        flex: 2,
      },
      {
        headerName: "Франчайзи",
        field: "is_franchisee",
        type: "boolean",
        editable: true,
        flex: 1,
      },
      {
        headerName: "Контакт",
        field: "contact",
        type: "string",
        editable: true,
        flex: 1,
      },
    ],
    [data],
  )
  const updateCourier = (upd: Liga.Courier, old: Liga.Courier) => {
    if (isEqualObjects(upd, old)) {
      return old
    }

    update({
      pathParams: {
        id: upd.id,
      },
      body: {
        is_warehouse_staff: upd.is_warehouse_staff,
        is_franchisee: upd.is_franchisee,
        warehouse_staff_id: upd.warehouse_staff_id ?? null,
        contact: upd.contact || null,
      },
    })
    return upd
  }

  return (
    <DataGrid<Liga.Courier>
      columns={columns}
      rows={data ?? []}
      loading={isFetching || isUpdating}
      paginationMode="client"
      editMode="cell"
      processRowUpdate={updateCourier}
      unstable_headerFilters
      // disableColumnFilter
      initialState={{
        sorting: {
          sortModel: [{ field: "name", sort: "asc" }],
        },
      }}
    />
  )
}
