import React from "react"
import { DatePickerElement } from "react-hook-form-mui"

interface IProps {
  required?: boolean
}
interface IField {
  date_start?: Date | null
}

export const OrderStartDate = <T extends IField>({ required }: IProps) => {
  return (
    <DatePickerElement<IField>
      name={"date_start"}
      label="Дата начала размещения"
      required={required}
    />
  )
}
