import { FC } from "react"
import { IFormProps } from "@/core/interfaces"
import { RtkHookForm } from "@/core/components"
import { ADV } from "../../interfaces"
import { useCreateTopUpMutation } from "../../store"
import { Grid, Stack } from "@mui/material"
import { DefaultValues } from "react-hook-form-mui"
import { TransactionHash } from "../formFields/hash"
import { SumToken } from "../formFields/sumToken"
import { Type } from "../formFields/typeSelect"
import { encryptTransactionId } from "../utils/string"
import { DateField } from "../formFields/date"
import { TopUpSource } from "../formFields/topUpSourceSelect"

interface IProps extends IFormProps<ADV.CreateTopUpReq, ADV.CreateTopUpRes> {}
interface IFormData extends ADV.CreateTopUp {}

export const CreateTopUpForm: FC<IProps> = (props) => {
  const DEFAULT_FORM_VALUES: DefaultValues<IFormData> = {
    date: new Date(),
  }

  return (
    <RtkHookForm<IFormData, ADV.CreateOrderRes, typeof useCreateTopUpMutation>
      mutationHook={useCreateTopUpMutation}
      getSubmitHandlerParams={async (body) => ({
        body: {
          ...body,
          hash: await encryptTransactionId(body.hash),
        },
      })}
      formContextParams={{
        defaultValues: DEFAULT_FORM_VALUES,
      }}
      actions={[
        {
          text: "Сохранить",
          type: "submit",
          variant: "contained",
          fullWidth: true,
        },
      ]}
    >
      <Grid container spacing={2}>
        <Grid item xs={12} sm={12} lg={4}>
          <Stack spacing={1}>
            <DateField />
          </Stack>
        </Grid>
        <Grid item xs={12} sm={12} lg={4}>
          <Stack spacing={1}>
            <Type<IFormData>
              required
              name={"type_id"}
              for={"payments"}
              label="Монета"
            />
            <TransactionHash<IFormData> name={"hash"} required />
            <SumToken<IFormData> name={"sum"} required />
          </Stack>
        </Grid>
        <Grid item xs={12} sm={12} lg={4}>
          <Stack spacing={1}>
            <TopUpSource<IFormData> required />
          </Stack>
        </Grid>
      </Grid>
    </RtkHookForm>
  )
}
