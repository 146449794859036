import { FC } from "react"
import { Box, Container, Typography } from "@mui/material"
import { OrdersTable } from "../components"
import { useTitle } from "@/core/hooks"
import { Navigate, useParams } from "react-router-dom"
import { Agent } from "../components/agent"
import { GoBack } from "@/core/components"

interface IProps {}

export const AgentPage: FC<IProps> = (props) => {
  const { agentId } = useParams()
  if (typeof agentId === "undefined" || isNaN(+agentId)) {
    return <Navigate to=".." relative="path" />
  }
  useTitle(`PR ADV - Контрагент ID:${agentId}`)

  return (
    <Container maxWidth={false}>
      <GoBack />
      <Box mb={3}>
        <Agent id={+agentId} />
      </Box>
      <Box mt={5}>
        <Typography variant="h5">Заказы контрагента</Typography>
        <Box height="85vh">
          <OrdersTable agents={+agentId} />
        </Box>
      </Box>
    </Container>
  )
}
