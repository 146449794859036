import { FC } from "react"
import { baseUrl } from "../store"
import { TG } from "../interfaces"
import { Box, Button, Typography } from "@mui/material"
import { Download } from "@mui/icons-material"
import { useAuth } from "@/modules/app/hooks"

export type IProps = TG.ExportNcMembersReq["queryParams"]
export const NcMembersExport: FC<IProps> = ({ find, groupByDate }) => {
  const searchParams = new URLSearchParams()
  searchParams.append("find", JSON.stringify(find))
  searchParams.append("groupByDate", `${groupByDate}`)
  const token = useAuth().token
  searchParams.append("auth_token", token ?? "")

  return (
    <Box>
      <Button
        href={`${baseUrl}/find-nc-member/export?${searchParams.toString()}`}
        rel="no-refferer"
        target="_blank"
      >
        <Download />
        &nbsp;
        <Typography variant="button">Экспорт</Typography>
      </Button>
    </Box>
  )
}
