import { FieldView } from "@/core/components"
import { ADV } from "../interfaces"
import { useGetAgentQuery } from "../store"
import {
  Box,
  Button,
  ButtonGroup,
  Collapse,
  Grid,
  LinearProgress,
  Link,
  Stack,
  Typography,
} from "@mui/material"
import { useEffect, useState } from "react"
import { formatTGLink, isURL } from "./utils/string"
import { UpdateAgentForm } from "./forms/updateAgentForm"
import { Close } from "@mui/icons-material"
import { CreateOrderForm } from "./forms/createOrderForm"

interface IProps {
  id: ADV.Agent["id"]
}

enum TabId {
  EditAgent = 1,
  CreateOrder,
}

export const Agent = ({ id }: IProps) => {
  const [expanded, setExpanded] = useState<TabId | undefined>()
  const { data, isLoading } = useGetAgentQuery({ pathParams: { id } })

  useEffect(() => {
    setExpanded(undefined)
  }, [data])

  if (isLoading) {
    return <LinearProgress />
  }
  return (
    <>
      <Box display="flex" justifyContent="space-between" mb={4}>
        <Typography variant="h5" paragraph>
          Контрагент ID:{data?.id}
        </Typography>
        <Box>
          <ButtonGroup>
            <Button onClick={() => setExpanded(TabId.EditAgent)}>
              Редактировать контрагента
            </Button>
            <Button onClick={() => setExpanded(TabId.CreateOrder)}>
              Создать новый заказ
            </Button>
          </ButtonGroup>
          {!!expanded && (
            <Box ml={1} display="inline">
              <Button onClick={() => setExpanded(undefined)}>
                <Close />
              </Button>
            </Box>
          )}
        </Box>
      </Box>

      <Collapse in={!!expanded}>
        <Box mb={6}>
          {expanded === TabId.EditAgent && (
            <>
              <Typography variant="h5">Редактирование контрагента</Typography>
              <UpdateAgentForm id={id} />
            </>
          )}
          {expanded === TabId.CreateOrder && (
            <>
              <Typography variant="h5">Создание нового заказа</Typography>
              <CreateOrderForm agentId={data?.id} />
            </>
          )}
        </Box>
      </Collapse>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6} md={4}>
          <Stack spacing={2}>
            <FieldView label={"Контрагент"} value={data?.name} />
            <FieldView label={"Тип"} value={data?.type} />
            <FieldView
              label={"Ссылка"}
              value={
                data?.url && (
                  <Link
                    color="secondary"
                    href={formatTGLink(data?.url)}
                    target="_blank"
                    referrerPolicy="no-referrer"
                  >
                    {data?.url}
                  </Link>
                )
              }
            />
            <FieldView
              label={"Контакты"}
              display="flex"
              value={
                data?.contacts && (
                  <Stack spacing={1}>
                    {data.contacts.map((c, i) => (
                      <Link
                        color="secondary"
                        href={formatTGLink(c.name)}
                        target="_blank"
                        referrerPolicy="no-referrer"
                        key={`agent_${i}`}
                        sx={(theme) => ({
                          backgroundColor: theme.palette.action.hover,
                          px: 1,
                          borderRadius: 2,
                        })}
                      >
                        {`${
                          isURL(c.name) || c.name.startsWith("@") ? "" : "@"
                        }${c.name}`}
                      </Link>
                    ))}
                  </Stack>
                )
              }
            />
            <FieldView
              label={"Геолокация"}
              display="flex"
              value={
                data?.geos && (
                  <Stack spacing={1}>
                    {data.geos.map((c, i) => (
                      <Box key={i}>{c.name}</Box>
                    ))}
                  </Stack>
                )
              }
            />
          </Stack>
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <Stack spacing={2}></Stack>
        </Grid>
      </Grid>
    </>
  )
}
