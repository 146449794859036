import React from "react"
import { DatePickerElement } from "react-hook-form-mui"

interface IProps {
  required?: boolean
}
interface IField {
  date_finish?: Date | undefined
}

export const OrderEndDate = <T extends IField>({ required }: IProps) => {
  return (
    <DatePickerElement<IField>
      name="date_finish"
      label="Дата окончания размещения"
      required={required}
    />
  )
}
