import { ADV } from "../../interfaces"
import { AutocompleteElement, FieldValues, Path } from "react-hook-form-mui"
import { useBotsListQuery, useTypesListQuery } from "../../store"

interface IProps<T extends FieldValues> {
  name: Path<T>
  typeId: number | undefined
  required?: boolean
  disabled?: boolean
}
type IField = {
  bot_id: ADV.Bot["id"] | undefined
}

export const Bot = <T extends FieldValues = IField>(props: IProps<T>) => {
  const { required, disabled, name } = props
  const { data, isFetching: isLoading } = useBotsListQuery(
    {
      queryParams: { type_id: props.typeId ?? 0 },
    },
    { skip: props.typeId === undefined },
  )

  return (
    <AutocompleteElement<T>
      name={name}
      label="Бот"
      loading={isLoading}
      matchId
      required={required}
      autocompleteProps={{
        disabled,
        fullWidth: true,
        getOptionLabel: (option: ADV.Type) => option?.name,
      }}
      options={data ?? []}
    />
  )
}
