import { FC, useMemo, useState } from "react"
import {
  DataGrid,
  GridCellEditingWrapper,
  GridRowModesExtended,
} from "@core/components"
import {
  GridColDef,
  GridRowId,
  getGridStringOperators,
} from "@mui/x-data-grid-pro"
import { Liga } from "../interfaces"
import {
  useCreateProcurementsMutation,
  useGetLocationsQuery,
  useGetProcurementsQuery,
  useGetProductsQuery,
  useGetStaffQuery,
  useUpdateProcurementsMutation,
} from "../store"
import { LocationSelect, ProductSelect } from "."
import equal from "fast-deep-equal"
import {
  stringOrEmptySortingComparator,
  wrapFilterOperator,
} from "@/core/utils"
import { StaffSelect } from "./staffSelect"
import { Autocomplete, TextField } from "@mui/material"

interface IProps {}
type INewRow = Liga.Procurement

const counter = (() => {
  let count = 0
  return () => count++
})()
const generateNewRow = (): INewRow => ({
  id: "new" + counter(),
  curator_id: 0,
  worker_username: "",
  city_id: 0,
  shop: undefined,
  site: undefined,
  product_id: 0,
  count: undefined,
  sum: "",
  status: Liga.ProcurementStatus.ISSUE,
  type: Liga.ProcurementType.BYING_MASTER_STAFF,
  write_off: undefined,
  comment: "",
  curator: undefined,
  city: undefined,
  product: undefined,
})

export const ProcurementsTable: FC<IProps> = (props) => {
  const { data, isFetching } = useGetProcurementsQuery({})
  const { data: staff, isFetching: isStaffFetching } = useGetStaffQuery({})
  const { data: cities, isFetching: isCitiesFetching } = useGetLocationsQuery({
    queryParams: {
      type: "Город",
    },
  })
  const { data: products, isFetching: isProductsFetching } =
    useGetProductsQuery({})
  const [update, { isLoading: isUpdating }] = useUpdateProcurementsMutation()
  const [create, { isLoading: isCreating }] = useCreateProcurementsMutation()
  const [newRows, setNewRows] = useState<INewRow[]>([])

  const columns = useMemo<GridColDef<Liga.Procurement>[]>(
    () => [
      {
        headerName: "ID",
        field: "id",
        flex: 1,
      },
      {
        headerName: "Куратор",
        field: "curator_id",
        editable: true,
        filterable: true,
        valueFormatter: (params) => {
          return params.value?.name
        },
        valueGetter: (params) => {
          return params.row?.curator
        },
        renderEditCell: (params) => (
          <GridCellEditingWrapper<typeof StaffSelect>
            id={params.id}
            field={params.field}
            component={StaffSelect}
            componentProps={{
              queryParams: { active: true, role: Liga.StaffRoles.MANAGER },
              defaultValue: params.value?.id ?? null,
              disableClearable: true,
            }}
          />
        ),
        // preProcessEditCellProps: (params) => {
        //   const hasError = !params.props.value
        //   console.log(params.props.value)

        //   return { ...params.props, error: hasError }
        // },
        valueParser(value, params) {
          return typeof value === "string"
            ? value
            : staff?.find((s) => s.id === value)
        },
        valueSetter(params) {
          return {
            ...params.row,
            curator: params.value,
            curator_id: params.value?.id ?? null,
          }
        },
        filterOperators: getGridStringOperators().map((f) =>
          wrapFilterOperator<Liga.Courier>(
            f,
            (applyFilterFn, params) =>
              applyFilterFn({ ...params, value: params.value?.name }),
            (applyFilterFnV7, value, row, col, apiRef) =>
              applyFilterFnV7(value?.name, row, col, apiRef),
          ),
        ),
        sortComparator: (v1, v2) =>
          stringOrEmptySortingComparator(v1?.name, v2?.name),
        flex: 2,
      },
      {
        headerName: "Для кого",
        field: "worker_username",
        editable: true,
        filterable: true,
        renderEditCell: (params) => (
          <GridCellEditingWrapper<typeof StaffSelect>
            id={params.id}
            field={params.field}
            component={StaffSelect}
            componentProps={{
              defaultValue: params.value ?? null,
              queryParams: { active: true, role: Liga.StaffRoles.COURIER },
              disableClearable: true,
              freeSolo: true,
            }}
          />
        ),
        valueParser(value, params) {
          return typeof value === "string"
            ? value
            : staff?.find((s) => s.id === value)
        },
        valueSetter(params) {
          return {
            ...params.row,
            worker_username: params.value ?? null,
          }
        },
        filterOperators: getGridStringOperators().map((f) =>
          wrapFilterOperator<Liga.Courier>(
            f,
            (applyFilterFn, params) =>
              applyFilterFn({ ...params, value: params.value?.name }),
            (applyFilterFnV7, value, row, col, apiRef) =>
              applyFilterFnV7(value?.name, row, col, apiRef),
          ),
        ),
        sortComparator: (v1, v2) =>
          stringOrEmptySortingComparator(v1?.name, v2?.name),
        flex: 2,
      },
      {
        headerName: "Город",
        field: "city_id",
        editable: true,
        type: "string",
        renderEditCell: (params) => (
          <GridCellEditingWrapper<typeof LocationSelect>
            id={params.id}
            field={params.field}
            component={LocationSelect}
            componentProps={{
              defaultValue: params.value?.id ?? null,
              type: "Город",
              disableClearable: true,
            }}
          />
        ),
        valueFormatter: (params) => {
          return params.value?.name
        },
        valueGetter: (params) => {
          return params.row?.city
        },
        valueParser(value, params) {
          return typeof value === "string"
            ? value
            : cities?.find((c) => c.id === value)
        },
        valueSetter(params) {
          return {
            ...params.row,
            city: params.value,
            city_id: params.value?.id ?? null,
          }
        },
        filterOperators: getGridStringOperators().map((f) =>
          wrapFilterOperator<Liga.Courier>(
            f,
            (applyFilterFn, params) =>
              applyFilterFn({ ...params, value: params.value?.name }),
            (applyFilterFnV7, value, row, col, apiRef) =>
              applyFilterFnV7(value?.name, row, col, apiRef),
          ),
        ),
        sortComparator: (v1, v2) =>
          stringOrEmptySortingComparator(v1?.name, v2?.name),
        flex: 2,
      },
      {
        headerName: "Магазин",
        field: "shop",
        editable: true,
        flex: 2,
      },
      {
        headerName: "Сайт",
        field: "site",
        editable: true,
        flex: 2,
      },
      {
        headerName: "Категория товара",
        field: "product_id",
        editable: true,
        renderEditCell: (params) => (
          <GridCellEditingWrapper<typeof ProductSelect>
            id={params.id}
            field={params.field}
            component={ProductSelect}
            componentProps={{
              defaultValue: params.value?.id ?? null,
              disableClearable: true,
            }}
          />
        ),
        valueFormatter: (params) => {
          return params.value?.name
        },
        valueGetter: (params) => {
          return params.row?.product
        },
        valueParser(value, params) {
          return typeof value === "string"
            ? value
            : products?.find((s) => s.id === value)
        },
        valueSetter(params) {
          return {
            ...params.row,
            product_id: params.value?.id ?? null,
            product: params.value,
          }
        },
        filterOperators: getGridStringOperators().map((f) =>
          wrapFilterOperator<Liga.Courier>(
            f,
            (applyFilterFn, params) =>
              applyFilterFn({ ...params, value: params.value?.name }),
            (applyFilterFnV7, value, row, col, apiRef) =>
              applyFilterFnV7(value?.name, row, col, apiRef),
          ),
        ),
        sortComparator: (v1, v2) =>
          stringOrEmptySortingComparator(v1?.name, v2?.name),
        flex: 2,
      },
      {
        headerName: "Вес",
        field: "count",
        type: "number",
        editable: true,
        renderEditCell: (params) => (
          <TextField
            type="number"
            name="Вес"
            defaultValue={params.value}
            onChange={(ev) => {
              params.api.setEditCellValue({
                value: ev.target.value,
                id: params.id,
                field: params.field,
              })
              params.api.setEditCellValue({
                value: (+ev.target.value * 0.03).toFixed(2),
                id: params.id,
                field: "write_off",
              })
            }}
            inputProps={{
              step: 10,
              min: 0,
            }}
          />
        ),
        valueSetter(params) {
          return {
            ...params.row,
            count: [
              Liga.ProcurementType.BYING_MASTER_STAFF,
              Liga.ProcurementType.TOP_UP,
            ].includes(params.row.type)
              ? params.value
              : null,
          }
        },
        flex: 2,
      },
      {
        headerName: "Оплата",
        field: "sum",
        editable: true,
        renderEditCell: (params) => (
          <TextField
            type="number"
            name="Оплата"
            defaultValue={params.value}
            onChange={(ev) =>
              params.api.setEditCellValue({
                value: ev.target.value,
                id: params.id,
                field: params.field,
              })
            }
            inputProps={{
              step: 500,
              min: 0,
            }}
          />
        ),
        valueSetter(params) {
          return {
            ...params.row,
            sum: params.value.toString(),
          }
        },
        flex: 2,
      },
      {
        headerName: "Статус",
        field: "status",
        editable: true,
        type: "singleSelect",
        valueOptions: Object.values(Liga.ProcurementStatus),
        renderEditCell: (params) => (
          <Autocomplete
            value={params.value ?? null}
            options={Object.values(Liga.ProcurementStatus)}
            fullWidth
            disableClearable
            onChange={(ev, value) =>
              params.api.setEditCellValue(
                {
                  value,
                  id: params.id,
                  field: "status",
                },
                ev,
              )
            }
            renderInput={(params) => <TextField {...params} label="Статус" />}
          />
        ),
        flex: 2,
      },
      {
        headerName: "Тип",
        field: "type",
        editable: true,
        type: "singleSelect",
        valueOptions: Object.values(Liga.ProcurementType),
        renderEditCell: (params) => (
          <Autocomplete
            value={params.value ?? null}
            options={Object.values(Liga.ProcurementType)}
            disableClearable
            fullWidth
            onChange={(ev, value) => {
              params.api.setEditCellValue(
                {
                  value,
                  id: params.id,
                  field: "type",
                },
                ev,
              )
            }}
            renderInput={(params) => <TextField {...params} label="Тип" />}
          />
        ),
        flex: 2,
      },
      {
        headerName: "Списание",
        field: "write_off",
        valueSetter(params) {
          return {
            ...params.row,
            write_off: !!params.row.count
              ? (params.row.count * 0.03).toFixed(2)
              : "",
          }
        },
        editable: true,
        flex: 2,
      },
      {
        headerName: "Комментарий",
        field: "comment",
        editable: true,
        flex: 2,
      },
    ],
    [data, cities, staff, products],
  )
  const deleteNewRow = (id: GridRowId) =>
    setNewRows((v) => v.filter((row) => row.id !== id))
  const processRowUpdate = (upd: Liga.Procurement, old: Liga.Procurement) => {
    if (equal(upd, old)) {
      return old
    }
    if (typeof upd.id === "number") {
      updateProcurement(upd)
    } else {
      createProcurement(upd)
    }

    return upd
  }
  const updateProcurement = (upd: Liga.Procurement) => {
    const { id, city, curator, product, ...body } = upd
    update({
      pathParams: {
        id,
      },
      body,
    })
  }
  const createProcurement = (p: Liga.Procurement) => {
    const { id, city, curator, product, ...body } = p
    create({ body })
      .unwrap()
      .then(() => deleteNewRow(id))
  }

  return (
    <DataGrid<Liga.Procurement>
      columns={columns}
      rows={[...(data ?? []), ...newRows]}
      loading={
        isFetching ||
        isUpdating ||
        isCreating ||
        isStaffFetching ||
        isProductsFetching ||
        isCitiesFetching
      }
      paginationMode="client"
      editMode="row"
      processRowUpdate={processRowUpdate}
      onProcessRowUpdateError={(e) => console.error(e)}
      canEdit
      canCreate
      onCreateClick={({ setRowModesModel }) => {
        const newRow = generateNewRow()
        setNewRows((v) => [...v, newRow])
        setRowModesModel((old) => ({
          ...old,
          [newRow.id]: { mode: GridRowModesExtended.Creating },
        }))
      }}
      onInternalCreateCancel={(params) => {
        deleteNewRow(params.id)
      }}
      // disableColumnFilter
      initialState={{
        sorting: {
          sortModel: [{ field: "id", sort: "desc" }],
        },
      }}
    />
  )
}
